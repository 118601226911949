import { Schema } from "@effect/schema";
import { ProjectC } from "@/Lib/APITypes";
import * as t from "io-ts";
import { flow } from "fp-ts/lib/function";
import { getOrThrowDecodeError } from "./util/typeUtils";

/**
 * A branded project ID
 *
 * It's just a UUID like it always has been, but this way it's harder to pix up with other UUIDs. We base
 * our branding on plain old string, though, since it's opaque to us
 *
 * Eventually this will become `ProjectId`
 */
export const ProjectId2024 = Schema.String.pipe(Schema.brand("ProjectId"));
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ProjectId2024 = typeof ProjectId2024.Type;

/**
 * What we think we are getting from graphql endpoint
 */
export class GQLProject extends Schema.Class<GQLProject>("GQLProject")({
  project_id: ProjectId2024,
  name: Schema.String,
  address: Schema.String,
  city: Schema.NullOr(Schema.String), // TODO: city should be required in gql
  state: Schema.NullOr(Schema.String), // TODO: state should be required in gql
  zipcode: Schema.NullOr(Schema.String), // TODO: zipcode should be required in gql
}) {}

/**
 * A project
 *
 * ...in the form we want to think of it in code - may or may not actually match the API representation
 *
 * Eventually this will become `Project`
 */
export const Project2024 = Schema.Struct({
  id: ProjectId2024,
  address: Schema.String,
  name: Schema.String,
  city: Schema.String.pipe(Schema.NullOr),
  state: Schema.NullOr(Schema.String), /// which do I prefer?
  zipcode: Schema.String.pipe(Schema.NullOr),
  unit: Schema.String.pipe(
    Schema.NullOr,
    Schema.optionalWith({ default: () => null })
  ),
});
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Project2024 = typeof Project2024.Type;

/**
 * Convert graphql rep to internal
 */
export const ProjectFromGQL = GQLProject.pipe(
  Schema.transform(Project2024, {
    decode: ({ project_id: id, ...rest }) => ({
      ...rest,
      id,
    }),
    encode: ({ id, ...rest }) => ({
      ...rest,
      project_id: ProjectId2024.make(id),
    }),
    strict: true,
  })
);

export const projectsFromGetProjects = flow(
  t.array(ProjectC).decode,
  getOrThrowDecodeError,
  (projects) =>
    projects.map(({ project_id: id, ...rest }) => ({ ...rest, id })),
  Schema.decodeSync(Schema.Array(Project2024))
);
