import React from "react";
import {
  Navigate,
  useParams,
  useSearchParams,
  useLocation,
  Link,
} from "react-router-dom";
import NotFound from "./Pages/NotFound";
import { Typography } from "@mui/material";
import config from "./config";

export const NotFoundSoCreateACard = () => {
  const { "*": splat } = useParams();
  const [search] = useSearchParams();
  const location = useLocation();
  const { pathname } = location;
  const crashVideo = React.useMemo(
    () =>
      location &&
      ((r: number) =>
        (r < 0.33 && "https://giphy.com/embed/5xaOcLQoRXgv1d8bsSQ") ||
        (r < 0.67 && "https://giphy.com/embed/MVgLEacpr9KVK172Ne") ||
        "https://giphy.com/embed/853jNve3ljqrYrcSOK")(Math.random()),
    [location]
  );
  // console.log("backward", { splat, search });
  if (splat === "portfolio") {
    const destination = `/portfolio/${search}`;
    // console.log({ destination });
    return <Navigate replace to={destination} />;
  }

  // return <Navigate to="/portfolio/todo" />;
  return (
    <NotFound>
      {config.nonProdEnv ? (
        <>
          <Typography>
            If you are John or a dev and think that this route should work, or
            you came from something that should have given you a valid link,
            please{" "}
            <Link
              to={`https://trello.com/add-card?source=${encodeURIComponent(
                pathname
              )}&mode=popup&url=${encodeURIComponent(
                location.pathname
              )}&name=${encodeURIComponent(
                `Broken link: "${pathname}"`
              )}&idBoard=5faed9ae13713c07c37bddcc`}
              target="_blank"
              rel="noopener noreferrer"
            >
              create a card
            </Link>
          </Typography>
          <iframe
            src={crashVideo}
            width="480"
            height="293"
            frameBorder="0"
            allowFullScreen
            title="oh snap"
          ></iframe>
        </>
      ) : null}
    </NotFound>
  );
};
