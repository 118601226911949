// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
//import WarningIcon from "@mui/icons-material/Warning";
// import BlockIcon from "@mui/icons-material/Block";
// import { ProjectAPIs } from "@lib/API";
import {
  CircularProgress,
  Grid,
  IconButton,
  LinearProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Collapse,
  Box,
  TextField,
  InputAdornment,
  Tooltip,
  Button,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import React, { useEffect, useMemo } from "react";
import sanitizeFilename from "sanitize-filename";
import { CSVExport } from "../../Components/CSVExport";
import { StealthyLink } from "../../Components/StealthyLink";
import {
  BackendGetProjectsResponse,
  BackendGetProjectsResponseEntry,
  ProjectStatus,
} from "@lib/APITypes";
import {
  Features,
  useAnalytics,
  useFeatures,
  usePermissions,
} from "@lib/hooks";
import { Role } from "@lib/roles";
// import DatePicker from '@mui/lab/DatePicker';
import { filenameDateFormat } from "@lib/util/filenameDateFormat";
import { useLocation, useNavigate } from "react-router-dom";
import { ProjectViewTabs } from "../ProjectView";
import config from "../../config";
import { useInspectors } from "@lib/hooks/useInspectors";
import { useAppContext } from "@lib/UserContext";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import qs from "qs";
import SearchIcon from "@mui/icons-material/Search";
import { useSnackbar } from "notistack";
import { useEntityFeatures } from "@lib/hooks/useEntityFeatures";
import { throwIfNot } from "@lib/util/throwIfNot";
import { useAuth } from "@lib/hooks/useAuth";
import { useNewUIHook } from "@/Lib/hooks/useNewUI";
import { useAdminData } from "@/Lib/AdminContext";
import { BulkUserAdder } from "@/Components/BulkUserAdder";
import { EmailAddress } from "@project-centerline/project-centerline-api-types";
import HomeTabNavigator from "@/Components/HomeTabNavigator";
import {
  availableColumns,
  ColumnId,
  useColumnDerivationContext,
} from "@/Components/ProjectColumnGenerator";
import { AdminViewContainer } from "@/Components/AdminViewContainer";
import { AdminView } from "../ProjectView/Admin/ProjectAdminView";
import { View, ViewID, createViews } from "./PortfolioViews";

const NESTED_FEATURE = false;

enum InvoiceActions {
  None = "None",
  Approve = "Approve",
  Reject = "Reject",
  Edit = "Edit",
}

function TableHeadWithSorters(props: {
  headCells: ColumnId[];
  order?: "asc" | "desc";
  orderBy?: ColumnId;
  onRequestSort: (column: ColumnId) => void;
  extraCellsLeft?: number;
}): JSX.Element {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (column: ColumnId) => () => {
    if (availableColumns[column].sortable) {
      onRequestSort(column);
    }
  };

  const headCellMap = props.headCells.map((columnName) => ({
    columnName,
    columnDefinition: availableColumns[columnName],
  }));

  return (
    <TableHead>
      <TableRow>
        {/* {Array(props.extraCellsLeft).map((_, i) => ( */}
        {NESTED_FEATURE ? <TableCell key={`expander`} /> : null}
        {/* ))} */}
        {headCellMap.map(
          ({ columnDefinition: { sortable, ...headCell }, columnName }) => (
            <TableCell
              // key={headCell.id}
              // sortDirection={orderBy === headCell.id ? order : false}
              key={columnName}
              align={
                headCell.alignHeader || (headCell.numeric ? "right" : "left")
              }
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={sortable && orderBy === columnName ? order : false}
            >
              <TableSortLabel
                active={sortable && orderBy === columnName}
                hideSortIcon={!sortable}
                direction={orderBy === columnName ? order : "asc"}
                onClick={createSortHandler(columnName)}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
}

/**
 * What you get from applying a DisplayColumn definition to a project
 */
type DerivedRowContent = Record<
  ColumnId,
  {
    formatted: string | JSX.Element;
    value: number | string;
    // key: string;
    display: string | JSX.Element; // `formatted`, but might be wrapped in a link; can't sort on it
    csv: string | number | undefined;
  }
>;

const replaceNaNWithMIN_VALUE = (x: number | string | JSX.Element) =>
  typeof x === "number" && isNaN(x) ? Number.MIN_VALUE : x;

function descendingComparator(
  a: DerivedRowContent,
  b: DerivedRowContent,
  orderBy: ColumnId
) {
  const { sortFormatted = false } = availableColumns[orderBy];
  // const sortColumn = availableColumns.find((col) => orderBy === col.id);
  // const sortFormatted = sortColumn?.sortFormatted;
  const aVal = replaceNaNWithMIN_VALUE(
    sortFormatted ? a[orderBy].formatted : a[orderBy].value
  );
  const bVal = replaceNaNWithMIN_VALUE(
    sortFormatted ? b[orderBy].formatted : b[orderBy].value
  );
  // const aVal = sortFormatted ? a[orderBy].formatted : a[orderBy].value;
  // const bVal = sortFormatted ? b[orderBy].formatted : b[orderBy].value;
  if (bVal < aVal) {
    return -1;
  }
  if (bVal > aVal) {
    return 1;
  }

  return 0;
}

function getComparator(order: "asc" | "desc", orderBy: ColumnId) {
  return order === "desc"
    ? (a: DerivedRowContent, b: DerivedRowContent) =>
        descendingComparator(a, b, orderBy)
    : (a: DerivedRowContent, b: DerivedRowContent) =>
        -descendingComparator(a, b, orderBy);
}

function stableSort(
  array: DisplayRow[],
  comparator: (a: DisplayRow, b: DisplayRow) => number
) {
  const stabilizedThis: [DisplayRow, number][] = array.map((el, index) => [
    el,
    index,
  ]);
  stabilizedThis.sort((a, b) => {
    const order: number = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const viewOrdering: ViewID[] = [
  "todo",
  "pending",
  "active",
  "delayed",
  "all",
  "complete",
  // "debug",
];

interface EmptyViewConfig {
  line1: string;
  line2: string;
  next?: ViewID;
}

const emptyViewActions: Partial<Record<ViewID, EmptyViewConfig>> = {
  todo: {
    line1: "Congratulations; you are all caught up!",
    line2: 'Your "To Do" view is empty.',
    next: "active",
  },
  active: {
    line1: "No Active Projects found",
    line2: "",
    next: "all",
  },
  complete: {
    line1: "No Completed Projects found",
    line2: "",
    next: "active",
  },
  delayed: {
    line1: "Things are looking good!",
    line2: "No Delayed projects found.",
    next: "pending",
  },
  pending: {
    line1: "There doesn't seem to be anything waiting.",
    line2: "No projects have pending Draws",
    next: "active",
  },
};

const dhlcOnlyCSVColumns: ColumnId[] = ["Custom1"];
const superAdminOnlyCSVColumns: ColumnId[] = [
  "FullAddress",
  "NumDraws",
  "ProjectId",
  "DaysSinceLastDraw",
];

const csvColumns: (arg: {
  features: Pick<Features, "dhlcCustom">;
  role: Role;
}) => ColumnId[] = ({ features: { dhlcCustom }, role }) =>
  (
    [
      "Name",
      "Amount",
      "EffectiveBudget",
      "EndDate",
      "LoanIdentifier",
      "LoanAmount",
      "LoanMaturityDate",
      "LoanStartDate",
      "PercentBudgetRemaining",
      "PercentComplete",
      "RemainingLoanBalance",
      "RemainingLooserBalance",
      "RemainingTaskBalance",
      "TotalTaskValue",
      "TotalActiveTaskValue",
      "Health",
      "HealthMetric",
      "LoanType",
      "Actions",
      "Status",
    ] as ColumnId[]
  )
    .concat(dhlcCustom ? dhlcOnlyCSVColumns : [])
    .concat(role === Role.SuperAdmin ? superAdminOnlyCSVColumns : []);

const csvHeaders = (arg: {
  features: Pick<Features, "dhlcCustom">;
  role: Role;
}) =>
  csvColumns(arg).map(
    (column) =>
      availableColumns[column].csvHeader ?? availableColumns[column].label
  );

export type DisplayRow = DerivedRowContent & {
  key: string;
  project: BackendGetProjectsResponseEntry;
};

const columnName = (n: number) => "ABCDEFGHIJKLMNOPQRSTUVWXYZ".charAt(n); // spell-checker:ignore ABCDEFGHIJKLMNOPQRSTUVWXYZ

export function standardColumns({
  useCustom1Column,
  canDeleteProjects,
  useDotDotDotMenu,
}: {
  useCustom1Column: boolean;
  canDeleteProjects: boolean;
  useDotDotDotMenu: boolean;
}): ColumnId[] {
  return [
    "Name",
    "Amount",
    "RemainingLooserBalance",
    "LoanIdentifier",
    "LoanMaturityDate",
    "Health",
    "Actions",
    // "DaysSinceLastDraw",
    "DaysInDataSinceLastDraw",
    // "DaysAgoInDataSinceLastDraw",
    ...(useCustom1Column ? ["Custom1" as ColumnId] : []),
    ...(useDotDotDotMenu ? ["DotDotDot" as ColumnId] : []),
    ...(canDeleteProjects && !useDotDotDotMenu ? ["Delete" as ColumnId] : []),
    // "Status",
  ];
}

const ExportRow = styled("div")({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "end",
});

const LeftGapDiv = styled("div")(({ theme: { spacing } }) => ({
  marginLeft: spacing(1),
}));

function CollapsibleRow({
  row,
  currentView,
}: {
  row: DisplayRow;
  currentView: View;
}): JSX.Element {
  const [primaryColumn, ...restColumns] = currentView.columns;
  const keyRoot = row.ProjectId.value;
  const [open, setOpen] = React.useState(false);
  const [desiredOperation, setDesiredOperation] =
    React.useState<InvoiceActions>(InvoiceActions.None);

  const pendingInvoices = row.project.summary?.p ?? [];
  return (
    <>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={keyRoot}
        sx={
          row.Status.value === ProjectStatus.Complete
            ? ({ palette }) => ({
                "& > *": {
                  color: palette.text.disabled,
                },
              })
            : undefined
        }
      >
        {NESTED_FEATURE ? (
          <TableCell>
            {pendingInvoices.length > 0 ? (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            ) : null}
          </TableCell>
        ) : null}
        {/* !!!
        <TableCell component="th" id={labelId} scope="row">
          {primaryColumn.display} */}
        <TableCell key={`${keyRoot}-${primaryColumn}`}>
          {/* {row[primaryColumn.columnName].display} */}
          {row[primaryColumn].display}
        </TableCell>
        {restColumns.map((columnName) => (
          <TableCell
            align={availableColumns[columnName].align || "right"}
            key={`${keyRoot}-${columnName}`}
          >
            {row[columnName].display}
          </TableCell>
        ))}
      </TableRow>
      {pendingInvoices && NESTED_FEATURE ? (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box>
                {/* <Typography variant="h6" gutterBottom component="div">
                Pending Invoices
              </Typography> */}
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Invoice</TableCell>
                      <TableCell>Something</TableCell>
                      <TableCell align="right">Something Else</TableCell>
                      <TableCell align="right">Something More</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pendingInvoices.map((invoice) => (
                      <TableRow key={invoice.timestamp}>
                        <TableCell component="th" scope="row">
                          <StealthyLink
                            to={`/projectview/${row.ProjectId.value}/${ProjectViewTabs.Tasks}`}
                          >
                            {invoice.timestamp}
                          </StealthyLink>
                        </TableCell>
                        <TableCell>{invoice.currentApprover}</TableCell>
                        <TableCell>
                          <FormControl variant="standard" fullWidth>
                            <InputLabel id="update-select-label">
                              Update
                            </InputLabel>
                            <Select
                              variant="standard"
                              labelId="update-select-label"
                              id="update-select"
                              value={
                                desiredOperation === InvoiceActions.None
                                  ? undefined
                                  : desiredOperation
                              }
                              onChange={({ target: { value } }) => {
                                setDesiredOperation(value as InvoiceActions);

                                const control =
                                  document.activeElement as unknown as {
                                    blur: () => void;
                                  };
                                control.blur && control.blur();
                              }}
                            >
                              <MenuItem value="None">
                                <em>---</em>
                              </MenuItem>
                              <MenuItem value="Approve">Approve</MenuItem>
                              <MenuItem value="Reject">Reject</MenuItem>
                              <MenuItem value="Edit">Edit</MenuItem>
                            </Select>
                          </FormControl>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      ) : null}
    </>
  );
}

function ProjectGrid(props: {
  data: BackendGetProjectsResponse;
  loading: boolean;
  onRequestDelete: (projectId: string) => void;
  onUpdateStatus: (projectId: string, status: ProjectStatus) => void;
  viewId?: ViewID;
}): JSX.Element {
  const { role, showAdmin, requestAdminView } = useAppContext();
  const [order, setOrder] = React.useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = React.useState<ColumnId>("Name"); // column id
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState<DisplayRow[]>([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [attributeFilter, setAttributeFilter] = React.useState("");
  const { useNewUI } = useNewUIHook();

  const features = useFeatures();
  const entityFeatures = useEntityFeatures();
  const permissions = usePermissions();
  const { protocol, host } = window.location;
  const analytics = useAnalytics();

  const skinny = useMediaQuery("(max-width:400px)");

  // one-off on page load
  React.useEffect(function onMount() {
    analytics.track("page:grid");
    // we intentionally want to run this just on mount, so squelch the warning:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { onRequestDelete, onUpdateStatus, viewId: forcedView } = props;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const progressSpinner = searchParams.get("spinner");
  const viewId = forcedView || (searchParams.get("view") as ViewID) || "todo";
  const {
    isConciergeInspector,
    isFullConciergeInspector,
    availableInspectors: inspectorsList,
  } = useInspectors();
  const availableInspectors = useMemo(
    () =>
      Object.fromEntries(
        inspectorsList.map((inspector) => [inspector.email, inspector])
      ),
    [inspectorsList]
  );
  const availableRows = React.useMemo(() => {
    return stableSort(rows, getComparator(order, orderBy));
  }, [rows, order, orderBy]);

  const handleRequestSort = (column: ColumnId) => {
    const isSortColumn = orderBy === column;
    const isAsc = isSortColumn && order === "asc";
    // update rows so that when we re-sort, stability is based on state just prior
    setRows(availableRows);
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(column);
  };

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    /*
     * go through passed-in `props.data` (project list) and build intermediate representation for sort and display
     */
    const newRows: DisplayRow[] = [];
    for (let i = 0; i < props.data.length; i++) {
      // const response = await ProjectAPIs.getProjectTasks(
      //   props.data[i].project_id
      // );

      const project = props.data[i];
      // store as { name: { value: ..., formatted: "...", sortFormatted: <from column definition> }, loanAmount: { value: ..., formatted: "xxx", ... }, ...}
      const newRow = Object.entries(availableColumns).reduce(
        (result, [columnId, column]) => {
          const derivationIngredients = {
            ...project,
            // __response: response,
          };
          const { value, formatted, csv } = column.derive(
            derivationIngredients,
            {
              urlBase: `${protocol}//${host}`,
              permissions,
              entityFeatures,
              onRequestDelete,
              onUpdateStatus: (id, newStatus, pending) => {
                if (newStatus === ProjectStatus.Complete) {
                  // setViewRefreshNeeded(true); // "Complete" view is dynamic
                  if (pending.length > 0) {
                    enqueueSnackbar(
                      "The project you just marked complete still has Pending Draws",
                      { variant: "warning" }
                    );
                  }
                }
                onUpdateStatus(id, newStatus);
              },
              isConciergeInspector,
              isFullConciergeInspector,
              availableInspectors,
            }
          );

          if (
            config.nonProdEnv &&
            typeof formatted !== "string" &&
            csv === undefined
          ) {
            throw new Error(
              `Can't export elements: define a CSV flavor for ${columnId}`
            );
          }

          result[columnId as ColumnId] = {
            value,
            formatted,
            csv,
            display: column.linkToProjectView ? (
              <StealthyLink
                to={`/projectview/${derivationIngredients.project_id}/${column.linkToProjectView}`}
              >
                {formatted}
              </StealthyLink>
            ) : (
              formatted
            ),
          };
          result.key = derivationIngredients.project_id;

          return result;
        },
        {
          project,
        } as DisplayRow
      );
      newRows.push(newRow);
    }

    setRows(newRows);
  }, [
    availableInspectors,
    host,
    isConciergeInspector,
    isFullConciergeInspector,
    onRequestDelete,
    onUpdateStatus,
    permissions,
    props.data,
    protocol,
    enqueueSnackbar,
    entityFeatures,
  ]);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: { target: { value: string } }) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const context = useColumnDerivationContext();
  const availableViews = useMemo(() => {
    return createViews({ features, context });
  }, [context, features]);

  const currentView = availableViews[viewId];
  const navigate = useNavigate();
  const switchView = (newView: ViewID) => {
    if (forcedView /* i.e. "new ui" */) {
      navigate(`../${newView}`, { relative: "route" });
    } else {
      navigate({
        search: `${qs.stringify({ view: newView })}`,
      });
    }
  };

  const email = throwIfNot(useAuth().currentUserEmail, "email is required");
  const filteredRows = React.useMemo(
    () =>
      availableRows.filter((row) => {
        if (attributeFilter) {
          return (
            (row.SearchAttributes.value as string)
              .toUpperCase()
              .indexOf(attributeFilter.toUpperCase()) >= 0
          );
        }

        return currentView.filter(row.project, {
          email,
          role,
          isConciergeInspector,
        });
      }),
    [
      attributeFilter,
      availableRows,
      currentView,
      email,
      isConciergeInspector,
      role,
    ]
  );

  const { setAdminData, setAdminChildren, adminData, adminChildren } =
    useAdminData();
  React.useEffect(() => {
    setAdminData((data) => ({
      ...data,
      projects: filteredRows.map(({ project }) => project),
    }));
    return () =>
      setAdminData(({ filteredRows, ...rest }) => {
        return rest;
      });
  }, [filteredRows, setAdminData]);

  React.useEffect(() => {
    setAdminChildren((children) => ({
      ...children,
      bulkUserAdder: (
        <BulkUserAdder
          projects={filteredRows.map(({ project }) => ({
            ...project,
            users: project.users as EmailAddress[],
            img: project.img as string,
            location: project.location || null,
          }))}
        />
      ),
    }));
    return () => setAdminChildren(({ bulkUserAdder, ...children }) => children);
  }, [filteredRows, setAdminChildren]);

  const numEmptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, filteredRows.length - page * rowsPerPage);

  const csvExportData = React.useMemo(() => {
    const data = rows.map((row) =>
      csvColumns({ features, role }).map(
        (columnId) => row[columnId].csv ?? row[columnId].formatted
      )
    );
    return data.concat([
      csvColumns({ features, role }).map((columnId, idx) =>
        availableColumns[columnId].summable
          ? `=SUM(${columnName(idx)}2:${columnName(idx)}${data.length + 1})`
          : ""
      ),
    ]);
  }, [rows, features, role]);

  const whenEmpty = emptyViewActions[viewId];
  return props.loading && progressSpinner === "circle" ? (
    <CircularProgress />
  ) : (
    <Grid
      container
      sx={{ flexDirection: "row" }}
      direction="column"
      alignItems="center"
    >
      <Grid
        item
        container
        direction={"column"}
        width="100%"
        marginBottom={"-10vh"}
      >
        {/* <div className={classes.filtersContainer}>
            <FormControl>
              <InputLabel shrink id="grid-filter-report-type-label">
                View
              </InputLabel>
              <Select
                label="View"
                labelId="grid-filter-report-type-label"
                id="grid-filter-report-type"
                value={availableViews.findIndex(
                  ({ description }) => description === currentView.description
                )}
                onChange={({ target: { value } }) =>
                  setCurrentView(availableViews[value as number])
                }
              >
                {availableViews.map((view, idx) => (
                  <MenuItem key={view.description} value={idx}>
                    {view.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {currentView.useDates && (
              <div className={classes.dateFiltersContainer}>
                <DatePicker
                  className={classes.datePicker}
                  label="From"
                  value={fromDate}
                  format="MM/dd/yyyy"
                  onChange={(newDate) => setFromDate(newDate)}
                ></DatePicker>
                <DatePicker
                  className={classes.datePicker}
                  label="To"
                  value={toDate}
                  format="MM/dd/yyyy"
                  onChange={(newDate) => setToDate(newDate)}
                ></DatePicker>
              </div>
            )}
          </div> */}
        <ExportRow>
          <Tooltip
            title="Search by project or user email"
            // sx={{ position: "fixed", marginBottom: 5, zIndex: 1 }}
          >
            <TextField
              id="filter-by-attributes"
              label={skinny ? undefined : "Search all projects"}
              // InputLabelProps={{
              //   shrink: true,
              // }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              variant="standard"
              value={attributeFilter}
              onChange={({ target: { value } }) => {
                setAttributeFilter(value);
              }}
              style={{ marginBottom: "2vh" }}
            />
          </Tooltip>
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
              marginBottom: "1vh",
            }}
          >
            {useNewUI ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <div style={{ paddingTop: "2vw", marginRight: "-23vw" }}>
                  <HomeTabNavigator />
                </div>
              </div>
            ) : (
              <div>
                {attributeFilter ? null : (
                  <FormControl
                    variant="standard"
                    sx={({ spacing }) => ({
                      minWidth: spacing("Draw Pending".length * 1.4), // HACK: longest view description; really ought to measure
                    })}
                  >
                    <InputLabel
                      style={{ whiteSpace: "nowrap" }}
                      shrink
                      id="grid-filter-report-type-label"
                    >
                      Filter by Status
                    </InputLabel>
                    <Select
                      variant="standard"
                      label="Filter by Status"
                      labelId="grid-filter-report-type-label"
                      id="grid-filter-report-type"
                      value={viewId}
                      onChange={({ target: { value } }) => {
                        switchView(value as ViewID);
                      }}
                      disabled={Boolean(attributeFilter)}
                    >
                      {viewOrdering.map((id) => (
                        <MenuItem key={id} value={id}>
                          {availableViews[id].description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </div>
            )}

            <LeftGapDiv>
              <div style={{ marginBottom: useNewUI ? 7 : 0 }}>
                <CSVExport
                  data={csvExportData}
                  headers={csvHeaders({ features, role }) as string[]}
                  filename={sanitizeFilename(
                    `Portfolio - ${filenameDateFormat(new Date())}.csv`
                  )}
                />
              </div>
            </LeftGapDiv>
          </div>
        </ExportRow>
        <TableContainer
          style={{
            width: "100%",
            height: "65vh",
          }}
        >
          {filteredRows.length === 0 ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              {!attributeFilter && whenEmpty ? (
                <>
                  <p>
                    {whenEmpty.line1}
                    <br />
                    {whenEmpty.line2}
                  </p>
                  <Button
                    variant="contained"
                    onClick={() => {
                      switchView(whenEmpty.next ?? "all");
                    }}
                  >
                    View {availableViews[whenEmpty.next ?? "all"].description}
                  </Button>
                </>
              ) : (
                <p>No matching projects found</p>
              )}
            </div>
          ) : (
            <Table
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
              stickyHeader
              // size="small"
            >
              <TableHeadWithSorters
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                headCells={currentView.columns}
                extraCellsLeft={1}
              />

              <TableBody
                sx={{
                  "& .MuiTableCell-root": {
                    paddingTop: 0,
                    paddingBottom: 0,
                    // fontSize: 32,
                  },
                }}
              >
                {filteredRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <CollapsibleRow
                      row={row}
                      key={row.ProjectId.value}
                      currentView={currentView}
                    />
                  ))}
                {numEmptyRows > 0 && (
                  <TableRow>
                    <TableCell style={{ padding: 0 }} colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        {viewId === "todo" && filteredRows.length === 0 ? null : (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
        {!props.loading ? null : (
          <>{progressSpinner !== "none" ? <LinearProgress /> : null}</>
        )}
        <AdminViewContainer
          initialPosition="bottom"
          isVisible={showAdmin}
          onVisibleChange={() => requestAdminView(!showAdmin)}
          dimMode="transparent"
        >
          <AdminView {...adminData}>
            {Object.entries(adminChildren).map(([key, node]) => node)}
          </AdminView>
        </AdminViewContainer>
      </Grid>
    </Grid>
  );
}

// enabling this makes a bunch of noise with useSWR
// ProjectGrid.whyDidYouRender = true;

export default /* observer */ ProjectGrid;
