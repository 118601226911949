/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
    "\n  fragment CheckinActivityFragment on CheckinRequest {\n    id\n    comment\n    poc {\n      email\n      id\n    }\n    activity {\n      edges {\n        node {\n          id\n          comments {\n            edges {\n              node {\n                comment\n                id\n                timestamp\n              }\n            }\n          }\n          timeIn\n          timeOut\n        }\n      }\n    }\n  }\n": types.CheckinActivityFragmentFragmentDoc,
    "\n  fragment CheckinsFragment on LegacyProject {\n    checkins {\n      edges {\n        node {\n          # __typename\n          # ...CheckinRequestDetails\n          ...CheckinActivityFragment\n        }\n      }\n    }\n  }\n": types.CheckinsFragmentFragmentDoc,
    "\n  query listProjectCheckinsQuery($projectId: String!) {\n    project(id: $projectId) {\n      # pageInfo {\n      #   hasNextPage\n      #   endCursor\n      # }\n\n      # project_id\n      # address\n      # __typename\n      ...CheckinsFragment\n    }\n  }\n": types.ListProjectCheckinsQueryDocument,
    "\n  query listProjectsQuery(\n    $first: Float\n    $last: Float\n    $before: Cursor\n    $after: Cursor\n  ) {\n    projects(first: $first, last: $last, before: $before, after: $after) {\n      # pageInfo {\n      #   hasNextPage\n      #   endCursor\n      # }\n      edges {\n        node {\n          project_id\n          address\n          city\n          state\n          zip\n          name\n          unit\n        }\n      }\n    }\n  }\n": types.ListProjectsQueryDocument,
    "\n  query listPortfolioCheckinsQuery(\n    $first: Float\n    $last: Float\n    $before: Cursor\n    $after: Cursor\n  ) {\n    projects(first: $first, last: $last, before: $before, after: $after) {\n      # pageInfo {\n      #   hasNextPage\n      #   endCursor\n      # }\n      edges {\n        node {\n          #   project_id\n          address\n          ...CheckinsFragment\n        }\n      }\n    }\n  }\n": types.ListPortfolioCheckinsQueryDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CheckinActivityFragment on CheckinRequest {\n    id\n    comment\n    poc {\n      email\n      id\n    }\n    activity {\n      edges {\n        node {\n          id\n          comments {\n            edges {\n              node {\n                comment\n                id\n                timestamp\n              }\n            }\n          }\n          timeIn\n          timeOut\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment CheckinActivityFragment on CheckinRequest {\n    id\n    comment\n    poc {\n      email\n      id\n    }\n    activity {\n      edges {\n        node {\n          id\n          comments {\n            edges {\n              node {\n                comment\n                id\n                timestamp\n              }\n            }\n          }\n          timeIn\n          timeOut\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CheckinsFragment on LegacyProject {\n    checkins {\n      edges {\n        node {\n          # __typename\n          # ...CheckinRequestDetails\n          ...CheckinActivityFragment\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment CheckinsFragment on LegacyProject {\n    checkins {\n      edges {\n        node {\n          # __typename\n          # ...CheckinRequestDetails\n          ...CheckinActivityFragment\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query listProjectCheckinsQuery($projectId: String!) {\n    project(id: $projectId) {\n      # pageInfo {\n      #   hasNextPage\n      #   endCursor\n      # }\n\n      # project_id\n      # address\n      # __typename\n      ...CheckinsFragment\n    }\n  }\n"): (typeof documents)["\n  query listProjectCheckinsQuery($projectId: String!) {\n    project(id: $projectId) {\n      # pageInfo {\n      #   hasNextPage\n      #   endCursor\n      # }\n\n      # project_id\n      # address\n      # __typename\n      ...CheckinsFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query listProjectsQuery(\n    $first: Float\n    $last: Float\n    $before: Cursor\n    $after: Cursor\n  ) {\n    projects(first: $first, last: $last, before: $before, after: $after) {\n      # pageInfo {\n      #   hasNextPage\n      #   endCursor\n      # }\n      edges {\n        node {\n          project_id\n          address\n          city\n          state\n          zip\n          name\n          unit\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query listProjectsQuery(\n    $first: Float\n    $last: Float\n    $before: Cursor\n    $after: Cursor\n  ) {\n    projects(first: $first, last: $last, before: $before, after: $after) {\n      # pageInfo {\n      #   hasNextPage\n      #   endCursor\n      # }\n      edges {\n        node {\n          project_id\n          address\n          city\n          state\n          zip\n          name\n          unit\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query listPortfolioCheckinsQuery(\n    $first: Float\n    $last: Float\n    $before: Cursor\n    $after: Cursor\n  ) {\n    projects(first: $first, last: $last, before: $before, after: $after) {\n      # pageInfo {\n      #   hasNextPage\n      #   endCursor\n      # }\n      edges {\n        node {\n          #   project_id\n          address\n          ...CheckinsFragment\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query listPortfolioCheckinsQuery(\n    $first: Float\n    $last: Float\n    $before: Cursor\n    $after: Cursor\n  ) {\n    projects(first: $first, last: $last, before: $before, after: $after) {\n      # pageInfo {\n      #   hasNextPage\n      #   endCursor\n      # }\n      edges {\n        node {\n          #   project_id\n          address\n          ...CheckinsFragment\n        }\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;