import React from "react";
import TextField from "@mui/material/TextField";
import { Button, FormControl, Grid } from "@mui/material";
import { buttonCTA, buttonCancel } from "@/Lib/StyleHelpers";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { DatePicker } from "@mui/x-date-pickers";
import * as z from "zod";
import colors from "@tim/assets/theme/base/colors";
import { ZodSchema as AddressSchema } from "@/Forms/AddressForm";
import { enqueueSnackbar } from "notistack";
import { logError } from "@/Lib/ErrorLogging";
import { CircularProgress } from "@mui/material";
import { requiredBySchema } from "@/Lib/util/zodUtils";

function TurnoverPage() {
  const turnoverSchema = AddressSchema.extend({
    vacateDate: z.date(),
    turnoverDate: z.date(),
    moveOutDate: z.date(),
  });
  const formMethods = useForm<Turnover>({
    mode: "onChange",
    resolver: zodResolver(turnoverSchema),
    defaultValues: {},
  });
  type Turnover = z.infer<typeof turnoverSchema>;

  const [submitting, setSubmitting] = React.useState<boolean>();

  const onSubmit: SubmitHandler<Turnover> = (formValues) => {
    setSubmitting(true);
    console.log(formValues);
    const createProject = Promise.resolve("Not implemented");
    enqueueSnackbar({
      message: "Not implemented",
      variant: "error",
    });
    createProject

      .catch((err) => {
        logError(new Error("Add project failure (create from inspection)"), {
          err,
        });
        enqueueSnackbar({
          message:
            "Oops! Something went wrong and project could not be added. The team has been notified",
          variant: "error",
        });
      })
      .finally(() => {
        return setSubmitting(false);
      });
  };
  const { control, handleSubmit } = formMethods;
  const timeDifference = Math.abs(
    formMethods.watch("turnoverDate")?.getTime() -
      formMethods.watch("moveOutDate")?.getTime()
  );
  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit, console.error)}>
        <div>
          <div style={{ display: "flex", marginTop: "1vh" }}>
            <div
              style={{
                width: "25vw",
                backgroundColor: `${colors.white}`,
                boxShadow: "0px 5px 8px #C0C0C0	",
                paddingTop: 40,
                paddingBottom: 40,
                minWidth: 450,
              }}
            >
              <div style={{ marginLeft: "1vw" }}>
                <Grid container spacing={2}>
                  <Grid item lg={6}>
                    <Controller
                      name="vacateDate"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl>
                          <div style={{ width: "11vw", minWidth: 200 }}>
                            <DatePicker
                              {...field}
                              label={"Notice to vacate date"}
                              value={field.value ?? null}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  id="vacateDate"
                                  required={requiredBySchema(
                                    turnoverSchema,
                                    "vacateDate"
                                  )}
                                  error={Boolean(error)}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item lg={6}>
                    <Controller
                      name="moveOutDate"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl>
                          <div style={{ width: "11vw", minWidth: 200 }}>
                            <DatePicker
                              {...field}
                              label={"Move Out Date?"}
                              value={field.value ?? null}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  id="moveOutDate"
                                  required={requiredBySchema(
                                    turnoverSchema,
                                    "moveOutDate"
                                  )}
                                  error={Boolean(error)}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item lg={6}>
                    <Controller
                      name="turnoverDate"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl>
                          <div
                            style={{
                              width: "11vw",
                              minWidth: 180,
                            }}
                          >
                            <DatePicker
                              {...field}
                              label={"Turnover Date"}
                              value={field.value ?? null}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  id="turnoverDate"
                                  required={requiredBySchema(
                                    turnoverSchema,
                                    "turnoverDate"
                                  )}
                                  error={Boolean(error)}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item lg={6}>
                    <FormControl>
                      <TextField
                        variant="outlined"
                        id="daysToTurn"
                        label="days to turn"
                        type="text"
                        value={timeDifference / (1000 * 3600 * 24) || 0}
                        sx={{ width: "5vw", minWidth: 120 }}
                        disabled
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginLeft: "1vw",
                  marginRight: "1vw",
                  marginTop: "2vh",
                }}
              >
                <Button
                  variant="contained"
                  size="medium"
                  color="cancel"
                  sx={buttonCancel}
                >
                  Leave
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  size="medium"
                  color="cta"
                  sx={buttonCTA}
                  disabled={submitting}
                >
                  {submitting ? <CircularProgress /> : "Submit"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}

export default TurnoverPage;
