import React, { useState } from "react";
import brandDark from "@/Assets/Images/PC_White_Blue.svg";
import brandWhite from "@/Assets/Images/PC_White.svg";
import { home } from "./Routes";
import { setMiniSidenav, useMaterialUIController } from "@tim/context";
import Configurator from "@tim/examples/Configurator";
import { Sidenav } from "./Sidenav";

/**
 * Sidenav wrapper that auto-opens on mouse hover etc
 *
 * @returns @see(Sidenav) that takes care of zooming itself on hover
 */
export const SelfManagedSidenav = () => {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    // direction,
    // layout,
    // openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  return (
    <>
      <Sidenav
        brand={
          (transparentSidenav && !darkMode) || whiteSidenav
            ? brandDark
            : brandWhite
        }
        color={sidenavColor}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        home={home}
      />
      <Configurator />
    </>
  );
};
