import React from "react";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import { useAppContext } from "@lib/UserContext";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import ClearIcon from "@mui/icons-material/Clear";
import { DropzoneArea } from "react-mui-dropzone";
import sanitizeFilename from "sanitize-filename";
import { Invoice, InvoiceActions, PendingInvoice, Task } from "../../../Models";
import {
  CalculatedDisplayInfo,
  csvHeaders,
  DrawReportButton,
  commonStyles,
  InvoiceTotal,
  CalculatedDraw,
  invoiceDescription,
  MarkPaidCheckbox,
  InvoiceContent,
  InvoiceHeader,
  DrawReportButtons,
  DrawLines,
  useDisplayDraws,
} from "./InvoicesCommon";
import { CommentsSection } from "./CommentsSection";
import { DisplayFiles } from "../../../Components/DisplayFiles";
import { useMoney } from "@lib/hooks/useMoney";
import { usePermissions } from "@lib/hooks/usePermissions";
import {
  useFeatures,
  useInvoiceComments,
  useAnalytics,
  useBackend,
} from "@lib/hooks";
import { CSVExport } from "../../../Components/CSVExport";
import {
  Badge,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { ReactComponent as conciergeInspectionIcon } from "../../../Assets/Images/Inspection_Icon_PC_Blue.svg";
import { useProjectUsers } from "@lib/hooks/useProjectUsers";
import { Role } from "@lib/roles";
import {
  useInvoiceAncestors,
  useProjectInvoices,
} from "@lib/hooks/useProjectInvoices";
import { useInspectors } from "@lib/hooks/useInspectors";

import {
  DrawRequestValuesEditor,
  TaskEditRequest,
} from "./DrawRequestValuesEditor";
import { FormProvider, useForm } from "react-hook-form";
import { useProjectDetails } from "@lib/hooks/useProjectDetails";
import { logAnomaly, logError } from "@lib/ErrorLogging";
import { PeopleRoutingEditor } from "../../../Components/PeopleRoutingEditor";
import { useSnackbar } from "notistack";
import {
  AllPersonInputs,
  AllPersonInputsSchema,
  BackendUserResponse,
  FileWithId,
  PersistentProjectFile,
} from "@lib/APITypes";
import { ImageGallery } from "../../../Components/ImageGallery";
import { useEntityFeatures } from "@lib/hooks/useEntityFeatures";
import { ConfirmDialog } from "../../../Components/ConfirmDialog";
// import { LocalTaskFile } from "./LocalTaskFile";
import { validateInvoiceRouting } from "./validateInvoiceRouting";
import { InspectorForm } from "../Info/InspectorForm";
import { zodResolver } from "@hookform/resolvers/zod";
import { commonLayouts } from "../../../Forms/PersonForm";
import useSWR from "swr";
import config from "../../../config";
import { APIError, uploadSupportingFile, validateUploadedFile } from "@lib/API";
import { DIPhotoButton } from "../Info/DIPhotoButton";
// import // taskEither as TE,
// // apply as AP,
// // task as T,
// // either as E,
// // array as A,
// // function as F,
// "fp-ts";
import { not } from "fp-ts/lib/Predicate";

import { isTempFile, TempFile, useIndexedDB } from "@lib/hooks/useIdb";
import { useProjectFiles } from "@lib/hooks/useProjectFiles";
import { gps } from "exifr";
import { LightboxFiles } from "./LightboxFile";
import { Unarray } from "@lib/misc";
import { LocationOff, LocationOn } from "@mui/icons-material";
import {
  distance,
  effectivePhotoLocation,
  GpsOutput,
  isNear,
  isValidLocation,
} from "./locationUtils";
import { AttachedFilesIcon } from "@lib/uikit";
import { isIOS } from "react-device-detect";
import {
  InspectionPaymentStripeInvoice,
  InspectionPaymentStripePaymentLink,
  LinkedFile,
  StoredFile,
} from "@project-centerline/project-centerline-api-types";
import { drawForTask, extractAmount } from "./taskUtils";
import { pipe } from "fp-ts/lib/function";
import { useAuth } from "@lib/hooks/useAuth";
import { useGetUserRole } from "@/Lib/hooks/useGetUserRole";
import { throwIfNot } from "@lib/util/throwIfNot";
import {
  UploadProgressDialog,
  useProgress,
} from "../../../Components/UploadProgressDialog";

type PossibleInspectionFile =
  | TempFile
  | (PersistentProjectFile & Pick<TempFile, "gpsInfo" | "deviceLocation">);

const makeNewPendingFile =
  (invoiceId: Invoice["invoice_id"]) => (taskId: Task["id"]) => (file: File) =>
    ({
      file,
      invoiceId,
      taskId,
      uploaded: false,
      validated: false,
    } as TempFile);

// type DBFoo = ReturnType<typeof useIndexedDB>;

// const addToTempFiles = (db: DBFoo) => {
//   // return TE.tryCatch(() => {
//   const store = db.db
//     ?.transaction("tempFiles", "readwrite")
//     .objectStore("tempFiles");
//   if (!store) {
//     throw new Error("no store for temp files");
//   }
//   return (files: TempFile[]) => files.map((f) => store.add(f));
//   // const foo = flow(newPendingFile, TE.tryCatch(store.add, E.toError));
//   // return TE.traverseArray(foo);
//   // }, E.toError);
// };

export interface PendingInvoiceProps {
  invoice: Readonly<PendingInvoice>;

  invoiceDisplayNumber: number;
  onDeleteRequested: (invoice: PendingInvoice) => unknown;
  filenameHint: string;
  /** General purpose callback: We uploaded files, approved, etc. */
  onSomethingChanged?: () => void;
  /** tasks (duh TODO: say something better */
  tasks: Task[];

  /** project ID */
  projectId: string;

  onTaskViewRequested: (taskId: string) => void;

  onEditLineItemRequested: ({
    task,
    invoice,
  }: {
    task: Task;
    invoice: PendingInvoice;
  }) => void;

  // TODO: Make file handling sane and regularized
  onDeleteLineItemFile: (file: PersistentProjectFile) => Promise<void>;
  onDeleteInvoiceFile: (
    invoice: PendingInvoice,
    file: StoredFile | LinkedFile
  ) => Promise<void>;

  files: PersistentProjectFile[];
}

// https://www.benmvp.com/blog/filtering-undefined-elements-from-array-typescript/
const isBackendUserResponse = (
  item: BackendUserResponse | undefined
): item is BackendUserResponse => Boolean(item);

const InspectionDialog = ({
  open,
  projectId,
  onClose,
  invoice,
  onInspectionRequested,
}: {
  open: boolean;
  projectId: string;
  onClose: () => void;
  invoice: PendingInvoice;
  onInspectionRequested: () => void; // TODO: grumble this should not be necessary but first InvoiceView needs to start using useInvoices / SWR
}) => {
  const analytics = useAnalytics();
  const { enqueueSnackbar } = useSnackbar();

  const { invoice_id: invoiceId } = invoice;

  const invoiceFns = useProjectInvoices(projectId).project.invoice(invoiceId);
  const requestInspection = invoiceFns?.requestInspection;

  const [busy, setBusy] = React.useState(false);
  const [comments, setComments] = React.useState("");

  const { availableInspectors, isConciergeInspector } = useInspectors();
  const [inspectorUser, setInspectorUser] = React.useState(
    availableInspectors[0]
  );

  const {
    project: { users, addUser },
  } = useProjectUsers(projectId);
  const { addComment } = useInvoiceComments({ invoiceId });
  const onRequestInspection = async () => {
    setBusy(true);

    function projectUserForEmail(email?: string) {
      return email ? users?.find((u) => u.email === email) : undefined;
    }
    const inspectorProjectUser = projectUserForEmail(inspectorUser.email);

    const oneClick = inspectorUser.isOneClick;
    try {
      const inspector =
        inspectorProjectUser ||
        (await addUser({
          ...inspectorUser,
          role: Role.Inspector,
          external: oneClick,
        }));

      if (
        oneClick &&
        !validateInvoiceRouting(
          [inspector]
            .concat(
              [
                projectUserForEmail(
                  invoice.current_approver /* grr PendingInvoice is supposed to protect this */ ??
                    ""
                ),
              ].filter(isBackendUserResponse)
            )
            .concat(
              invoice.remaining_approvers
                ?.map(projectUserForEmail)
                .filter(isBackendUserResponse) ?? []
            ),
          (users ?? []).concat(inspector),
          enqueueSnackbar
        )
      ) {
        return;
      }

      analytics.track(
        `${oneClick ? "one-click" : "concierge"} inspection request`
      );

      await Promise.all([
        addComment?.(
          [`${oneClick ? "One-Click" : "Concierge"} Inspection Requested`]
            .concat((comments || "").split("\n"))
            .join("\n")
        ),
        requestInspection?.({ inspector, comments }),
      ]);
      onInspectionRequested();
      setBusy(false);
      onClose();
    } catch (err) {
      logError(err);
      enqueueSnackbar("Could not create request. Please try again later.", {
        variant: "error",
      });
    } finally {
      setBusy(false);
    }
  };

  const { companyFirst } = commonLayouts;
  const formMethods = useForm<AllPersonInputs>({
    defaultValues: Object.fromEntries(
      Object.keys(AllPersonInputsSchema.shape).map((k) => [k, ""])
    ),
    resolver: zodResolver(
      AllPersonInputsSchema.pick(
        Object.fromEntries(companyFirst.map((k) => [k, true])) as Record<
          (typeof companyFirst)[number],
          true
        >
      )
    ),
  });

  if (!PendingInvoice.is(invoice)) {
    throw new Error("The Pending invoice Club is for Pending invoices only");
  }
  return invoiceFns ? (
    <Dialog
      onClose={onClose}
      aria-labelledby="request-inspection-dialog-title"
      open={open}
    >
      <DialogTitle id="request-inspection-dialog-title">
        Request Inspection
      </DialogTitle>
      <DialogContent>
        <FormProvider {...formMethods}>
          <form
            id="add-user-form"
            onSubmit={formMethods.handleSubmit(
              () => {
                throw new Error("Not implemented");
              },
              (foo) => {
                // TODO: how hacky is this? We are using the error to submit. It probably blows up on invalid emails
                onInspectionRequested();
              }
            )}
          >
            <InspectorForm
              variant="inspectorsAndOneClicks"
              // {...props}
              onChange={setInspectorUser}
            />
          </form>
        </FormProvider>

        <TextField
          variant="standard"
          sx={({ spacing }) => ({
            paddingTop: spacing(1),
          })}
          id="description"
          name="description"
          label="Comments"
          fullWidth
          multiline
          rows={4}
          onChange={({ target: { value } }) => setComments(value)}
          value={comments}
          autoFocus
          placeholder={
            isConciergeInspector(inspectorUser)
              ? "Anything we should know?"
              : "Please enter any comments for the inspector"
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button disabled={busy} color="primary" onClick={onRequestInspection}>
          {busy ? <CircularProgress /> : "Request Inspection"}
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
};

const EditValues = ({
  displayInfo: {
    invoice: {
      invoice_id: invoiceId,
      project_id: projectId,
      drawItems,
      is_put,
    },
  },
  tasks,
  onClose,
  onTaskViewRequested,
  onDeleteFile,
  files,
}: {
  displayInfo: Pick<CalculatedDisplayInfo, "invoice">;
  onClose: () => void;
  onTaskViewRequested?: TaskEditRequest;
  onDeleteFile: (index: number) => Promise<void>;
  files: PersistentProjectFile[];
} & Pick<PendingInvoiceProps, "tasks">) => {
  const { formatMoney } = useMoney();
  const analytics = useAnalytics();

  const putMode = Boolean(is_put);
  const updateDraws =
    useProjectInvoices(projectId).project.invoice(invoiceId)?.updateDraws;

  const { retention = 0 } = useProjectDetails(projectId).project?.details ?? {};

  const formMethods = useForm({
    defaultValues: {
      putMode,
      amounts: tasks
        .map((task) => {
          // if at least one has a task, we will not try to match on title.
          // https://projectcenterline.slack.com/archives/G01D11FMKB8/p1695340916470799
          const modern = drawItems.some(({ task }) => task);
          return pipe(
            drawItems,
            drawForTask(task, { matchTitles: !modern }),
            extractAmount(() => 0)
          );
        })
        .slice(),
    },
  });
  const { watch } = formMethods;

  const amounts = watch("amounts");
  const totalAmount = amounts.reduce(
    (sum, amountRequested) => sum + (amountRequested ?? 0),
    0
  );

  const { enqueueSnackbar } = useSnackbar();
  const [busy, setBusy] = React.useState(false);
  const handleUpdate = () => {
    setBusy(true);
    const potentialUpdates: Invoice["drawItems"] = tasks.map((task, i) => ({
      task,
      title: task.title,
      amount: amounts[i],
    }));
    updateDraws?.(potentialUpdates.filter(({ amount }) => amount))
      .catch((err) => {
        if (err.response?.status === 412) {
          enqueueSnackbar(
            "It looks like someone else already edited this draw. Please try again.",
            { variant: "warning" }
          );
        } else {
          logError(new Error("edit draw failure"), { err });
          analytics.track("draw:edited:error");

          enqueueSnackbar("Sorry, but something went wrong.", {
            variant: "error",
          });
        }
        setBusy(false);
      })
      .then(() => {
        analytics.track("draw:edited");
        setBusy(false);
        onClose();
      });
  };

  const { fetchInitialRequest } = useInvoiceAncestors({ projectId });
  const initialRequestFetcher = fetchInitialRequest(invoiceId);
  const initialAmounts = tasks
    .map(initialRequestFetcher)
    .map((amount) => amount ?? 0);

  return (
    <FormProvider {...formMethods}>
      <Dialog
        maxWidth="xl"
        open
        onClose={onClose}
        aria-labelledby="edit-values-dialog-title"
      >
        <DialogTitle id="edit-values-dialog-title">
          Update {putMode ? "Put" : "Draw"} Values
        </DialogTitle>
        <DialogContent>
          <DrawRequestValuesEditor
            retention={retention || 0}
            tasks={tasks}
            initialAmounts={initialAmounts}
            putMode={putMode}
            // onTaskViewRequested={onTaskViewRequested}
            // temporarily disabled b/c too hard to get z-order right files={files.filter((f) => f.invoice_id === invoiceId)}
            onDeleteFile={onDeleteFile}
            // rowsPerPage={10}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleUpdate} disabled={busy}>
            {busy ? (
              <CircularProgress />
            ) : (
              `Update ${formatMoney(Math.abs(totalAmount))}`
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
};

// const formatInspection = (inspection: BackendGetInspectionResponse) =>
//   JSON.stringify(inspection);

const MaybeTooltip = ({
  title,
  children,
}: {
  title?: string;
  children: JSX.Element;
}) =>
  title ? <Tooltip title={title}>{children}</Tooltip> : <div>{children}</div>;

export function PendingInvoiceComponent({
  invoice,
  invoiceDisplayNumber: key,
  onDeleteRequested,
  filenameHint,
  onSomethingChanged,
  tasks,
  files,
  projectId,
  onTaskViewRequested,
  onEditLineItemRequested,
  onDeleteInvoiceFile,
  onDeleteLineItemFile,
}: PendingInvoiceProps): JSX.Element {
  const analytics = useAnalytics();
  const { formatMoney } = useMoney();
  const { role } = useAppContext();
  const {
    canApproveDrawRequests,
    canModifyDrawRequests,
    canRequestConciergeInspection,
    canDeleteDrawRequests,
    canSeeVendorInspectionLinks,
    canDoLenderAdminThings,
    // canAddDigitalInspectionPhotos
    canRemoveInvoiceComment,
  } = usePermissions();
  const canAddDigitalInspectionPhotos = [
    Role.Owner,
    Role.Lender,
    Role.LenderAdmin,
    Role.SuperAdmin,
  ].includes(role);
  const { maxFileUploadSize, maxFileUploadCount } = useFeatures();
  const {
    perLineItemAttachments: canSeePerLineItemAttachments,
    restrictInspectors,
    digitalInspections: digitalInspectionsFeature,
    frontend: {
      drawDocsRequired: { approval: requiredDrawFiles },
    },
  } = useEntityFeatures();
  const {
    availableInspectors,
    isConciergeInspector,
    isFullConciergeInspector,
    isDigitalInspector,
    availableOneClicks,
  } = useInspectors();

  const { currentUserEmail } = useAuth();
  const currentUser = useGetUserRole()?.user;

  const {
    invoice_id: invoiceId,
    currentInspection,
    timestamp: invoiceDate,
    delegate,
    current_approver: currentApprover,
  } = invoice;

  const db = useIndexedDB("pcFrontend");
  const [recentPhotos, setRecentPhotos] = React.useState<TempFile[]>([]);
  const makeNewPendingFileForTask = makeNewPendingFile(invoiceId);
  const {
    project: { addFiles },
  } = useProjectFiles({ projectId });
  const { location: projectLocation } =
    useProjectDetails(projectId).project?.details ?? {};
  const projectLocationIsValid = isValidLocation(projectLocation);

  const currentInspectionPayment = currentInspection?.payment;
  const paymentType = currentInspectionPayment?.type;
  const paymentRequired =
    paymentType !== "not-required" &&
    currentInspectionPayment?.status === "pending";

  const stripePaymentUrl =
    (InspectionPaymentStripeInvoice.is(currentInspectionPayment) &&
      currentInspectionPayment.invoice.url) ||
    (InspectionPaymentStripePaymentLink.is(currentInspectionPayment) &&
      currentInspectionPayment.paymentLink.url);
  const receiptUrl =
    currentInspectionPayment?.type === "stripe" &&
    currentInspectionPayment.receiptUrl !== "TODO receipt URL" &&
    currentInspectionPayment.receiptUrl;

  const { addComment, comments, removeComment } = useInvoiceComments({
    invoiceId: invoiceId,
  });

  const amountDue = (paymentRequired && currentInspectionPayment?.amount) || 0;
  const { getInspection } = useBackend();
  const currentInspectionId = currentInspection?.id;
  const { data: inspectionDetails, error } = useSWR(
    () => "/inspection/" + currentInspectionId,
    () =>
      currentInspectionId
        ? getInspection({ id: currentInspectionId })
        : undefined,
    {
      focusThrottleInterval: 5 * 60 * 1000,
    }
  );
  if (error) {
    if (config.nonProdEnv) {
      console.warn(new Error("Unexpected inspection fetch failure"), {
        error,
        currentInspectionId,
        currentInspection: inspectionDetails,
      });
    } else {
      logAnomaly(new Error("Unexpected inspection fetch failure"), {
        error,
        currentInspectionId,
        currentInspection: inspectionDetails,
      });
    }
  }

  const actingAsConcierge =
    isConciergeInspector({
      email: throwIfNot(currentUserEmail, "email is required"),
    }) || currentUser?.role === Role.SuperAdmin;

  const [filesToAdd, setFilesToAdd] = React.useState<File[]>([]);
  const [fileToDelete, setFileToDelete] = React.useState<
    | {
        file: StoredFile | LinkedFile | PersistentProjectFile | TempFile;
        onDeleted: () => void;
        onCancel: () => void;
      }
    | undefined
  >();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  function onDropzoneFiles(newFiles: File[]) {
    const filesAdded = newFiles.filter((file) => !filesToAdd.includes(file));
    if (filesAdded.some((file) => /\.heic$/i.test(file.name))) {
      // spell-checker:ignore heic
      enqueueSnackbar(
        "HEIC files will not appear in draw reports due to patent/licensing issues. We're working on it",
        { variant: "warning" }
      );
    }
    setFilesToAdd(newFiles);
  }

  const [addDoc, setAddDoc] = React.useState(false);
  const [routingEditorState, setRoutingEditorState] = React.useState<
    "open" | "closed" | "busy"
  >("closed");

  const invoiceFns = useProjectInvoices(projectId).project.invoice(invoiceId);

  const [loading, setLoading] = React.useState<false | "files">(false);
  const [progress, setProgress] = useProgress();
  const handleUploadDocument = () => {
    setLoading("files");
    invoiceFns
      ?.addFiles(filesToAdd, setProgress)
      .then((response) => {
        setAddDoc(false);
        onSomethingChanged && setTimeout(onSomethingChanged, 200);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleApproval = (invoice: Invoice, action: InvoiceActions) => {
    invoiceFns
      ?.setApproval(action)
      .then((response) => {
        // analytics.track(`draw:${action}`);
        onSomethingChanged?.();
      })
      .catch((error) => {
        const err = error as APIError;
        const message = `Error Approving Draw.  ${
          (err.response?.status === 412 && err.response?.data?.reason) ||
          "Please try again later"
        }`;
        logError(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      });
  };

  const [inspectionDialogOpen, setInspectionDialogOpen] = React.useState(false);
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);

  const automatedInspectionActive =
    inspectionDetails && inspectionDetails.status !== "complete";
  const inspectionPending = Boolean(delegate) || automatedInspectionActive;
  const inspectionRequested = isConciergeInspector({
    email: currentApprover || "",
  });
  const digitalInspectionActive = isDigitalInspector({
    email: currentApprover || "",
  });

  const fullConciergeInspectionPending =
    inspectionPending &&
    delegate &&
    isFullConciergeInspector({ email: delegate });

  const inspectionActive = inspectionRequested || inspectionPending;

  const canEditIndividualInvoiceRouting =
    [Role.SuperAdmin, Role.LenderAdmin].includes(role) &&
    !automatedInspectionActive;

  const {
    project: { users: projectUsers = [] },
  } = useProjectUsers(projectId);

  const handleEditLineItemRequested = ({ draw }: { draw: CalculatedDraw }) => {
    const task = draw.task;
    if (!task) {
      logAnomaly(new Error("Can't find task to edit"), { tasks, draw });
      enqueueSnackbar("Sorry, something went wrong", { variant: "error" });
      return;
    }
    onEditLineItemRequested({ invoice, task });
  };

  const [lightboxFiles, setLightboxFiles] = React.useState<LightboxFiles>([]);
  const [lightboxOpen, setLightboxOpen] = React.useState(false);

  const handleShowFiles = (
    files: (Unarray<LightboxFiles> | PossibleInspectionFile)[]
  ) => {
    setLightboxFiles(
      files.map((f) => {
        const fileInfo =
          LinkedFile.is(f) || StoredFile.is(f) ? f : f.destination || f;

        const photoLocation = effectivePhotoLocation(
          f as PossibleInspectionFile
        );
        const isNearProject =
          photoLocation &&
          projectLocationIsValid &&
          isNear(photoLocation as GpsOutput /* why? */, projectLocation);

        const adornments = [
          photoLocation
            ? isNearProject
              ? goodLocationDataRef
              : badLocationDataRef
            : indeterminateLocationDataRef,
          // indeterminateLocationDataRef,  uncomment this to see slots in action
        ];

        return {
          ...fileInfo,
          leftAdornments:
            digitalInspectionsFeature && digitalInspectionActive
              ? adornments.map((r) => r.current)
              : undefined,
        };
      })
    );
    setLightboxOpen(true);
  };

  const inspectionPendingStatusDescription =
    (inspectionDetails?.status === "complete" && "Inspection Completed") ||
    (inspectionDetails?.status === "waiting-dispatch" &&
      "Inspection Awaiting Dispatch") ||
    (inspectionDetails?.status === "waiting-assigned" &&
      "Inspector Assigned") ||
    "Inspection Pending";

  const numDrawFiles =
    invoice.storedFiles.length +
    files.filter((f) => f.invoice_id === invoiceId).length;
  const [showOverrideLackingFilesDialog, setShowOverrideLackingFilesDialog] =
    React.useState(false);
  const [
    showOverrideDigitalInspectionPhotosDialog,
    setShowOverrideDigitalInspectionPhotosDialog,
  ] = React.useState(false);

  const insufficientRequiredFiles = numDrawFiles < requiredDrawFiles;
  const approveDisabledForFileCount =
    insufficientRequiredFiles && !canDoLenderAdminThings;

  const activeTasks = tasks.filter(({ task_value }) => task_value !== 0);
  const taskFiles = activeTasks.reduce((result, task) => {
    const backendFilesThisLine = files.filter(
      ({ task_id, invoice_id }) =>
        invoice_id === invoiceId && task_id === task?.id
    );
    // const backendFilesThisLineWithGps = backendFilesThisLine.some(
    //   (f) => f.meta?.exif?.latitude && f?.meta?.exif?.longitude
    // );
    const recentPhotosThisLine = recentPhotos.filter(
      ({ taskId, invoiceId: taskInvoiceId, uploaded }) =>
        uploaded !== "added" && // in this case the backend owns it now
        taskInvoiceId === invoiceId &&
        taskId === task?.id
    );
    // const recentPhotoWithGps = recentPhotosThisLine.some(hasLocation);
    const ftl = backendFilesThisLine.map((f: PersistentProjectFile) => {
      const { latitude, longitude } = f.meta?.exif || {};
      const gpsInfo =
        (latitude && longitude && { latitude, longitude }) || undefined;
      return {
        ...f,
        gpsInfo,
      };
    });

    const filesThisLine: PossibleInspectionFile[] = [
      ...ftl,
      ...recentPhotosThisLine,
    ];

    const inspectionPhotosThisLine = filesThisLine.filter(
      (f) => isTempFile(f) || (StoredFile.is(f) && f.tags?.includes("di-photo"))
    );

    const qualified = inspectionPhotosThisLine.filter((f) => {
      const photoLocation = effectivePhotoLocation(f);
      return (
        projectLocationIsValid &&
        photoLocation &&
        isNear(photoLocation, projectLocation)
      );
    });
    const unqualified = filesThisLine.filter(not((f) => qualified.includes(f)));

    return result.set(task.id, {
      qualified,
      unqualified,
      filesThisLine,
    });
  }, new Map<Task["id"], { qualified: PossibleInspectionFile[]; unqualified: PossibleInspectionFile[]; filesThisLine: PossibleInspectionFile[] }>());

  const hasAtLeastOneQualifiedPhoto = ({ id }: Task): boolean =>
    Boolean(taskFiles.get(id)?.qualified.length);
  // const approvedDigitalInspectionTasks = tasks.filter(
  //   hasAtLeastOneQualifiedPhoto
  // );
  const isDigitalInspectionQualified = hasAtLeastOneQualifiedPhoto;
  const notApprovedDigitalInspectionTasks = activeTasks.filter(
    not(isDigitalInspectionQualified)
  );

  const readyForDigitalInspectionApproval =
    notApprovedDigitalInspectionTasks.length === 0;

  type ApprovalChecks = Record<
    "sufficientRequiredFiles" | "digitalInspectionReady",
    boolean | "overridden"
  >;

  const [approvalChecks, setApprovalChecks] = React.useState<ApprovalChecks>({
    digitalInspectionReady: false,
    sufficientRequiredFiles: false,
  });
  React.useEffect(() => {
    setApprovalChecks((checks) => ({
      ...checks,
      sufficientRequiredFiles: numDrawFiles >= requiredDrawFiles,
    }));
  }, [numDrawFiles, requiredDrawFiles]);
  React.useEffect(() => {
    setApprovalChecks((checks) => ({
      ...checks,
      digitalInspectionReady:
        readyForDigitalInspectionApproval || !digitalInspectionActive,
    }));
  }, [digitalInspectionActive, readyForDigitalInspectionApproval]);

  const tryApproveInvoice = (override?: Partial<ApprovalChecks>): void => {
    if (
      !approvalChecks.digitalInspectionReady &&
      !override?.digitalInspectionReady
    ) {
      setShowOverrideDigitalInspectionPhotosDialog(true);
    } else if (
      !approvalChecks.sufficientRequiredFiles &&
      !override?.sufficientRequiredFiles
    ) {
      setShowOverrideLackingFilesDialog(true);
    } else {
      handleApproval(invoice, InvoiceActions.APPROVE);
    }
  };

  const ApproveButton = ({ disabled }: { disabled?: boolean }) => (
    <Button
      disabled={disabled ?? approveDisabledForFileCount}
      color="primary"
      onClick={() => tryApproveInvoice()}
    >
      {fullConciergeInspectionPending && actingAsConcierge
        ? "Report Received"
        : inspectionRequested
        ? paymentRequired && !stripePaymentUrl
          ? "Mark as Paid"
          : inspectionPending
          ? "Early Approve"
          : "Dispatched Inspector"
        : !insufficientRequiredFiles || canDoLenderAdminThings
        ? invoice.remaining_approvers.length > 0 && !(role === Role.SuperAdmin)
          ? digitalInspectionActive
            ? "Finish Inspection"
            : "Submit"
          : "Approve"
        : digitalInspectionActive && !readyForDigitalInspectionApproval
        ? "Take More Photos"
        : "More Files Needed"}
    </Button>
  );

  const tooFewFilesDesc = `Policy requires at least ${requiredDrawFiles} files, but this draw ${
    numDrawFiles ? `only has ${numDrawFiles}` : "has none"
  }`;

  const handleInspectionPhoto =
    (taskId: Task["id"]) =>
    async ({
      newPhotos,
      currentPosition,
    }: {
      newPhotos: FileList;
      currentPosition: GeolocationPosition | GeolocationPositionError;
    }) => {
      if (!newPhotos) {
        const error = new Error(
          "Inspection newPhotos object should not be null"
        );
        logError(error);
        enqueueSnackbar(
          "Sorry, but an unexpected error has occurred. We'll look into it.",
          { variant: "error" }
        );
        throw error;
      }

      const deviceLocation =
        currentPosition instanceof GeolocationPosition
          ? currentPosition
          : undefined;
      const newPhotosArray = Array.from(newPhotos);
      const withLocations = await Promise.all(
        newPhotosArray.map((file) =>
          gps(file).then((gpsInfo) => ({ file, gpsInfo, deviceLocation }))
        )
      );
      if (
        withLocations.some(
          ({ deviceLocation, gpsInfo }) => !deviceLocation && !gpsInfo
        )
      ) {
        enqueueSnackbar(
          "Location information is required for Digital Inspections. Please enable location, refresh, and try again",
          { variant: "warning", persist: true }
        );
        return; // do not save photos
      }

      const suspiciousLocations = withLocations.filter(
        ({ deviceLocation, gpsInfo }) =>
          deviceLocation &&
          gpsInfo &&
          (distance(deviceLocation?.coords, gpsInfo) ?? 0) > 50
      );
      if (suspiciousLocations.length) {
        logAnomaly(new Error("Suspicious DI Photos"), { suspiciousLocations });
      }

      // const errorStoringFile = (err: unknown) => {
      //   logError(new Error("Error adding temp inspection file"), { err });
      //   return Promise.reject(err);
      // };

      const makeNewPendingFile = makeNewPendingFileForTask(taskId);
      // have to clone these ourselves so IDB doesn't blow up
      const clonedDeviceLocation = deviceLocation?.coords && {
        deviceLocation: {
          timestamp: deviceLocation.timestamp,
          coords: {
            latitude: deviceLocation.coords.latitude,
            longitude: deviceLocation.coords.longitude,
          },
        },
      };
      const iosGpsInfo = isIOS &&
        clonedDeviceLocation?.deviceLocation?.coords && {
          gpsInfo: clonedDeviceLocation.deviceLocation.coords,
        };
      const photosWithGps = withLocations.map(
        ({ file, gpsInfo, deviceLocation }) => ({
          ...makeNewPendingFile(file),
          ...clonedDeviceLocation,
          gpsInfo,
          ...iosGpsInfo,
        })
      );

      photosWithGps.forEach((photo) => {
        const location = effectivePhotoLocation(photo);
        analytics.track("di:photo:new", {
          location,
          name: photo.file.name,
          invoiceId: photo.invoiceId,
          distanceFromProject:
            projectLocationIsValid && distance(projectLocation, location),
        });
      });

      const tx = db.db?.transaction("tempFiles", "readwrite");
      const store = tx?.objectStore("tempFiles");
      if (!store) {
        throw new Error("no store for temp files");
      }

      const photoRecords = await Promise.all(
        photosWithGps.map((entry) =>
          store
            .add({ ...entry, uploaded: "uploading" })
            .then((key) => ({ ...entry, key }))
            .catch((err) => {
              logAnomaly(new Error("Error adding DI photo to store"), { err });
              throw err;
            })
        )
      );
      await tx?.done;

      setRecentPhotos((photos) => photos.concat(photoRecords));

      setLoading("files");

      try {
        const uploads = await Promise.all(
          photoRecords.map((entry) =>
            uploadSupportingFile({
              file: entry.file,
              prefix: `${projectId}/t/${taskId}/i/${invoiceId}`,
              progress: setProgress,
            }).then((storedFile) =>
              validateUploadedFile(entry.file, storedFile).then(() => {
                return db.db
                  ?.put("tempFiles", {
                    ...entry,
                    uploaded: "done",
                    destination: storedFile,
                  })
                  .then(() =>
                    addFiles([
                      {
                        email: throwIfNot(
                          currentUserEmail,
                          "email is required"
                        ),
                        storedFile,
                        invoiceId: invoiceId,
                        tags: ["di-photo"],
                        taskId,
                        deviceLocation: entry.deviceLocation,
                        ...(isIOS &&
                          entry.gpsInfo && { gpsInfo: entry.gpsInfo }),
                      },
                    ])
                  )
                  .then(() => {
                    return db.db?.put("tempFiles", {
                      ...entry,
                      uploaded: "added",
                      destination: storedFile,
                    });
                  })
                  .then(() => {
                    // now that it's uploaded and added, forget the temp file version
                    setRecentPhotos((photos) =>
                      photos.filter((photo) => photo.key !== entry.key)
                    );
                  });
                // .then(() => {
                //   setRecentPhotos((photos) =>
                //     photos.map((p) =>
                //       p.destination?.storageKey === storedFile.storageKey
                //         ? { ...p, uploaded: "added", destination: storedFile }
                //         : p
                //     )
                //   );
                // });
              })
            )
          )
        );

        return uploads;
      } finally {
        setLoading(false);
      }
    };

  const goodLocationDataRef = React.useRef<HTMLElement>();
  const indeterminateLocationDataRef = React.useRef<HTMLElement>();
  const badLocationDataRef = React.useRef<HTMLElement>();
  const closeLightbox = React.useCallback(() => setLightboxOpen(false), []);
  const onLightboxDelete = React.useCallback(
    ({
      // file,
      index,
    }: {
      index: number;
      // file: TempFile | StoredFile | LinkedFile;
    }): Promise<void> =>
      new Promise((resolve, reject) => {
        setFileToDelete({
          file: lightboxFiles[index],
          onCancel: reject,
          onDeleted: () => {
            setLightboxFiles((lbf) => {
              const copyToSplice = lbf.slice();
              copyToSplice.splice(index, 1);
              return copyToSplice;
            });
          },
        });
      }),
    [lightboxFiles]
  );

  const displayDraws = useDisplayDraws({ files, invoice, tasks });

  return (
    <div key={key}>
      <div style={{ display: "none" }}>
        <Tooltip title={"Good Location info"} ref={goodLocationDataRef}>
          <SvgIcon component={LocationOn} color="cta" />
        </Tooltip>
        <Box ref={indeterminateLocationDataRef}>
          <Tooltip title={"Indeterminate Location info"}>
            <SvgIcon component={LocationOff} />
          </Tooltip>
        </Box>

        <Tooltip title={"Bad Location info"} ref={badLocationDataRef}>
          <SvgIcon component={LocationOn} style={{ color: "red" }} />
        </Tooltip>
      </div>
      <Card id={invoiceId} sx={commonStyles.root}>
        <CardContent>
          <InvoiceContent>
            <InvoiceHeader>
              <div>
                {canDeleteDrawRequests ? (
                  <ClearIcon
                    sx={({ spacing }) => ({
                      cursor: "pointer",
                      paddingRight: spacing(1),
                    })}
                    color="secondary"
                    onClick={() => onDeleteRequested(invoice)}
                  />
                ) : undefined}
                <MarkPaidCheckbox invoice={invoice} />
              </div>

              <DrawReportButtons>
                {canRequestConciergeInspection &&
                !invoice.is_put &&
                !inspectionActive ? (
                  <div onClick={() => setInspectionDialogOpen(true)}>
                    <Tooltip title={"Request Inspection"}>
                      {/* <IconButton aria-label="project-centerline-inspection"> */}
                      <SvgIcon
                        sx={commonStyles.headerIcons}
                        component={conciergeInspectionIcon}
                        viewBox="0 0 375 375"
                      />
                      {/* </IconButton> */}
                    </Tooltip>
                  </div>
                ) : undefined}
                <DrawReportButton preview invoiceId={invoiceId} />
                <DrawReportButton invoiceId={invoiceId} />
                <CSVExport
                  data={displayDraws.map(
                    ({ drawRequestAmount, task, ...rest }) => ({
                      drawRequestAmount: invoice.is_put
                        ? -drawRequestAmount
                        : drawRequestAmount,
                      ...rest,
                      task: task.title,
                    })
                  )}
                  headers={csvHeaders(invoice.is_put)}
                  filename={sanitizeFilename(
                    `${filenameHint} ${
                      invoice.is_put ? "- Put " : ""
                    }- ${invoiceDate.toISOString().split("T").shift()}.csv`
                  )}
                />
              </DrawReportButtons>
            </InvoiceHeader>

            <h3>{invoiceDescription(invoice, key, invoiceDate)}</h3>
          </InvoiceContent>
          <Divider />
          <DrawLines
            displayInfo={{ invoice, displayDraws }}
            tasks={tasks}
            formatMoney={formatMoney}
            maxDrawLines={3}
            onTitleClicked={(draw) => handleEditLineItemRequested({ draw })}
            renderFileAffordance={
              canSeePerLineItemAttachments
                ? (task) => {
                    const diQualified = isDigitalInspectionQualified(task);
                    const badgeColor = digitalInspectionsFeature
                      ? diQualified
                        ? "cta"
                        : "muted"
                      : "primary";

                    const { filesThisLine } = taskFiles.get(task.id) || {};
                    return filesThisLine && filesThisLine.length > 0 ? (
                      <MaybeTooltip
                        title={
                          digitalInspectionActive
                            ? projectLocationIsValid
                              ? !diQualified
                                ? "Photos do not meet Digital Inspection criteria"
                                : undefined
                              : "Project does not have valid location info. Please update project address."
                            : undefined
                        }
                      >
                        <IconButton
                          onClick={() => handleShowFiles(filesThisLine)}
                          size="large"
                        >
                          <Badge
                            badgeContent={filesThisLine.length}
                            color={badgeColor}
                          >
                            <AttachedFilesIcon />
                          </Badge>
                        </IconButton>
                      </MaybeTooltip>
                    ) : undefined;
                  }
                : undefined
            }
            leftSideWidget={
              digitalInspectionActive
                ? (draw, key) => {
                    const task = draw.task;
                    return task && draw.drawRequestAmount > 0 ? (
                      <DIPhotoButton
                        key={key}
                        disabled={!canAddDigitalInspectionPhotos}
                        onNewPhotos={handleInspectionPhoto(task.id)}
                      />
                    ) : undefined;
                  }
                : undefined
            }
          />
          <InvoiceTotal displayInfo={{ invoice, displayDraws }} />
          <p
            style={{
              width: "100%",
            }}
          >
            {inspectionPending ? (
              <>
                {inspectionPendingStatusDescription}:{" "}
                {inspectionDetails?.vendor ??
                  (
                    availableInspectors.find(
                      ({ email }) => email === delegate
                    ) ||
                    availableOneClicks?.find(
                      ({ prefix, suffix }) =>
                        delegate?.startsWith(prefix ?? "") &&
                        delegate?.endsWith(suffix)
                    )
                  )?.vendor}
                {inspectionDetails?.vendorLink &&
                canSeeVendorInspectionLinks ? (
                  <>
                    {" ("}
                    <a
                      href={inspectionDetails?.vendorLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      view order
                    </a>
                    {")"}
                  </>
                ) : undefined}
                {receiptUrl ? (
                  <>
                    {" ("}
                    <a href={receiptUrl} target="_blank" rel="noreferrer">
                      view payment
                    </a>
                    {")"}
                  </>
                ) : undefined}
              </>
            ) : inspectionRequested ? (
              `Inspection Requested: ${
                availableInspectors.find(
                  ({ email }) => email === currentApprover
                )?.vendor
              }`
            ) : digitalInspectionActive ? (
              "Digital Inspection Active"
            ) : (
              `Current Approver: ${currentApprover}`
            )}
          </p>
          {paymentRequired ? (
            <p>
              <strong>Pending Payment: {formatMoney(amountDue)}</strong>
              {stripePaymentUrl ? (
                <>
                  {" ("}
                  <a href={stripePaymentUrl} target="_blank" rel="noreferrer">
                    Pay Now
                  </a>
                  {")"}
                </>
              ) : undefined}
            </p>
          ) : undefined}
          <DisplayFiles files={invoice.storedFiles} onClick={handleShowFiles} />
          {addDoc ? (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <DropzoneArea
                  maxFileSize={maxFileUploadSize}
                  filesLimit={maxFileUploadCount}
                  onChange={onDropzoneFiles}
                  alertSnackbarProps={{ autoHideDuration: 3000 }}
                  useChipsForPreview
                  getFileAddedMessage={(fileName) =>
                    `File ${fileName} queued for upload.`
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  onClick={(e) =>
                    filesToAdd.length > 0
                      ? handleUploadDocument()
                      : setAddDoc(false)
                  }
                >
                  {filesToAdd.length > 0 ? "Upload" : "Cancel"}
                </Button>
              </div>
            </div>
          ) : digitalInspectionActive ? null : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button variant="contained" onClick={() => setAddDoc(true)}>
                Add Files
              </Button>
            </div>
          )}
          {addComment ? (
            <CommentsSection
              invoiceId={invoiceId}
              addComment={addComment}
              removeComment={canRemoveInvoiceComment(invoice) && removeComment}
              comments={comments}
            />
          ) : null}
        </CardContent>
        {canApproveDrawRequests(invoice) ||
        (role === Role.Owner && digitalInspectionActive) ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            {canApproveDrawRequests(invoice) ? (
              <Button
                color="secondary"
                onClick={(e) => handleApproval(invoice, InvoiceActions.REJECT)}
              >
                Reject
              </Button>
            ) : null}
            {canModifyDrawRequests({ restrictInspectors }) ? (
              <Button
                color="secondary"
                // onClick={(e) =>
                //   history.push(
                //     `/projectview/${projectId}/${ProjectViewTabs.Tasks}?editInvoice=${invoiceId}`
                //   )
                // }
                onClick={() => setEditDialogOpen(true)}
              >
                Edit
              </Button>
            ) : null}

            {digitalInspectionActive && !readyForDigitalInspectionApproval ? (
              <Tooltip
                title={`Some tasks still need good inspection files to enable Approval`}
              >
                <div>
                  <ApproveButton disabled={role === Role.Owner} />
                </div>
              </Tooltip>
            ) : approveDisabledForFileCount ? (
              <Tooltip
                title={`${tooFewFilesDesc}. Add more files to enable Approval`}
              >
                <div>
                  <ApproveButton />
                </div>
              </Tooltip>
            ) : (
              <ApproveButton />
            )}
          </div>
        ) : null}
        {canEditIndividualInvoiceRouting ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              color="secondary"
              onClick={() => {
                analytics.track("button:edit-routing:pending-draw", {
                  projectId,
                  invoiceId: invoiceId,
                });
                setRoutingEditorState("open");
              }}
            >
              Edit Routing
            </Button>
            <PeopleRoutingEditor
              open={routingEditorState !== "closed"}
              onClose={() => setRoutingEditorState("closed")}
              addablePeople={projectUsers || []}
              busy={routingEditorState === "busy"}
              onSubmit={(newRouting) => {
                if (
                  !validateInvoiceRouting(
                    newRouting,
                    projectUsers,
                    enqueueSnackbar
                  )
                ) {
                  return;
                }
                setRoutingEditorState("busy");
                invoiceFns
                  ?.updateRouting(newRouting)
                  .then(() => setRoutingEditorState("closed"))
                  .catch((error) => {
                    const err = error as APIError;
                    const message = `Error updating routing. ${
                      (err.response?.status === 412 &&
                        err.response?.data?.reason) ||
                      "Please try again later"
                    }`;
                    enqueueSnackbar(message, {
                      variant: "error",
                    });
                    logError(error);
                    setRoutingEditorState("open");
                  });
              }}
              initialPeople={
                [currentApprover]
                  .concat(invoice.remaining_approvers || [])
                  .map((email) => projectUsers.find((u) => u.email === email))
                  .filter(Boolean) as typeof projectUsers
              }
              title="Current Approver(s)"
            />
          </div>
        ) : null}
      </Card>
      {inspectionDialogOpen ? (
        <InspectionDialog
          open={inspectionDialogOpen}
          onClose={() => setInspectionDialogOpen(false)}
          projectId={projectId}
          invoice={invoice}
          onInspectionRequested={() => onSomethingChanged?.()}
        />
      ) : null}
      {lightboxOpen ? (
        <ImageGallery
          files={lightboxFiles}
          open={lightboxOpen}
          onClose={closeLightbox}
          onDelete={onLightboxDelete}
          downloadAllFilename={`${filenameHint} - ${invoice.identifier} - files.zip`}
        />
      ) : null}
      {editDialogOpen ? (
        <EditValues
          displayInfo={{ invoice }}
          tasks={tasks}
          onClose={() => setEditDialogOpen(false)}
          onTaskViewRequested={onTaskViewRequested}
          files={files}
          onDeleteFile={(index: number) =>
            new Promise((resolve, reject) => {
              setFileToDelete({
                file: files[index],
                onCancel: reject,
                onDeleted: () => resolve(),
              });
            })
          }
        />
      ) : null}
      {fileToDelete ? (
        <ConfirmDialog
          onConfirm={() => {
            const victim = fileToDelete.file;
            const deletingFeedback = enqueueSnackbar(
              `Deleting ${extractTitle(victim) || "File"}...`,
              {
                variant: "info",
              }
            );
            const promiseToDelete =
              (isTempFile(victim) &&
                (db.db?.delete("tempFiles", victim.key) ??
                  Promise.reject(new Error("Landed in an unexpected spot")))) ||
              (FileWithId.is(victim) && onDeleteLineItemFile(victim)) ||
              onDeleteInvoiceFile(invoice, victim as StoredFile | LinkedFile);
            promiseToDelete
              .then(() => {
                enqueueSnackbar(`${extractTitle(victim) || "File"} deleted`, {
                  variant: "success",
                });
                fileToDelete.onDeleted();
              })
              .finally(() => {
                closeSnackbar(deletingFeedback);
              });
          }}
          open={Boolean(fileToDelete)}
          title="Delete File?"
          confirmActionWords="Delete"
          onCancel={() => {
            fileToDelete?.onCancel();
            setFileToDelete(undefined);
          }}
          setOpen={(wantsToBeOpen) => {
            if (!wantsToBeOpen) {
              setFileToDelete(undefined);
            }
          }}
        >
          <Typography>
            Are you sure you want to delete{" "}
            {extractTitle(fileToDelete?.file) || "this file"}? There is no Undo
          </Typography>
        </ConfirmDialog>
      ) : null}
      <ConfirmDialog
        onConfirm={() => {
          const override: Pick<ApprovalChecks, "sufficientRequiredFiles"> = {
            sufficientRequiredFiles: "overridden",
          };
          setApprovalChecks((checks) => ({
            ...checks,
            ...override,
          }));
          tryApproveInvoice(override);
        }}
        open={showOverrideLackingFilesDialog}
        title="Approve Without Required Files?"
        confirmActionWords="Approve Anyway"
        onCancel={() => {
          setShowOverrideLackingFilesDialog(false);
        }}
        setOpen={(wantsToBeOpen) => {
          if (!wantsToBeOpen) {
            setShowOverrideLackingFilesDialog(false);
          }
        }}
      >
        Are you sure you want to Approve this draw? {tooFewFilesDesc}
      </ConfirmDialog>
      <ConfirmDialog
        onConfirm={() => {
          const override: Pick<ApprovalChecks, "digitalInspectionReady"> = {
            digitalInspectionReady: "overridden",
          };
          setApprovalChecks((checks) => ({
            ...checks,
            ...override,
          }));
          tryApproveInvoice(override);
        }}
        open={showOverrideDigitalInspectionPhotosDialog}
        title="Approve Without Inspection Files?"
        confirmActionWords="Approve Anyway"
        onCancel={() => {
          setShowOverrideDigitalInspectionPhotosDialog(false);
        }}
        setOpen={(wantsToBeOpen) => {
          if (!wantsToBeOpen) {
            setShowOverrideDigitalInspectionPhotosDialog(false);
          }
        }}
      >
        Not all lines have approved Digital Inspection Photos. Are you sure you
        want to Approve this draw?
      </ConfirmDialog>
      <UploadProgressDialog progress={progress} open={loading === "files"} />
    </div>
  );
}

function extractTitle(victim: StoredFile | LinkedFile | TempFile) {
  return LinkedFile.is(victim)
    ? victim.title
    : StoredFile.is(victim)
    ? victim.path
    : victim.destination?.path || victim.file.name;
}
