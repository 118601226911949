import { throwIfNot } from "@/Lib/util/throwIfNot";
import { useParams } from "react-router-dom";
import TimelineList from "examples/Timeline/TimelineList";
import TimelineItem, {
  Props as TimelineItemProps,
} from "examples/Timeline/TimelineItem";
import {
  graphql,
  useFragment as getFragmentData,
  FragmentType,
} from "@/graphql-generated";
import { useQuery } from "urql";
import { CircularProgress, Typography } from "@mui/material";

export interface Activity {
  title: "Check In" | "Check Out" | "Cancelled";
  dateTime: string;
  description: string;
}
export const MakeActivity = (o: Activity) => o;

export const COLORS: Record<Activity["title"], TimelineItemProps["color"]> = {
  "Check In": "success", // green
  "Check Out": "dark", // dark blue
  Cancelled: "error", // red
} as const;

export const ICONS: Record<Activity["title"], TimelineItemProps["icon"]> = {
  "Check In": "done", //Checkmark
  "Check Out": "done", //Checkmark
  Cancelled: "cancel", // X with Circle
} as const;

const CheckinActivityFragment = graphql(/* GraphQL */ `
  fragment CheckinActivityFragment on CheckinRequest {
    id
    comment
    poc {
      email
      id
    }
    activity {
      edges {
        node {
          id
          comments {
            edges {
              node {
                comment
                id
                timestamp
              }
            }
          }
          timeIn
          timeOut
        }
      }
    }
  }
`);

// const CheckinRequestDetails = graphql(/* GraphQL */ `
//   fragment CheckinRequestDetails on CheckinRequest {
//     id
//     comment
//     poc {
//       email
//       id
//     }
//   }
// `);

export const CheckinsFragment = graphql(/* GraphQL */ `
  fragment CheckinsFragment on LegacyProject {
    checkins {
      edges {
        node {
          # __typename
          # ...CheckinRequestDetails
          ...CheckinActivityFragment
        }
      }
    }
  }
`);

export const listProjectCheckinsQuery = graphql(/* GraphQL */ `
  query listProjectCheckinsQuery($projectId: String!) {
    project(id: $projectId) {
      # pageInfo {
      #   hasNextPage
      #   endCursor
      # }

      # project_id
      # address
      # __typename
      ...CheckinsFragment
    }
  }
`);

export const CheckinRequest = ({
  checkin: request,
}: {
  checkin: // FragmentType<typeof CheckinRequestDetails> &
  FragmentType<typeof CheckinActivityFragment>;
}) => {
  const { comment, poc, activity } = getFragmentData(
    CheckinActivityFragment,
    request
  );
  // const { activity } = getFragmentData(CheckinActivityFragment, request);
  return (
    <TimelineList title={`${comment}, POC: ${poc.email}`}>
      <>
        {activity.edges.length ? (
          activity.edges
            .flatMap(({ node: { comments, timeIn, timeOut } }) =>
              [
                MakeActivity({
                  dateTime: timeIn,
                  title: "Check In",
                  description: comments.edges
                    .map(({ node: { comment } }) => comment)
                    .join("\n"),
                }),
              ].concat(
                timeOut
                  ? MakeActivity({
                      dateTime: timeOut,
                      title: "Check Out",
                      description:
                        "(need to revisit design so outs can have different comments)",
                    })
                  : []
              )
            )
            .map((item) => {
              return (
                <TimelineItem
                  color={COLORS[item.title]}
                  icon={ICONS[item.title]}
                  {...item}
                  // title={item.title}
                  // dateTime={item.dateTime}
                  // description={item.description}
                  // badges={["server", "payments"]} optional, not sure how to use exactly
                />
              );
            })
        ) : (
          <Typography variant="body2">(no activity)</Typography>
        )}
      </>
    </TimelineList>
  );
};

const ActivityPage = () => {
  const projectId = throwIfNot(
    useParams<"projectId">().projectId,
    "Missing project id"
  );

  const [{ data, fetching, error }] = useQuery({
    query: listProjectCheckinsQuery,
    variables: { projectId },
  });

  const { checkins } = getFragmentData(CheckinsFragment, data?.project) ?? {};

  return (
    <div style={{ width: "81vw", height: "50vh", flexDirection: "row" }}>
      {error ? (
        <div>
          <Typography variant="h3">Oh Snap!</Typography>
          <Typography>{JSON.stringify(error, undefined, 2)}</Typography>
        </div>
      ) : fetching ? (
        <CircularProgress />
      ) : checkins?.edges.length ? (
        checkins.edges.map(({ node }) => <CheckinRequest checkin={node} />)
      ) : (
        <Typography>(no activity)</Typography>
      )}
    </div>
  );
};

export default ActivityPage;
