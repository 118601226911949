import React from "react";
import { styled } from "@mui/system";
import Button, { ButtonProps } from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { DropzoneArea } from "react-mui-dropzone";
import { LoanType } from "@lib/LoanType";

import { amountRemaining, fractionRemaining } from "./DrawRequestRow";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
  useAnalytics,
  useInvoiceComments,
  useFeatures,
  useMoney,
  usePermissions,
  useBackend,
} from "@lib/hooks";

import { ErrorOrProgressOr } from "../../../Components/ErrorOrProgressOr";

import sanitizeFilename from "sanitize-filename";
import {
  Badge,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Hidden,
  MenuItem,
  Select,
  SvgIcon,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { TileContainer } from "../../../Components/TileContainer";
import { Tile } from "../../../Components/Tile";
import { CSVExport } from "../../../Components/CSVExport";
import { filenameDateFormat } from "@lib/util/filenameDateFormat";
import {
  bigAssIcon,
  invertedButtonStyle,
} from "../../../projectCenterlineTheme";
// import { DevTool } from "@hookform/devtools";
import { HtmlTooltip } from "../../../Components/HtmlTooltip";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as conciergeInspectionIcon } from "../../../Assets/Images/Inspection_Icon_PC_Blue.svg";
import { useProjectDecisions } from "@lib/hooks/useProjectDecisions";
import { useProjectInvoices } from "@lib/hooks/useProjectInvoices";
import { APIError } from "@lib/API";
import {
  DrawRequestValuesEditor,
  TaskCompleteRequest,
  TaskDeleteRequest,
  TaskEditRequest,
} from "./DrawRequestValuesEditor";
import { logError } from "@lib/ErrorLogging";
import { useSnackbar } from "notistack";
import { wholePercentageFormatter } from "@lib/wholePercentageFormatter";
import { LocalTaskFile } from "./LocalTaskFile";
import {
  UploadProgressDialog,
  useMultiFileProgress,
} from "../../../Components/UploadProgressDialog";
import { useProjectFiles } from "@lib/hooks/useProjectFiles";
import { extractFileTitle } from "./extractFileTitle";
import { useEntityFeatures } from "@lib/hooks/useEntityFeatures";
import { alignHorizontalCenters, HorizontallyAligned } from "@lib/StyleHelpers";
import { ConfirmDialog } from "../../../Components/ConfirmDialog";

import { Prompt } from "../../../Components/Prompt";
import { useProjectDetails } from "@lib/hooks/useProjectDetails";
import { byCreatedAt, useProjectTasks } from "@lib/hooks/useProjectTasks";

import { Task } from "../../../Models";
// import { byCreatedAt, useProjectTasks } from "@lib/hooks/useProjectTasks";

import {
  ProjectId,
  InvoiceId,
  TaskId,
  StoredFile,
} from "@project-centerline/project-centerline-api-types";
import { NonEmptyString } from "io-ts-types";
import { useAuth } from "@lib/hooks/useAuth";
import { throwIfNot } from "@lib/util/throwIfNot";
import { useGetUserRole } from "@/Lib/hooks/useGetUserRole";

type FormData = {
  putMode: boolean;
  amounts: number[];
  comment: string;
};
export interface CreateDrawRequestProps {
  onRefreshNeeded?: (reason?: "Invoice Created" | "Task Deleted") => void;
  loading?: boolean;
  project: {
    retention: number | null;
    draw_requests_remaining: number | null;
    project_id: ProjectId;
    address: string;
    loanType: LoanType;
  };
  onTaskCompleteRequested?: TaskCompleteRequest;
  onTaskEditRequested?: TaskEditRequest;
  onTaskViewRequested?: TaskEditRequest;
  onTaskDeleteRequested?: TaskDeleteRequest;
  files: LocalTaskFile[];
  onDeleteFile: (file: LocalTaskFile) => Promise<void>;
}

const HeaderRow = styled("div")(({ theme: { spacing, unstable_sx } }) => ({
  ...unstable_sx(alignHorizontalCenters),
  width: "100%",
  display: "flex",
  flex: 1,
  flexDirection: "row",
  justifyContent: "space-between",
  margin: spacing(1),
}));

const StyleApplyingDropzoneContainer = styled("div")`
  & .MuiDropzoneArea-text {
    margin-bottom: 0;
  }

  & .MuiDropzoneArea-root {
    height: 100%;
  }

  height: 100%;
`;

// grr HACK
const TopRightButtonsStyler = styled("div")({
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  alignItems: "start",
  justifyContent: "flex-end",
  "& *": {
    paddingTop: 0,
    paddingBottom: 0, // spacing(1),
  },
  // marginTop: -1 * spacing(3),
  marginBottom: 0,
});

const InspectionDialog = ({
  open,
  projectId,
  onClose,
}: {
  open: boolean;
  projectId: string;
  onClose: () => void;
}) => {
  const [busy, setBusy] = React.useState(false);
  const [comments, setComments] = React.useState("");
  const { project } = useProjectDecisions(projectId);
  const { requestPCInspection } = project;

  const onRequestInspection = () => {
    setBusy(true);

    requestPCInspection({ comments })
      .then(onClose)
      .catch((err) => alert(`Sorry, but something went wrong. ${err.message}`))
      .finally(() => {
        setBusy(false);
      });
  };

  return project ? (
    <Dialog
      onClose={onClose}
      aria-labelledby="request-inspection-dialog-title"
      open={open}
      maxWidth={"lg"}
    >
      <DialogTitle id="request-inspection-dialog-title">
        Project Centerline Create Draw
      </DialogTitle>
      <DialogContent>
        <Typography>
          We will dispatch an inspector and create a draw for you based on their
          report.
        </Typography>
        <TextField
          variant="standard"
          id="description"
          name="description"
          label="Comments"
          fullWidth
          multiline
          rows={4}
          onChange={({ target: { value } }) => setComments(value)}
          autoFocus
          placeholder={"Anything we should know?"}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button disabled={busy} color="primary" onClick={onRequestInspection}>
          {busy ? <CircularProgress /> : "Request Inspection"}
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
};

const total =
  <T,>(mapper: (arg: T) => number) =>
  (a: T[]) =>
    a.map(mapper).reduce((sum, amount) => sum + amount, 0);

const taskValue = ({ task_value }: Pick<Task, "task_value">) => task_value;
const invoicedAmount = ({ invoiced_amount }: Pick<Task, "invoiced_amount">) =>
  invoiced_amount;
const remaining = ({
  task_value,
  invoiced_amount,
  requested_amount,
}: Pick<Task, "task_value" | "invoiced_amount" | "requested_amount">) =>
  task_value - invoiced_amount - requested_amount;

function LineOfCreditTiles({
  tasks,
  loanAmount,
  amountRequested,
}: {
  tasks: Task[];
  loanAmount: number;
  amountRequested: number;
}) {
  const incompleteTasks = tasks.filter(({ completed }) => !completed);

  const { formatMoney } = useMoney();

  return (
    <TileContainer spacing={1}>
      <Hidden smDown>
        <Tile>
          <Typography variant="tile">
            {formatMoney(loanAmount, {
              // wholeUnits: true,
            })}
            <br />
            LOC
          </Typography>
        </Tile>
      </Hidden>
      <Hidden smDown>
        <Tile>
          <Typography variant="tile">
            {formatMoney(total(taskValue)(incompleteTasks), {
              // wholeUnits: true,
            })}
            <br />
            Holdback
          </Typography>
        </Tile>
      </Hidden>
      <Tile>
        <Typography variant="tile">
          {formatMoney(total(remaining)(tasks), {
            // wholeUnits: true,
          })}
          <br />
          Remaining
        </Typography>
      </Tile>
      <Hidden smDown>
        <Tile>
          <Typography variant="tile">
            {formatMoney(total(invoicedAmount)(tasks), {
              // wholeUnits: true,
            })}
            <br />
            Spent
          </Typography>
        </Tile>
      </Hidden>
    </TileContainer>
  );
}

function ConventionalLoanTiles({
  tasks,
  amountRequested,
}: {
  tasks: Task[];
  amountRequested: number;
}) {
  const { formatMoney } = useMoney();

  const totalValue = total(taskValue)(tasks);
  const totalInvoiced = total(invoicedAmount)(tasks);
  return (
    <TileContainer spacing={1}>
      <Hidden smDown>
        <Tile>
          <Typography variant="tile">
            {tasks.length}
            <br />
            Line Items
          </Typography>
        </Tile>
      </Hidden>
      <Hidden smDown>
        <Tile>
          <Typography variant="tile">
            {formatMoney(totalValue, {
              // wholeUnits: true,
            })}
            <br />
            Holdback
          </Typography>
        </Tile>
      </Hidden>
      <Tile>
        <Typography
          variant="tile"
          sx={({ palette }) => ({
            color: palette.cta.main,
          })}
        >
          {formatMoney(amountRequested, {
            // wholeUnits: true,
          })}
          <br />
          Requested
        </Typography>
      </Tile>
      <Tile>
        <Typography variant="tile">
          {formatMoney(total(remaining)(tasks), {
            // wholeUnits: true,
          })}
          <br />
          Remaining
        </Typography>
      </Tile>
      <Hidden smDown>
        <Tile>
          <Typography variant="tile">
            {totalValue
              ? wholePercentageFormatter.format(totalInvoiced / totalValue)
              : "--"}
            <br />
            Spent
          </Typography>
        </Tile>
      </Hidden>
    </TileContainer>
  );
}

function CreateDrawRequest(props: CreateDrawRequestProps): JSX.Element {
  const { uploadSupportingFiles } = useBackend();
  const {
    onTaskCompleteRequested,
    onTaskEditRequested,
    onTaskViewRequested,
    onTaskDeleteRequested,
    project: {
      project_id: projectId,
      address: projectAddress,
      loanType,
      retention,
    },
    files,
    onDeleteFile,
  } = props;
  const { maxFileUploadSize, maxFileUploadCount, conciergeCreatedDraws } =
    useFeatures();
  const {
    restrictInspectors,
    frontend: { onDemandInspections },
  } = useEntityFeatures();

  const { role } = useGetUserRole().user || {};
  const showOnDemandInspection =
    (role && onDemandInspections.has(role)) || conciergeCreatedDraws;

  const {
    project: { tasks: { tasks = [] } = {} },
  } = useProjectTasks(projectId, { sortBy: byCreatedAt });

  React.useEffect(() => {
    console.log("cdr files changed", { files });
  }, [files]);
  const analytics = useAnalytics();
  // one-off on page load
  React.useEffect(function onMount() {
    analytics.track("page:draws");
    // we intentionally want to run this just on mount, so squelch the warning:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { formatMoney } = useMoney();
  const [invoiceWideFiles, setInvoiceWideFiles] = React.useState<
    (File | string)[]
  >([]);
  const email = throwIfNot(useAuth().currentUserEmail, "email is required");

  const [loading, setLoading] = React.useState<"files" | "other stuff" | false>(
    false
  );
  // don't remember why I had to do this filtering
  const invoiceFiles = React.useMemo(
    () => invoiceWideFiles.filter((f) => f instanceof File) as File[],
    [invoiceWideFiles]
  );
  const allFiles = React.useMemo(
    () => invoiceFiles.concat(files.map((f) => f.file)),
    [files, invoiceFiles]
  );
  const { summaryProgress, updateFileProgress } =
    useMultiFileProgress(allFiles);

  const [submittedForApproval, setSubmittedForApproval] = React.useState(false);
  const {
    project: { decisions },
  } = useProjectDecisions(projectId);
  const {
    project: { addInvoice, invoice: invoiceApis },
  } = useProjectInvoices(projectId);
  const {
    project: { addFiles /* , files: projectFiles */ },
  } = useProjectFiles({ projectId });
  // const existingFiles = []; // I don't think John knows what he's asking not to have projectFiles?.userFiles?.filter((f) => !f.invoice_id);

  const loanAmount = useProjectDetails(projectId).project?.details?.loanAmount;

  const numConciergeInspections = (
    decisions?.filter(
      ({ inspector, approval_status }) =>
        approval_status === "Pending" && Boolean(inspector)
    ) ?? []
  ).length;

  const handleAdditional = () => {
    setSubmittedForApproval(false);
    props.onRefreshNeeded?.();
  };

  const { enqueueSnackbar } = useSnackbar();
  function onDropzoneFiles(newFiles: File[]) {
    const filesAdded = newFiles.filter(
      (file) => !invoiceWideFiles.includes(file)
    );
    if (filesAdded.some((file) => /\.heic$/i.test(file.name))) {
      analytics.track("draw:heic alert");
      enqueueSnackbar(
        // spell-checker:ignore heic
        "HEIC files will not appear in draw reports due to patent/licensing issues. We're working on it",
        { variant: "warning" }
      );
    }
    setInvoiceWideFiles(newFiles);
  }

  const emptyFormData: () => FormData = () => ({
    putMode: false,
    amounts: tasks.map(() => 0),
    comment: "",
  });

  const formMethods = useForm({
    defaultValues: emptyFormData(),
    shouldUnregister: false,
  });
  const { control, handleSubmit, reset, watch } = formMethods;
  const { search } = useLocation();

  const invoiceIdToEdit = new URLSearchParams(search).get("editInvoice");
  React.useEffect(() => {
    const invoiceData =
      invoiceApis &&
      InvoiceId.is(invoiceIdToEdit) &&
      invoiceApis(invoiceIdToEdit)?.details;
    if (invoiceData) {
      const newDefaultFormData = {
        putMode: Boolean(invoiceData.is_put),
        amounts: tasks.map(
          ({ id }) =>
            invoiceData.drawItems.find((item) => item.task?.id === id)
              ?.amount || 0
        ),
      };
      // setDefaultValues(newDefaultFormData);
      // console.log({ newDefaultFormData, amountsByTitle });
      reset(newDefaultFormData);
      // setFiles(["can", "I", "set", "files?"]);
    }
  }, [invoiceIdToEdit, invoiceApis, reset, tasks]);

  const { canCreateDrawRequests, canDoLenderAdminThings } = usePermissions();
  const useRequestedAmountColumn = canCreateDrawRequests({
    restrictInspectors,
  });
  const watchedFormData = watch();
  const { putMode, amounts } = watchedFormData;
  const { addCommentToInvoice } = useInvoiceComments({});

  const totalAmount = amounts.reduce(
    (sum, amountRequested) => sum + (amountRequested ?? 0),
    0
  );

  const handleSubmitDraw =
    (whichButton: "top" | "bottom") => (formValues: FormData, err: unknown) => {
      const { amounts, putMode } = formValues;
      const drawItems = amounts
        .map((amount, i) => ({
          amount,
          task: tasks[i],
          title: tasks[i].title,
        }))
        .filter(({ amount }) => amount);

      if (putMode) {
        // opi = Over Put Index :-)
        const opi = amounts.findIndex(
          (amount, i) => Math.abs(amount) > tasks[i].invoiced_amount
        );
        if (opi >= 0) {
          enqueueSnackbar(
            `$${amounts[opi]} is greater than $${tasks[opi].invoiced_amount} invoiced for ${tasks[opi].title}`,
            { variant: "warning" }
          );
          return;
        }
        // console.log({ projectId, email, draws, putMode });
        // setComplaint("Puts are not actually implemented on the backend yet");
        // return;
      }

      setLoading("files");

      let complaint = "";
      uploadSupportingFiles({
        files: invoiceFiles,
        prefix: projectId,
        progress: updateFileProgress,
      })
        .catch((err) => {
          complaint = "Error uploading files";
          throw err;
        })
        .then((storedFiles: StoredFile[]) =>
          addInvoice(projectId, email, drawItems, putMode, storedFiles, tasks)
        )
        .then((invoice) => {
          // dice up line item files by task to match our other pattern
          const filesByTaskId = files.reduce(
            (acc, curr) => ({
              ...acc,
              [curr.id]: (acc[curr.id] || []).concat(curr.file),
            }),
            {} as Record<TaskId, File[]>
          );

          return Promise.all(
            Object.entries(filesByTaskId).map(([taskIdString, files]) =>
              uploadSupportingFiles(
                {
                  files,
                  prefix: `${projectId}/t/${taskIdString}/i/${invoice.invoice_id}`,
                  progress: updateFileProgress,
                }
                // setMostRecentProgressFile(file.name);
              ).then((uploadedFiles) =>
                addFiles(
                  uploadedFiles.map((storedFile) => ({
                    storedFile,
                    email,
                    invoiceId: invoice.invoice_id,
                    taskId: taskIdString as TaskId,
                  }))
                )
              )
            )
          )
            .catch((err) => {
              complaint = "Error uploading files";
              throw err;
            })
            .then(() => {
              setLoading("other stuff");
            })
            .then(() =>
              formValues.comment
                ? addCommentToInvoice(invoice.invoice_id, formValues.comment)
                : Promise.resolve()
            )
            .then(() => invoice);
        })
        .then((invoice) => {
          setLoading("other stuff");
          analytics.track("draw:submit", {
            // this may or may not be what we want but it's an example of a rich event
            putMode,
            projectId,
            invoiceId: invoice.invoice_id, // https://projectcenterline.slack.com/archives/G01D11FMKB8/p1648669817605999
            numFiles: invoiceWideFiles.length,
            numLineItemFiles: files.length,
            totalNumFiles: invoiceWideFiles.length + files.length,
            amount: Object.values(drawItems).reduce(
              (sum, { amount }) => sum + amount,
              0
            ),
            whichButton,
          });

          setSubmittedForApproval(true);
          props.onRefreshNeeded?.("Invoice Created");
          reset(emptyFormData());
        })
        .catch((error) => {
          analytics.track("draw:submit:error", {
            putMode,
            projectId,
            whichButton,
          });
          logError(error);

          const err = error as APIError;
          complaint =
            complaint ||
            `Could not create draw. ${
              (err.response?.status === 412 && err.response?.data?.reason) ||
              "Please try again later"
            }`;
          enqueueSnackbar(complaint, {
            variant: "error",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    };

  const taskToCSVStaticValues = (task: Task) => [
    task.title,
    task.task_value || 0,
    task.invoiced_amount || 0,
    amountRemaining(task, false) || 0,
    fractionRemaining(task) * 100,
  ];
  const staticCsvExportData = React.useMemo(
    () => tasks.map(taskToCSVStaticValues),
    [tasks]
  );
  const nRows = staticCsvExportData.length;
  const csvHeaders = [
    "Task Name",
    "Task Value",
    "Approved Draws / Puts",
    "Amount Remaining",
    "Percentage Spent",
    "Amount Requested",
  ].slice(0, useRequestedAmountColumn ? undefined : -1);
  const csvExportData = React.useMemo(
    () =>
      staticCsvExportData
        .map((staticData, index) =>
          useRequestedAmountColumn
            ? staticData.concat(amounts[index] || 0)
            : staticData
        )
        .concat([
          [],
          [
            "TOTAL",
            `=SUM(B2:B${nRows + 1})`, // value
            `=SUM(C2:C${nRows + 1})`, // approved invoices
            `=SUM(D2:D${nRows + 1})`, // remaining
            `=100*(B${nRows + 3} - D${nRows + 3})/B${nRows + 3}`, // % complete
            `=SUM(F2:F${nRows + 1})`, // (maybe) amount requested
          ].slice(
            0,
            useRequestedAmountColumn ? undefined : -1
          ) as NonEmptyString[],
        ]),
    [staticCsvExportData, nRows, useRequestedAmountColumn, amounts]
  );

  // TODO: reset or cap values when mode switch
  // useEffect(() => {
  //   tasks.forEach((_, i) => { setValue(`amounts[${i}]`, 0)});
  // }, [putMode, setValue, tasks]);

  const [inspectionDialogOpen, setInspectionDialogOpen] = React.useState(false);

  const {
    frontend: {
      drawDocsRequired: { creation: requiredDrawFiles },
    },
  } = useEntityFeatures();
  const numDrawFiles = invoiceWideFiles.length + files.length;
  const moreDrawFilesNeeded = Math.max(0, requiredDrawFiles - numDrawFiles);
  const [showingLackingFilesConfirmDialog, showLackingFilesConfirmDialog] =
    React.useState(false);

  const createDisabled =
    (loading && "loading") ||
    (totalAmount === 0 && "nothing") ||
    (moreDrawFilesNeeded && !canDoLenderAdminThings && "file count");

  const tooFewFilesDesc = `Policy requires at least ${requiredDrawFiles} files, but this draw ${
    numDrawFiles ? `only has ${numDrawFiles}` : "has none"
  }`;

  const CreateDrawButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (props, ref) => (
      <Button
        {...props}
        ref={ref}
        sx={({ palette, spacing }) => ({
          ...invertedButtonStyle({
            palette,
            overrideColor: palette.cta.main,
          }),
          marginLeft: spacing(1),
        })}
        onClick={(...arg) => {
          if (moreDrawFilesNeeded) {
            showLackingFilesConfirmDialog(true);
          } else {
            handleSubmit(handleSubmitDraw("bottom"))(...arg);
          }
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          `${
            (invoiceIdToEdit && "Update/Replace") ||
            `Submit ${putMode ? "Put" : "Draw"}`
          } ${formatMoney(Math.abs(totalAmount))}`
        )}
      </Button>
    )
  );

  const sm = useMediaQuery<Theme>(({ breakpoints: { down } }) => down("sm"));
  return (
    <div className="tableWrapper">
      <FormProvider {...formMethods}>
        {!submittedForApproval ? (
          <HorizontallyAligned>
            <ErrorOrProgressOr
              progress={Boolean(props.loading)}
              render={() => (
                <>
                  {loanType === LoanType.LoC ? (
                    <LineOfCreditTiles
                      tasks={tasks}
                      loanAmount={loanAmount ?? Number.NaN}
                      amountRequested={totalAmount}
                    />
                  ) : (
                    <ConventionalLoanTiles
                      tasks={tasks}
                      amountRequested={totalAmount}
                    />
                  )}
                  <HeaderRow>
                    {/* https://trello.com/c/3tTthoef/932-ui-hide-show-name-changes
                    <Button
                    sx={invertedButtonStyle({
    palette,
    overrideColor: palette.cta.main,
  })}
                      onClick={handleSubmit(handleSubmitDraw("top"))}
                      disabled={loading || totalAmount === 0}
                    >
                      {loading ? (
                        <CircularProgress />
                      ) : (
                        `${
                          (editInvoice && "Update/Replace") ||
                          `Submit ${putMode ? "Put" : "Draw"}`
                        } ${formatMoney(Math.abs(totalAmount))}`
                      )}
                    </Button> */}
                    {loanType === LoanType.LoC ? (
                      <Controller
                        name="putMode"
                        control={control}
                        render={({ field: { value, onChange, ...rest } }) => (
                          <Select
                            variant="standard"
                            // variant="outlined"
                            sx={({ spacing }) => ({
                              marginLeft: spacing(2),
                            })}
                            {...rest}
                            value={String(value)}
                            onChange={({ target: { value } }) =>
                              onChange(value === "true")
                            }
                            // displayEmpty
                            inputProps={{ "aria-label": "Put mode" }}
                            disabled={Boolean(invoiceIdToEdit)}
                          >
                            <MenuItem value={"false"}>Draw</MenuItem>
                            {loanType === LoanType.LoC ? (
                              <MenuItem value={"true"}>
                                <Tooltip title="Allows previously drawn funds to be added back to tasks">
                                  <span>Put</span>
                                </Tooltip>
                              </MenuItem>
                            ) : (
                              <HtmlTooltip
                                disableFocusListener
                                disableTouchListener
                                title={
                                  <React.Fragment>
                                    <Typography color="inherit">
                                      Put Mode Unavailable
                                    </Typography>
                                    <p>
                                      Only loans set up as Line Of Credit can
                                      have Puts.
                                    </p>
                                    <Link to="Loan">Go To loan</Link>
                                  </React.Fragment>
                                }
                              >
                                <div>
                                  <MenuItem value={"true"} disabled>
                                    Put
                                  </MenuItem>
                                </div>
                              </HtmlTooltip>
                            )}
                            {/* <MenuItem value={""} disabled>
                            Change Order
                          </MenuItem> */}
                          </Select>
                        )}
                      />
                    ) : (
                      <div />
                    )}
                    <TopRightButtonsStyler>
                      {showOnDemandInspection ? (
                        <div
                          style={{
                            fontSize: "2rem",
                            display: "inherit",
                            marginRight:
                              "8px" /* would be better with sx and theme spacing */,
                          }}
                          onClick={() =>
                            numConciergeInspections === 0 &&
                            setInspectionDialogOpen(true)
                          }
                        >
                          <Tooltip
                            title={
                              numConciergeInspections > 0
                                ? "You have a Project Centerline Inspection pending"
                                : "Request Project Centerline Inspection and Create Draw"
                            }
                          >
                            {/* <IconButton aria-label="project-centerline-inspection"> */}
                            <Badge
                              color="secondary"
                              variant="dot"
                              invisible={numConciergeInspections === 0}
                            >
                              <SvgIcon
                                /* HACK */
                                fontSize="inherit"
                                sx={bigAssIcon}
                                component={conciergeInspectionIcon}
                                viewBox="0 0 375 375"
                              />
                            </Badge>
                            {/* </IconButton> */}
                          </Tooltip>
                        </div>
                      ) : null}
                      <CSVExport
                        data={csvExportData}
                        headers={csvHeaders}
                        filename={sanitizeFilename(
                          `${projectAddress} - ${filenameDateFormat(
                            new Date()
                          )}.csv`
                        )}
                      />
                    </TopRightButtonsStyler>
                  </HeaderRow>
                  <DrawRequestValuesEditor
                    retention={retention ?? 1}
                    tasks={tasks}
                    putMode={putMode}
                    onTaskCompleteRequested={onTaskCompleteRequested}
                    onTaskDeleteRequested={onTaskDeleteRequested}
                    onTaskEditRequested={onTaskEditRequested}
                    onTaskViewRequested={onTaskViewRequested}
                    onDeleteFile={(index) => {
                      const file = files[index];
                      return onDeleteFile(file).then(() => {
                        enqueueSnackbar(
                          `${extractFileTitle(file) || "File"} deleted`,
                          {
                            variant: "success",
                          }
                        );
                      });
                    }}
                    files={files}
                  />
                  <Grid container spacing={1} alignItems="stretch"></Grid>
                  {useRequestedAmountColumn ? (
                    <Grid container spacing={1} alignItems="stretch">
                      <Grid item xs={3} sm={4}>
                        <StyleApplyingDropzoneContainer>
                          <DropzoneArea
                            dropzoneClass="bar"
                            dropzoneParagraphClass="foo"
                            maxFileSize={maxFileUploadSize}
                            filesLimit={maxFileUploadCount}
                            onChange={onDropzoneFiles}
                            alertSnackbarProps={{ autoHideDuration: 1500 }}
                            useChipsForPreview
                            dropzoneText={
                              sm
                                ? ""
                                : `Drop files here or click to browse${
                                    moreDrawFilesNeeded
                                      ? ` : ${
                                          moreDrawFilesNeeded === 1
                                            ? "ONE MORE REQUIRED"
                                            : `${moreDrawFilesNeeded} MORE REQUIRED`
                                        }`
                                      : ""
                                  }`
                            }
                            getFileAddedMessage={(fileName) =>
                              `File ${fileName} queued for upload.`
                            }
                          />
                        </StyleApplyingDropzoneContainer>
                      </Grid>
                      <Grid item xs={5} sm={6}>
                        <Controller
                          control={control}
                          name="comment"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id="comment"
                              // label="Comments"
                              fullWidth
                              multiline
                              maxRows={4}
                              variant="outlined"
                              placeholder={"Comment (optional)"}
                              sx={{
                                height: "100%",
                                "& .MuiOutlinedInput-root": { height: "100%" },
                              }}
                            />
                          )}
                        ></Controller>
                      </Grid>
                      <Grid item xs={4} sm={2}>
                        {createDisabled && createDisabled !== "loading" ? (
                          <Tooltip
                            title={
                              <Typography>
                                {(createDisabled === "nothing" &&
                                  "Nothing requested. Add some line item values") ||
                                  `${tooFewFilesDesc}. Add more files to enable draw creation`}
                              </Typography>
                            }
                          >
                            <div style={{ display: "flex", height: "100%" }}>
                              <CreateDrawButton disabled />
                            </div>
                          </Tooltip>
                        ) : (
                          <div style={{ display: "flex", height: "100%" }}>
                            <CreateDrawButton
                              disabled={Boolean(createDisabled)}
                            />
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  ) : null}

                  <Prompt
                    message={`${
                      amounts.some((amt) => !!amt)
                        ? "You have unsaved entries"
                        : "Files have not been uploaded yet"
                    }. Do you really want to leave?`}
                    when={
                      amounts.some((amt) => !!amt) ||
                      invoiceWideFiles.length > 0
                    }
                  />
                </>
              )}
            />

            {/* <DevTool control={control} />  */}
          </HorizontallyAligned>
        ) : (
          <HorizontallyAligned>
            <p>
              You submitted your draw request! Users have been notified for
              approval.
            </p>
            <Button
              sx={({ palette }) => ({
                color: palette.cta.main,
                fontSize: "1em !important",
                textDecoration: "none",
              })}
              onClick={handleAdditional}
            >
              Submit Another
            </Button>
          </HorizontallyAligned>
        )}
      </FormProvider>

      <InspectionDialog
        open={inspectionDialogOpen}
        onClose={() => setInspectionDialogOpen(false)}
        projectId={projectId}
      />
      <UploadProgressDialog
        progress={summaryProgress}
        open={loading === "files"}
      />
      {showingLackingFilesConfirmDialog ? (
        <ConfirmDialog
          onConfirm={(...arg) => {
            handleSubmit(handleSubmitDraw("bottom"))(...arg);
            showLackingFilesConfirmDialog(false);
          }}
          open={showingLackingFilesConfirmDialog}
          title="Create Without Required Files?"
          confirmActionWords="Create Anyway"
          onCancel={() => {
            showLackingFilesConfirmDialog(false);
          }}
          setOpen={(wantsToBeOpen) => {
            if (!wantsToBeOpen) {
              showLackingFilesConfirmDialog(false);
            }
          }}
        >
          Are you sure you want to Create this draw? {tooFewFilesDesc}
        </ConfirmDialog>
      ) : null}
    </div>
  );
}

export default function MaybeCreateDrawRequest(props: CreateDrawRequestProps) {
  const { canSeeDrawRequests } = usePermissions();
  if (!canSeeDrawRequests) {
    return null;
  }

  const {
    project: { draw_requests_remaining },
  } = props;
  if (
    draw_requests_remaining != null &&
    Number(draw_requests_remaining) === 0
  ) {
    return (
      <Typography>
        You have reached the maximum number of draw requests
      </Typography>
    );
  }

  return <CreateDrawRequest {...props} />;
}
