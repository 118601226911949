import * as z from "zod";
import React, { CSSProperties, useEffect } from "react";
import {
  useParams,
  useLocation,
  Link,
  Route,
  useNavigate,
  Navigate,
  Outlet,
  useSearchParams,
  Routes,
} from "react-router-dom";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
// import DateRangeIcon from "@mui/icons-material/DateRange";
import ScheduleView from "./ProjectView/Schedule/ScheduleView";
import InvoiceView, {
  TabNames as InvoiceTabs,
} from "./ProjectView/Invoice/InvoiceView";
import DecisionView from "./ProjectView/DecisionMatrix/DecisionMatrixView";
import InfoView, {
  TabNames as InfoTabSubTabs,
} from "./ProjectView/Info/InfoView";
import Search from "./ProjectView/Search/Search";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import TuneIcon from "@mui/icons-material/Tune";
// import ChatIcon from "@mui/icons-material/Chat";
import ChatView from "./ProjectView/Chat/ChatView";
import Badge from "@mui/material/Badge";
import { useAppContext } from "@lib/UserContext";
import ProjectAdminView from "./ProjectView/Admin/ProjectAdminView";
import { useDocumentTitle, useHeader } from "@lib/hooks/useDocumentTitle";
import { AdminViewContainer } from "../Components/AdminViewContainer";
import { useProjectDetails } from "@lib/hooks/useProjectDetails";
import {
  NotificationTypeEnum,
  Notification,
  legacyBackendGetProjectDetailsResponse,
  PartiallyModernizedGetProjectDetailsResponse,
} from "@lib/APITypes";
import { useGetUserRole } from "@/Lib/hooks/useGetUserRole";
import { Typography } from "@mui/material";
import { useEntityFeatures } from "@lib/hooks/useEntityFeatures";
import { useAuth } from "@lib/hooks/useAuth";

const navItemStyle: CSSProperties = {
  minWidth: "56px", // galaxy fold is 280; 56 * 5 = 280
  padding: "6px 0px 8px",
};

export interface LocationState {
  value: string;
}

/**
 * Names of bottom nav items
 */
enum Topic {
  Schedule = "Schedule",
  Decision = "Decision",
  Tasks = "Tasks",
  Info = "Info",
  Chat = "Chat",
  Search = "Search",
  // Admin = "Admin",
}

export const ProjectViewTabs = Topic;

export type ProjectAdminData = Record<string, unknown>;

const TopicSchema = z.nativeEnum(Topic);

const notificationTypeForTopic: Record<
  Topic,
  NotificationTypeEnum | undefined
> = {
  [Topic.Schedule]: NotificationTypeEnum.Task,
  [Topic.Decision]: NotificationTypeEnum.Decision,
  [Topic.Tasks]: NotificationTypeEnum.DrawRequest,
  [Topic.Chat]: NotificationTypeEnum.Chat,
  [Topic.Info]: NotificationTypeEnum.Info, // TODO: Code was looking for "User"; do we need to worry? Don't see it in the DB.
  [Topic.Search]: undefined,
};

function parseTopic(s: string | undefined | null): Topic | null {
  const parsed = TopicSchema.safeParse(s);
  return parsed.success ? parsed.data : null;
}

// function RelativeLink<P extends { to: string }>(props: P) {
//   return <Link relative="path" {...props} />;
// }

const BottomNav = ({
  projectId,
  topic,
  subTopic,
}: {
  projectId?: string;
  topic?: Topic;
  subTopic?: string;
}) => {
  const { user } = useGetUserRole();
  const { notifications = [] } = user || {};

  const notificationsByType = React.useMemo(
    () =>
      notifications.reduce(
        (result, notification) =>
          notification.project_id !== projectId
            ? result
            : result.set(
                notification.type,
                (result.get(notification.type) || []).concat([notification])
              ),
        new Map<NotificationTypeEnum, Notification[]>()
      ),
    [projectId, notifications]
  );

  useEffect(() => {
    const type = topic && notificationTypeForTopic[topic];
    if (type) {
      const notificationsForThisTopic = notificationsByType.get(type) || [];
      if (user && projectId && notificationsForThisTopic.length > 0) {
        user.resetNotifications(projectId, type);
      }
    }
  }, [topic, projectId, notificationsByType, user]);

  function countNotificationsForTopic(topic: Topic): number {
    const type = notificationTypeForTopic[topic];
    if (!type) {
      throw new Error(
        `There is no notification category for ${topic}; why are you trying to count them?`
      );
    }
    return notificationsByType?.get(type)?.length ?? 0;
  }

  const {
    frontend: { hideDecisions },
  } = useEntityFeatures();
  return (
    <BottomNavigation
      value={topic}
      showLabels
      sx={{
        width: "100%",
        position: "fixed",
        bottom: 0,
        zIndex: 100,
        boxShadow: "0px -1px 0px gray",
        left: 0,
      }}
    >
      <BottomNavigationAction
        sx={navItemStyle}
        label={"Draws"}
        value={Topic.Tasks}
        component={Link}
        to={Topic.Tasks}
        icon={
          <Badge
            badgeContent={countNotificationsForTopic(Topic.Tasks)}
            color="secondary"
          >
            <MonetizationOnIcon />
          </Badge>
        }
      />

      {hideDecisions ? null : (
        <BottomNavigationAction
          sx={navItemStyle}
          label="Decisions"
          value={Topic.Decision}
          component={Link}
          to={Topic.Decision}
          icon={
            <Badge
              badgeContent={countNotificationsForTopic(Topic.Decision)}
              color="secondary"
            >
              <AssignmentTurnedInIcon />
            </Badge>
          }
        />
      )}

      <BottomNavigationAction
        sx={navItemStyle}
        label="More"
        value="Info"
        component={Link}
        to={Topic.Info}
        icon={
          <Badge
            badgeContent={countNotificationsForTopic(Topic.Info)}
            color="secondary"
          >
            <TuneIcon />
          </Badge>
        }
      />
    </BottomNavigation>
  );
};

const Admin = ({
  projectData,
  extraAdminData,
  currentAction,
}: {
  projectData?: Readonly<legacyBackendGetProjectDetailsResponse>;
  extraAdminData?: ProjectAdminData;
  currentAction?: Topic;
}) => {
  const { showAdmin, requestAdminView } = useAppContext();

  return projectData ? (
    <AdminViewContainer
      initialPosition="bottom"
      isVisible={showAdmin}
      onVisibleChange={() => requestAdminView(!showAdmin)}
      dimMode="transparent"
    >
      <ProjectAdminView
        data={projectData}
        extraData={{ ...extraAdminData, currentAction }}
      />
    </AdminViewContainer>
  ) : null;
};

function ProjectInnerView({
  projectData,
  onProjectModified,
}: {
  projectData: Readonly<PartiallyModernizedGetProjectDetailsResponse>;
  onProjectModified?: () => void;
}) {
  const { project_id: projectId } = projectData;
  const navigate = useNavigate();

  const [extraAdminData, setExtraAdminData] =
    React.useState<ProjectAdminData>();
  const setExtraAdminDataCallback = React.useCallback(
    (theData: ProjectAdminData) => {
      setExtraAdminData(theData);
    },
    []
  );
  const adminSupport = {
    setExtraAdminData: setExtraAdminDataCallback,
  };
  const { topic, subTopic } = useParams<{
    topic: Topic;
    subTopic: string;
  }>();

  const slugAliases: Record<string, Topic> = {
    "Draw Request": Topic.Tasks,
    ...Object.fromEntries(InvoiceTabs.map((t) => [t, `${Topic.Tasks}/${t}`])),
    More: Topic.Info,
    ...Object.fromEntries(
      Object.values(InfoTabSubTabs).map((t) => [t, `${Topic.Info}/${t}`])
    ),
  };

  // // in an ideal world we are at /projectview/:id/<topic|subtopic>
  // const topicFromPath = parseTopic(
  //   Object.entries(routeMatchers).find(([_topic, path]) =>
  //     matchPath(location.pathname, { path })
  //   )?.[0]
  // );

  // deprecated deep links /projectview/:id?state=topic
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const stateFromSearch = searchParams.get("state");
  const invoiceId = searchParams.get("invoiceId");
  const topicFromQueryString =
    stateFromSearch &&
    (parseTopic(stateFromSearch) || slugAliases[stateFromSearch]);
  React.useEffect(() => {
    if (topicFromQueryString) {
      navigate(
        `${topicFromQueryString}${invoiceId ? `?invoiceId=${invoiceId}` : ""}`,
        { replace: true }
      );
    }
  }, [invoiceId, navigate, topicFromQueryString]);

  // with state e.g. from search button in header
  const topicFromLocationState =
    location.state?.value &&
    (parseTopic(location.state.value) || slugAliases[location.state.value]);
  React.useEffect(() => {
    if (topicFromLocationState) {
      navigate(
        `${topicFromLocationState}${
          invoiceId ? `?invoiceId=${invoiceId}` : ""
        }`,
        { replace: true }
      );
    }
  }, [invoiceId, navigate, topicFromLocationState]);

  const {
    frontend: { hideDecisions },
  } = useEntityFeatures();

  return (
    <Routes>
      <Route
        element={
          <div>
            <Outlet />
            <BottomNav
              projectId={projectId}
              topic={topic}
              subTopic={subTopic}
            />
            <Admin
              extraAdminData={extraAdminData}
              currentAction={topic}
              projectData={projectData}
            />
          </div>
        }
      >
        <Route
          path={Topic.Schedule}
          element={
            <div>
              <ScheduleView
                data={
                  {
                    ...projectData,
                    original_value: projectData.originalValue,
                    retention: projectData.retention || 0,
                  } /* TODO: Sanitize on retrieval. */
                }
                callBack={() => {
                  onProjectModified?.();
                }}
                onDecisionDateClicked={() => {
                  hideDecisions || navigate(Topic.Decision);
                }}
                {...adminSupport}
              />
            </div>
          }
        />
        <Route
          path={Topic.Decision}
          element={
            <div>
              <DecisionView project={projectData} {...adminSupport} />
            </div>
          }
        />
        <Route
          path={`${Topic.Info}/*`}
          element={
            <div>
              <InfoView projectId={projectData.project_id} {...adminSupport} />
            </div>
          }
        />
        <Route
          path={Topic.Chat}
          element={
            <div>
              <ChatView data={projectData} {...adminSupport} />
            </div>
          }
        />
        <Route
          path={Topic.Search}
          element={
            <div>
              <Search data={projectData} {...adminSupport} />
            </div>
          }
        />
        <Route
          path={Topic.Tasks}
          element={
            <div>
              <InvoiceView data={projectData} {...adminSupport} />
            </div>
          }
        />
        {Object.entries(slugAliases).map(([from, to]) => (
          <Route
            path={from}
            element={<Navigate replace to={`../${to}`} />}
            key={`alias-${from}-${to}`}
          />
        ))}
        <Route index element={<Navigate replace to={Topic.Tasks} />} />
      </Route>
    </Routes>
  );
}
export default function ProjectView(): JSX.Element | null {
  const { projectId } = useParams<{ projectId: string }>();
  const { currentUserEmail: email } = useAuth();

  if (!projectId) {
    throw new Error("Can't find project id");
  }

  const { project, errors } = useProjectDetails(projectId);

  const projectData = project?.details;
  const projectAddress = projectData?.address;
  const projectName = projectData?.name;

  useDocumentTitle(projectName || projectAddress || "Project Centerline");
  const header = React.useMemo(
    () =>
      projectAddress ? (
        <Typography noWrap variant="h5">
          {projectName || projectAddress}
        </Typography>
      ) : undefined,
    [projectAddress, projectName]
  );
  useHeader(header);

  // return needRedirect ? (
  //   <Navigate replace to={topic} />
  // ) :

  return projectData ? (
    <ProjectInnerView projectData={projectData} />
  ) : errors?.details?.response?.status === 404 ? (
    <div>
      <p>Sorry, that project doesn't seem to exist.</p>
    </div>
  ) : errors?.details?.response?.status === 403 ? (
    <div>
      <p>
        <em>{email}</em> does not have permission to view this project. Contact
        the project owner to be added.
      </p>
    </div>
  ) : null;
}

export interface ExtraAdminDataProtocolProps {
  setExtraAdminData?: (theData: ProjectAdminData) => void;
}

// TODO: something like the WIP below
// export const useExtraAdminData = (setExtraAdminData ?: (theData: unknown) => void) => (
//   extraData: unknown,
//   setExtraAdminData?: (theData: unknown) => void,

// ) => {
//   useEffect(() => {
//     setExtraAdminData?.(extraData);
//   }, [extraData]);

//   useEffect(() => {
//     // do nothing on mount, but cleanup on un-mount
//     return () => setExtraAdminData?.({});
//   }, [setExtraAdminData]);
// };
