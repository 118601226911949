import { AddressForm, AddressInputs } from "@/Forms/AddressForm";
import { useFormContext } from "react-hook-form";
import ProjectPicker, { ProjectPickerProps } from "./ProjectPicker";
import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/material";
import React from "react";
import { Project2024 } from "@/Lib/types";
import { fullAddress } from "@/Lib/util";

export type Context = AddressInputs & { selectedProject?: Project2024 };

export const ProjectOrNewAddress = ({
  projects,
  prompt = "<Enter New Address>",
  ...props
}: {
  projects: ReadonlyArray<Project2024>;
  prompt?: string;
} & Omit<ProjectPickerProps, "onSelectProject" | "createNew">) => {
  const [selectedAddress, setSelectedAddress] = React.useState<
    string | undefined
  >("");
  const { setValue, reset } = useFormContext<Context>();

  return (
    <Stack>
      <ProjectPicker
        {...props}
        projects={projects}
        onSelectProject={(p) => {
          console.log("selected", { p });
          setSelectedAddress(fullAddress(p));
          const { address, city, state, zipcode } = p;
          setValue("address", address);
          setValue("city", city || "");
          setValue("state", state || "");
          setValue("zipcode", zipcode || "");
          setValue("selectedProject", p);
        }}
        createNew={{
          onCreateNew: () => {
            reset();
            setSelectedAddress(undefined);
          },
          prompt,
        }}
        nameOnly
      />

      <Box mt={1}>
        {selectedAddress !== undefined ? (
          <Typography fontStyle="italic">{selectedAddress}</Typography>
        ) : (
          <AddressForm oneLine={true} variant="outlined" />
        )}
      </Box>
    </Stack>
  );
};

export default ProjectOrNewAddress;
