import React from "react";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import sanitizeFilename from "sanitize-filename";
import {
  // deriveDisplayData,
  // displayDrawLines,
  csvHeaders,
  DrawReportButton,
  InvoiceTotal,
  CommonInvoicesProps,
  invoiceDescription,
  MarkPaidCheckbox,
  InvoiceContent,
  InvoiceHeader,
  DrawReportButtons,
  InvoiceList,
  commonStyles,
  DrawLines,
  useDisplayDraws,
} from "./InvoicesCommon";
import { CommentsSection } from "./CommentsSection";
import { DisplayFiles } from "../../../Components/DisplayFiles";
import { useMoney } from "@lib/hooks/useMoney";
import { CSVExport } from "../../../Components/CSVExport";
import { logError } from "@lib/ErrorLogging";
import { ConfirmDialog } from "../../../Components/ConfirmDialog";
import { useInvoiceComments, usePermissions } from "@lib/hooks";
import { Badge, Button, IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import { ImageGallery } from "../../../Components/ImageGallery";
import { useEntityFeatures } from "@lib/hooks/useEntityFeatures";
import { Invoice, NotPendingInvoice } from "../../../Models";
import { LightboxFiles } from "./LightboxFile";
import { AttachedFilesIcon } from "@lib/uikit";
import {
  InvoiceId,
  ProjectId,
} from "@project-centerline/project-centerline-api-types";

export interface FinalizedInvoicesProps
  extends CommonInvoicesProps<NotPendingInvoice> {
  reverseInvoice?: (prop: {
    invoiceId: InvoiceId;
    projectId: ProjectId;
  }) => Promise<unknown>;
}

const FinalizedInvoice = ({
  invoice,
  sequence,
  filenameHint,
  onTitleClicked,
  files,
  tasks,
  onReverse,
}: Pick<
  FinalizedInvoicesProps,
  "filenameHint" | "files" | "onTitleClicked" | "tasks"
> & {
  invoice: Invoice;
  sequence: number;
  onReverse?: () => void;
}) => {
  const [lightboxFiles, setLightboxFiles] = React.useState<LightboxFiles>([]);
  const [lightboxOpen, setLightboxOpen] = React.useState(false);
  const handleShowFiles = (files: LightboxFiles) => {
    setLightboxFiles(files);
    setLightboxOpen(true);
  };
  const displayDraws = useDisplayDraws({ invoice, tasks, files });
  const csvData = displayDraws.map(({ drawRequestAmount, task, ...rest }) => ({
    drawRequestAmount: invoice.is_put ? -drawRequestAmount : drawRequestAmount,
    ...rest,
    task: task.title,
  }));

  const { formatMoney } = useMoney();
  const { canReverseDrawRequests, canRemoveInvoiceComment } = usePermissions();
  const { perLineItemAttachments: canSeePerLineItemAttachments } =
    useEntityFeatures();
  const { comments, addComment, removeComment } = useInvoiceComments({
    invoiceId: invoice.invoice_id,
  });

  const invoiceDate = invoice.timestamp;
  return (
    <>
      <Card id={invoice.invoice_id} sx={commonStyles.root}>
        <CardContent>
          <InvoiceContent>
            <InvoiceHeader>
              <MarkPaidCheckbox invoice={invoice} />
              <DrawReportButtons>
                {invoice.is_put ? null : (
                  <>
                    <DrawReportButton preview invoiceId={invoice.invoice_id} />
                    <DrawReportButton invoiceId={invoice.invoice_id} />
                  </>
                )}
                <CSVExport
                  data={csvData}
                  headers={csvHeaders(invoice.is_put)}
                  filename={sanitizeFilename(
                    `${filenameHint} ${
                      invoice.is_put ? "- Put " : ""
                    }- ${invoiceDate.toISOString().split("T").shift()}.csv`
                  )}
                />
              </DrawReportButtons>
            </InvoiceHeader>
            <h3>{invoiceDescription(invoice, sequence, invoiceDate)}</h3>
          </InvoiceContent>
          <Divider />
          <DrawLines
            displayInfo={{ invoice, displayDraws }}
            formatMoney={formatMoney}
            maxDrawLines={3}
            onTitleClicked={(draw) => onTitleClicked({ invoice, draw })}
            tasks={tasks}
            renderFileAffordance={
              canSeePerLineItemAttachments
                ? ({ title }) => {
                    const filesThisLine = files.filter(
                      (f) =>
                        f.invoice_id === invoice.invoice_id &&
                        f.task_id === tasks.find((t) => t.title === title)?.id
                    );
                    return filesThisLine.length > 0 ? (
                      <IconButton
                        onClick={() => handleShowFiles(filesThisLine)}
                        size="large"
                      >
                        <Badge
                          badgeContent={filesThisLine.length}
                          color="primary"
                        >
                          <AttachedFilesIcon />
                        </Badge>
                      </IconButton>
                    ) : undefined;
                  }
                : undefined
            }
          />
          <InvoiceTotal displayInfo={{ invoice, displayDraws }} />
          <p
            style={{
              width: "100%",
            }}
          >
            Final Approver: {invoice.final_approver}
          </p>
          <DisplayFiles files={invoice.storedFiles} onClick={handleShowFiles} />
          {comments?.length && addComment ? (
            <CommentsSection
              readonly
              invoiceId={invoice.invoice_id}
              comments={comments}
              addComment={addComment}
              removeComment={canRemoveInvoiceComment(invoice) && removeComment}
            />
          ) : null}

          {canReverseDrawRequests && onReverse ? (
            <>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button color="secondary" onClick={onReverse}>
                  Reverse Draw
                </Button>
              </div>
            </>
          ) : null}
        </CardContent>
      </Card>
      {lightboxOpen ? (
        <ImageGallery
          files={lightboxFiles}
          open={lightboxOpen}
          onClose={() => setLightboxOpen(false)}
          downloadAllFilename={`${filenameHint} - ${invoice.identifier} - files.zip`}
        />
      ) : null}
    </>
  );
};
/**
 * Show a list of invoices, which are assumed to be approved or rejected (i.e. not pending). It is no longer
 * possible to accept, reject, or add files.
 *
 * //TODO: #90 This can surely be combined with PendingInvoices, no?
 *
 * @param {FinalizedInvoiceProps} props configuration
 * @param props.filenameHint used as part of the generated name for CSV download.
 * @param props.invoices the invoices to show
 * @param props.tasks the project tasks that the invoices may be drawing against
 */
export function FinalizedInvoices({
  filenameHint,
  invoices,
  tasks,
  reverseInvoice,
  files = [],
  onTitleClicked,
  projectId,
}: FinalizedInvoicesProps): JSX.Element {
  // const displayedInvoices = React.useMemo(
  //   () => deriveDisplayData(tasks, invoices, files),
  //   [files, invoices, tasks]
  // );

  const [drawToReverse, setDrawToReverse] = React.useState<
    InvoiceId | undefined
  >(undefined);
  const [reversingInProgress, setReversingInProgress] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  return (
    <div className="invoices">
      {invoices.length === 0 ? null : (
        <InvoiceList>
          {invoices.map((invoice, i) => (
            <FinalizedInvoice
              invoice={invoice}
              sequence={i}
              key={invoice.invoice_id}
              filenameHint={filenameHint}
              onReverse={
                reverseInvoice
                  ? () => {
                      setDrawToReverse(invoice.invoice_id);
                    }
                  : undefined
              }
              files={files}
              onTitleClicked={onTitleClicked}
              tasks={tasks}
            />
          ))}
        </InvoiceList>
      )}
      {drawToReverse ? (
        <ConfirmDialog
          open={Boolean(drawToReverse)}
          setOpen={() => {
            /* nope; we're state driven */
          }}
          busy={reversingInProgress}
          title="Reverse Draw"
          onCancel={() => {
            setDrawToReverse(undefined);
          }}
          onConfirm={() => {
            setReversingInProgress(true);

            return reverseInvoice?.({
              invoiceId: drawToReverse,
              projectId,
            })
              .then(() => {
                setDrawToReverse(undefined);
              })
              .catch((err: Error) => {
                logError(new Error("Error reversing draw"), {
                  err,
                });
                enqueueSnackbar(
                  "Sorry; something went wrong. Please try again later",
                  { variant: "error" }
                );
              })
              .finally(() => {
                setReversingInProgress(false);
              });
          }}
        >
          Are you sure you want to reverse this draw?
          {/* It
                  should not be the right answer very often. Maybe
                  there is a better way to do things so you can avoid
                  the need in the future. */}
        </ConfirmDialog>
      ) : undefined}
    </div>
  );
}

export default FinalizedInvoices;
