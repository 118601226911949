import { Project2024 } from "../types";

export * from "./stringIfNonTruthy";
type AddressComponents = Pick<
  Project2024,
  "address" | "city" | "state" | "zipcode" | "unit"
>;

export const fullAddress = ({
  address,
  city,
  state,
  zipcode,
  unit,
}: AddressComponents) =>
  `${address}${unit ? `#${unit}` : ""}, ${city}, ${state}, ${zipcode}`;

export const projectDescription = (
  p: AddressComponents & Pick<Project2024, "name">
) => {
  const full = fullAddress(p);
  const { name, address } = p;
  return name === address ? full : `${name} (${full})`;
};

export * from "./replaceInvalidNumber";
