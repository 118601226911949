import {
  availableColumns,
  ColumnId,
  useColumnDerivationContext,
} from "@/Components/ProjectColumnGenerator";
import { Role } from "@/Lib/roles";
import { standardColumns } from "./ProjectGrid";
import { BackendGetProjectsResponseEntry, ProjectStatus } from "@/Lib/APITypes";
import { useFeatures } from "@/Lib/hooks";

export interface View {
  description: string;
  columns: ColumnId[];
  useDates?: boolean;
  filter: (
    project: BackendGetProjectsResponseEntry,
    extra: {
      email: string;
      role: Role;
      isConciergeInspector: (arg: { email: string }) => boolean;
    }
  ) => boolean;
}
// identity function gets us lots of type stuff without having to be explicit
export const makeView = (view: View) => view;

/*
 * each "view" has a description and a list of columns, in display order. For now, there is only one and we don't
 * show a control to switch it, but that is coming. (see also `currentView` in render function)
 */

export type ViewID =
  | "all"
  | "active"
  | "debug"
  | "todo"
  | "pending"
  | "delayed"
  | "complete";

export const createViews: (arg: {
  features: ReturnType<typeof useFeatures>;
  context: ReturnType<typeof useColumnDerivationContext>;
}) => Record<ViewID, View> = ({ features: { dhlcCustom }, context }) => {
  const {
    permissions: { canDeleteProjects, canOpenCloseProjects },
  } = context;

  const views = {
    todo: makeView({
      columns: standardColumns({
        useCustom1Column: dhlcCustom,
        canDeleteProjects,
        useDotDotDotMenu: canOpenCloseProjects,
      }),
      description: "My Todo",
      filter: ({ summary }, { email, role, isConciergeInspector }) => {
        return (
          summary?.p.some(
            ({ currentApprover }) =>
              currentApprover === email ||
              (role === Role.SuperAdmin &&
                (isConciergeInspector({ email: currentApprover }) ||
                  (summary.pci?.length ?? 0) > 0))
          ) ?? false
        );
      },
    }),
    pending: makeView({
      columns: standardColumns({
        useCustom1Column: dhlcCustom,
        canDeleteProjects,
        useDotDotDotMenu: canOpenCloseProjects,
      }),
      description: "Draw Pending",
      filter: (project) =>
        availableColumns.Actions.derive(project, context).value !== "Delayed" &&
        (project.summary?.pci?.length || project.summary?.p?.length
          ? true
          : false),
    }),
    delayed: makeView({
      columns: standardColumns({
        useCustom1Column: dhlcCustom,
        canDeleteProjects,
        useDotDotDotMenu: canOpenCloseProjects,
      }),
      description: "Delayed",
      filter: (project) =>
        availableColumns.Actions.derive(project, context).value === "Delayed",
    }),
    all: makeView({
      columns: standardColumns({
        useCustom1Column: dhlcCustom,
        canDeleteProjects,
        useDotDotDotMenu: canOpenCloseProjects,
      }),
      description: "All",
      filter: (x) => Boolean(x),
    }),
    complete: makeView({
      columns: standardColumns({
        useCustom1Column: dhlcCustom,
        canDeleteProjects,
        useDotDotDotMenu: canOpenCloseProjects,
      }),
      description: "Completed",
      filter: (project) =>
        availableColumns.Status.derive(project, context).value ===
        ProjectStatus.Complete,
    }),
    debug: makeView({
      columns: standardColumns({
        useCustom1Column: dhlcCustom,
        canDeleteProjects,
        useDotDotDotMenu: canOpenCloseProjects,
      }).concat("SearchAttributes"),
      description: "Search Debug",
      filter: (x) => Boolean(x),
    }),
    active: makeView({
      columns: standardColumns({
        useCustom1Column: dhlcCustom,
        canDeleteProjects,
        useDotDotDotMenu: canOpenCloseProjects,
      }),
      description: "Active",
      filter: (project) =>
        availableColumns.Status.derive(project, context).value !==
        ProjectStatus.Complete,
    }),
    // {
    //   description: "Slim",
    //   columns: ["Name", "Amount", "LoanMaturityDate"],
    //   filter: (x) => Boolean(x),
    // },
    // {
    //   description: "All",
    //   columns: Object.keys(availableColumns) as ColumnId[],
    //   // useDates: true,
    //   filter: (x) => Boolean(x),
    // },
  };

  return views;
};
