import React, { useMemo } from "react";
import { Role } from "@/Lib/roles";
import { useAppContext } from "@/Lib/UserContext";
import {
  availableColumns,
  ColumnId,
  DerivationFunctionContext,
} from "../../../Components/ProjectColumnGenerator";
import { useFeatures, usePermissions } from "@/Lib/hooks";
import { useEntityFeatures } from "@/Lib/hooks/useEntityFeatures";
import { enqueueSnackbar } from "notistack";
import { useInspectors } from "@/Lib/hooks/useInspectors";
import { CSVExport } from "../../../Components/CSVExport";
import sanitizeFilename from "sanitize-filename";
import { filenameDateFormat } from "@/Lib/util/filenameDateFormat";
import { BackendGetProjectsResponse, ProjectStatus } from "@/Lib/APITypes";

interface CSVProps {
  projects: BackendGetProjectsResponse;
  onRequestDelete: (projectId: string) => void;
  onUpdateStatus: (projectId: string, status: ProjectStatus) => void;
}

const PortfolioCSVGenerator = (props: CSVProps) => {
  const { role } = useAppContext();
  const { projects } = props;
  const { protocol, host } = window.location;
  const permissions = usePermissions();
  const entityFeatures = useEntityFeatures();
  const features = useFeatures();
  const { onRequestDelete, onUpdateStatus } = props;
  const {
    isConciergeInspector,
    isFullConciergeInspector,
    availableInspectors: inspectorsList,
  } = useInspectors();
  const availableInspectors = useMemo(
    () =>
      Object.fromEntries(
        inspectorsList.map((inspector) => [inspector.email, inspector])
      ),
    [inspectorsList]
  );

  const dhlcOnlyCSVColumns: ColumnId[] = ["Custom1"];
  const superAdminOnlyCSVColumns: ColumnId[] = [
    "FullAddress",
    "NumDraws",
    "ProjectId",
    "DaysSinceLastDraw",
  ];

  const ProjectColumns = (
    [
      "Name",
      "Amount",
      "EffectiveBudget",
      "EndDate",
      "LoanIdentifier",
      "LoanAmount",
      "LoanMaturityDate",
      "LoanStartDate",
      "PercentBudgetRemaining",
      "PercentComplete",
      "RemainingLoanBalance",
      "RemainingLooserBalance",
      "RemainingTaskBalance",
      "TotalTaskValue",
      "TotalActiveTaskValue",
      "Health",
      "HealthMetric",
      "LoanType",
      "Actions",
      "Status",
    ] as ColumnId[]
  )
    .concat(features.dhlcCustom ? dhlcOnlyCSVColumns : [])
    .concat(role === Role.SuperAdmin ? superAdminOnlyCSVColumns : []);

  const csvData = React.useMemo(() => {
    const extras: DerivationFunctionContext = {
      urlBase: `${protocol}//${host}`,
      permissions,
      entityFeatures,
      onRequestDelete,
      onUpdateStatus: (
        id: string,
        newStatus: ProjectStatus,
        pending: unknown[]
      ) => {
        if (newStatus === ProjectStatus.Complete) {
          // setViewRefreshNeeded(true); // "Complete" view is dynamic
          if (pending?.length > 0) {
            enqueueSnackbar(
              "The project you just marked complete still has Pending Draws",
              { variant: "warning" }
            );
          }
        }
        onUpdateStatus(id, newStatus);
      },
      isConciergeInspector,
      isFullConciergeInspector,
      availableInspectors,
    };

    return projects.map((project) => {
      return ProjectColumns.map((columnName) => {
        const derived = availableColumns[columnName].derive(project, extras);
        return derived.csv ?? derived.formatted;
      });
    });
  }, [
    ProjectColumns,
    availableInspectors,
    entityFeatures,
    host,
    isConciergeInspector,
    isFullConciergeInspector,
    onRequestDelete,
    onUpdateStatus,
    permissions,
    projects,
    protocol,
  ]);

  return (
    <div>
      <CSVExport
        useNewIcon
        data={csvData}
        headers={ProjectColumns.map(
          (item) =>
            availableColumns[item].csvHeader ??
            (availableColumns[item].label as string)
        )}
        filename={sanitizeFilename(
          `Portfolio - ${filenameDateFormat(new Date())}.csv`
        )}
      />
    </div>
  );
};

export default PortfolioCSVGenerator;
