// @mui material components
import { Theme } from "@mui/material/styles";
import { CSSProperties } from "@mui/material/styles/createMixins";

function timelineItem(
  theme: Theme,
  ownerState: { lastItem?: boolean; isDark: boolean | JSX.Element | null }
): CSSProperties {
  const { borders } = theme;
  const { lastItem, isDark } = ownerState;

  const { borderWidth, borderColor } = borders;

  return {
    "&:after": {
      content: !lastItem && "''",
      position: "absolute",
      top: "2rem",
      left: "17px",
      height: "100%",
      opacity: isDark ? 0.1 : 1,
      borderRight: `${borderWidth[2]} solid ${borderColor}`,
    },
  };
}

export default timelineItem;
