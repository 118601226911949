import React, { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  List,
  ListItem,
  ListItemText,
  MenuItem,
  IconButton,
  ListItemSecondaryAction,
  CircularProgress,
  useTheme,
  DialogTitle,
} from "@mui/material";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DroppableProps,
  DropResult,
} from "react-beautiful-dnd";
import ClearIcon from "@mui/icons-material/Clear";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { reorder } from "@lib/misc";
import { v4 as uuidV4 } from "uuid";
import { PersonWithEmailAndRole } from "../Pages/ProjectView/DecisionMatrix/DecisionMatrixView";

// https://medium.com/@wbern/getting-react-18s-strict-mode-to-work-with-react-beautiful-dnd-47bc909348e4
// Credits to https://github.com/GiovanniACamacho and https://github.com/Meligy for the TypeScript version
// Original post: https://github.com/atlassian/react-beautiful-dnd/issues/2399#issuecomment-1175638194import { useEffect, useState } from "react";
const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);
  if (!enabled) {
    return null;
  }
  return <Droppable {...props}>{children}</Droppable>;
};

function addDraggableId<T>(person: T) {
  return { ...person, draggableId: uuidV4() };
}

export interface PeopleRoutingFormProps<
  UserType extends PersonWithEmailAndRole
> {
  title: string;
  people: ReadonlyArray<UserType>;
  addablePeople: ReadonlyArray<UserType>;
  onChange?: (newPeople: ReadonlyArray<UserType>) => void;
}

export function PeopleRoutingForm<UserType extends PersonWithEmailAndRole>({
  title,
  people,
  addablePeople,
  onChange,
}: PeopleRoutingFormProps<UserType>) {
  const { palette } = useTheme();
  const editMode = Boolean(onChange);
  const dragPeople = people.map(addDraggableId);

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (destination) {
      onChange?.(reorder(people, source.index, destination.index));
    }
  };

  const getItemStyle = (
    isDragging: boolean,
    draggableStyle?: React.CSSProperties
  ) => ({
    ...draggableStyle,
    ...(isDragging && {
      background: "rgb(235,235,235)",
    }),
  });

  const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? palette.primary.main : "white",
  });

  // something about the way we are fiddling things on user add is leaving the Select open *sometimes*. So just control it
  const [addUserOpen, setAddUserOpen] = React.useState(false);
  return (
    <>
      <DialogTitle>{title}</DialogTitle>

      <DragDropContext onDragEnd={onDragEnd}>
        <StrictModeDroppable droppableId="droppable">
          {(provided, snapshot) => (
            <>
              <List
                style={getListStyle(snapshot.isDraggingOver)}
                ref={provided.innerRef}
              >
                {dragPeople.map((item, index) => (
                  <Draggable
                    key={item.draggableId}
                    draggableId={item.draggableId}
                    index={index}
                    isDragDisabled={!editMode}
                  >
                    {(provided, snapshot) => (
                      // <div ref={provided.innerRef}>
                      <ListItem
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        {editMode && (
                          <Button
                            onClick={(e) => {
                              onChange?.(
                                dragPeople.filter(
                                  ({ draggableId }) =>
                                    draggableId !== item.draggableId
                                )
                              );
                            }}
                            style={{ marginRight: "5px" }}
                          >
                            <ClearIcon color="secondary" />
                          </Button>
                        )}
                        <ListItemText
                          primary={item.first_name + " " + item.last_name}
                          secondary={item.email}
                        />
                        {editMode && (
                          <IconButton disabled size="large">
                            <OpenWithIcon />
                          </IconButton>
                        )}
                        <ListItemSecondaryAction />
                      </ListItem>
                      // </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
                {editMode && (
                  <ListItem>
                    <Button disabled style={{ marginRight: "5px" }}>
                      {/* Just to lazy-match the layout above */}
                    </Button>
                    <FormControl fullWidth>
                      <Select
                        value="add-new"
                        id="add-approver-id"
                        open={addUserOpen}
                        onOpen={() => setAddUserOpen(true)}
                        onClose={() => setAddUserOpen(false)}
                      >
                        <MenuItem disabled value="add-new">
                          <em>Add Another User...</em>
                        </MenuItem>
                        {addablePeople.map((user, index) => {
                          const key = `user-${index}`;
                          return (
                            <div key={key}>
                              <MenuItem
                                value={key}
                                onClick={() => {
                                  setAddUserOpen(false);
                                  onChange?.(
                                    people.concat([addablePeople[index]])
                                  );
                                }}
                              >
                                {user.first_name + " " + user.last_name} :{" "}
                                {user.role}
                              </MenuItem>
                            </div>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <Button disabled style={{ marginRight: "5px" }}>
                      {/* Just to lazy-match the layout above */}
                    </Button>
                    <ListItemSecondaryAction />
                  </ListItem>
                )}
              </List>
            </>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    </>
  );
}

export function PeopleRoutingEditor<UserType extends PersonWithEmailAndRole>({
  title,
  addablePeople,
  open,
  busy,
  onClose,
  onSubmit,
  initialPeople,
  cancelButtonText = "Close",
  submitButtonText = "Submit",
}: Omit<PeopleRoutingFormProps<UserType>, "onChange" | "people"> & {
  open: boolean;
  busy: boolean;
  onClose: () => void;
  onSubmit: (arg: ReadonlyArray<UserType>) => void;
  cancelButtonText?: string;
  submitButtonText?: string;
  initialPeople: ReadonlyArray<UserType>;
}): JSX.Element {
  const [people, setPeople] = React.useState(initialPeople);
  React.useEffect(() => {
    if (!open) {
      setPeople(initialPeople);
    }
  }, [initialPeople, open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <PeopleRoutingForm
          title={title}
          people={people}
          addablePeople={addablePeople}
          onChange={setPeople}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {cancelButtonText}
        </Button>
        <Button
          onClick={() => onSubmit(people)}
          color="primary"
          disabled={busy}
        >
          {busy ? <CircularProgress /> : submitButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
