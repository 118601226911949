// for use with ImageGallery. That's too big already.

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from "@mui/material";
import { PathAndKey } from "@project-centerline/project-centerline-api-types";

/**
 * Progress dialog for ImageGallery "download all " button
 *
 * @param props.files list of files being downloaded. It's actually only used to control `open` property but was
 *  what we were using when I ripped this out :-)
 * @param props.filename resulting sip filename we are generating, so we can show it to the user
 * @param props.url The link for "Download" button, when encoding is done. If set, we assume we are done and just waiting for the user
 * @param props.error In case of error, we will show to user
 * @param onClose called when the user clicks Cancel or Download
 */
export const DownloadingArchiveDialog = ({
  files,
  filename,
  url,
  error,
  onClose,
}: {
  files?: Array<PathAndKey>;
  filename: string;
  url?: string;
  error?: Error;
  onClose: () => void;
}) => (
  <Dialog open={!!files?.length}>
    <DialogTitle>
      {url
        ? "File Ready"
        : (error?.message && "Error creating Archive") ?? `Creating Archive`}
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        {url ? `Created ${filename}` : error?.message ?? `Creating ${filename}`}
      </DialogContentText>
      {!error && !url && <LinearProgress />}
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button
        href={url}
        disabled={!url}
        onClick={() => setTimeout(onClose, 250)}
      >
        Download
      </Button>
    </DialogActions>
  </Dialog>
);

export default DownloadingArchiveDialog;
