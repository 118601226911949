import { zodResolver } from "@hookform/resolvers/zod";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  styled,
  Slider,
  SwitchProps,
  TextField,
  Typography,
  Tooltip,
  InputAdornment,
  IconButton,
  FormHelperText,
  Box,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  UseFormReturn,
} from "react-hook-form";
import * as z from "zod";
import {
  DrawReportModeSchema as DrawReportMode,
  Entity,
  EntityFeatures,
  EntityFeaturesZodSchema,
  UserIdentity,
} from "@lib/APITypes";
import EditIcon from "@mui/icons-material/Edit";
import { logAnomaly, logError } from "@lib/ErrorLogging";
import { prettyName } from "./prettyName";
import { useAllUsers } from "./useAllUsers";
import { Storage } from "aws-amplify";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { NVMSConfigEditor } from "./NVMSConfigEditor";
import {
  ControlledSwitch,
  ControlledSwitchProps,
} from "../../Components/ControlledSwitch";
import { PersonWithEmailAndRole } from "../ProjectView/DecisionMatrix/DecisionMatrixView";
import { Role } from "@lib/roles";
import { PeopleRoutingEditor } from "../../Components/PeopleRoutingEditor";
import { byProp } from "@lib/misc";
import {
  EmailAddress,
  storedFileZodSchema,
} from "@project-centerline/project-centerline-api-types";

const PrettyName = z.string().regex(/\S+\s+\S+\s*<\S+@[-A-Za-z0-9.]+>/);

export const validNVMSSecretSchema = z.string().uuid();
export const placeholderNVMSSecretSchema = z
  .string()
  .length(36)
  .regex(/^\*+[a-zA-Z0-9]+$/);
const nvmsApiSecretSchema = z.union([
  validNVMSSecretSchema,
  placeholderNVMSSecretSchema,
]);

export const nvmsSchema = z.object({
  apiSecret: nvmsApiSecretSchema,
  twoDayInspectionService: z.number().int(),
  nvmsUserId: z.number().int(),
  payments: z.object({
    sfr: z.union([
      z.object({
        type: z.literal("stripe"),
        priceKey: z.string().min(1),
      }),
      z.object({
        type: z.literal("john"),
        amount: z.number().nonnegative(),
      }),
      z.object({
        type: z.literal("not-required"),
      }),
    ]),
  }),
  sendAllItems: z.boolean().default(false),
  staticFileNumber: z.boolean().default(false),
});

const nvmsOptionalSchema = nvmsSchema.deepPartial().nullish();

// z.string().regex(/^\*+[a-zA-Z0-9]+$/),
// ]);
export const EntityBaseZodSchema = z.object({
  matchDomains: z.array(z.string().regex(/[-A-Za-z0-9.]+/)).default([]),
  matchEmails: z.array(z.union([z.string().email(), PrettyName])).default([]),
  autoAddUsers: z.array(z.union([UserIdentity, PrettyName])).default([]),
  overrideInvoiceRouting: z
    .array(z.union([z.string().email(), PrettyName]))
    .default([]),
  defaultLoanContact: z.string().default(""), // multiline, but converted to array on submit
  prepopulateLenderId: z.string().default(""),
  features: EntityFeaturesZodSchema.default({}),
  ccAllNotifications: z
    .array(z.union([z.string().email(), PrettyName]))
    .default([]),
  logo: storedFileZodSchema.shape.storageKey.optional(),
  customNVMS: z.boolean().default(false),
});

const schemaWithOptionalNVMS = EntityBaseZodSchema.extend({
  nvms: nvmsOptionalSchema,
});

const schemaWithRequiredNVMS = EntityBaseZodSchema.extend({
  nvms: nvmsSchema,
});

type Inputs = z.infer<typeof schemaWithOptionalNVMS>;
type EntityValues = Omit<Entity, "id" | "etag" | "logo"> & {
  /// undefined means no change; null means remove it
  newLogoFile?: File | null;
};

const BorderedFigure = styled("figure")(({ theme: { spacing } }) => ({
  border: "1px solid #000000",
  borderRadius: spacing(1),
  padding: spacing(1),
}));

export const EntityEditor: (props: {
  open: boolean;
  initialValue: Readonly<Omit<Entity, "id">>;
  onClose: () => void;
  onSubmit: (values: EntityValues) => void;
  title: string;
}) => JSX.Element = ({
  open,
  initialValue,
  onClose,
  onSubmit: callerOnSubmit,
  title,
}) => {
  const { users = [] } = useAllUsers();
  const [usersByIdentity, usersByEmail /* , usersById */] = React.useMemo(
    () => [
      byProp(users)("identity"),
      byProp(users)("email"),
      // byProp(users)("id"),
    ],
    [users]
  );

  const [prettyIfYouGotIt, usersByPretty] = React.useMemo(() => {
    const pig = (email: string) =>
      (usersByEmail[email] && prettyName(usersByEmail[email])) || email;
    return [pig, Object.fromEntries(users.map((u) => [pig(u.email), u]))];
  }, [users, usersByEmail]);
  const unPretty = (maybePretty: string) =>
    usersByPretty[maybePretty]?.email || maybePretty;

  const { autoAddUserEmails = [], autoAddUserIdentities = [] } =
    initialValue || {};

  const defaultValues = React.useMemo(() => {
    return schemaWithOptionalNVMS.parse({
      ...initialValue,
      defaultLoanContact: initialValue?.defaultLoanContact?.join("\n") ?? "",
      prepopulateLenderId: initialValue?.prepopulateLenderId || "",
      logo: initialValue?.logo || undefined,
      autoAddUsers: ((autoAddUserEmails as string[]) || []).concat(
        (autoAddUserIdentities || [])
          .map(
            (identity) =>
              (identity &&
                usersByIdentity[identity] &&
                prettyName(usersByIdentity[identity])) ||
              "loading"
          )
          .map(prettyIfYouGotIt)
      ),
      customNVMS: Boolean(initialValue?.nvms),
    });
  }, [
    autoAddUserEmails,
    autoAddUserIdentities,
    initialValue,
    prettyIfYouGotIt,
    usersByIdentity,
  ]);

  // https://github.com/react-hook-form/react-hook-form/discussions/4815
  const zodNVMSRequiredResolver = zodResolver(schemaWithRequiredNVMS);
  const zodResolverIgnoringNVMS = zodResolver(EntityBaseZodSchema);
  const formMethods: UseFormReturn<Inputs> = useForm<Inputs>({
    defaultValues,
    resolver: async (data, context, options) =>
      data.customNVMS
        ? await zodNVMSRequiredResolver(data, context, options)
        : await zodResolverIgnoringNVMS(data, context, options),
    // TODO this could be much simpler with something like defaultValues: schema.parse(initialValue)
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    setValue,
  } = formMethods;
  React.useEffect(() => {
    if (open && reset) {
      reset();
    }
  }, [open, reset]);
  React.useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  /*
   * When NVMS transitions from default (null) to custom, all the NVMS subtree has nulls in it. We
   * need to fix the switches so they don't get sent back to us if untouched. (all other controls
   * require input)
   */
  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // console.log({ value, name, type });
      if (name === "customNVMS" && value["nvms"] === null) {
        setValue("nvms.sendAllItems", false);
        setValue("nvms.staticFileNumber", false);
      }
    });
    return () => subscription.unsubscribe();
  }, [setValue, watch]);

  const userEmails = users.map(({ email }) => email);

  const domains = Array.from(
    userEmails.reduce((domains, user) => {
      const domain = user.split("@").pop();
      return domain ? domains.add(domain) : domains;
    }, new Set<string>())
  ).sort();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [newLogoFile, setNewLogoFile] = React.useState<
    File | undefined | /* don't change it */ null /* delete it */
  >();
  const [logoUrl, setLogoUrl] = React.useState<string | undefined>();
  useEffect(() => {
    let stillMounted = true;
    if (newLogoFile) {
      const reader = new FileReader();
      reader.onload = () => {
        if (stillMounted && reader.result) {
          setLogoUrl(reader.result as string);
        }
      };
      reader.readAsDataURL(newLogoFile);
    } else if (newLogoFile === undefined && initialValue?.logo) {
      Storage.get(initialValue.logo).then((resolvedFile) => {
        if (stillMounted) {
          setLogoUrl(resolvedFile as string);
        }
      });
    } else {
      setLogoUrl(undefined);
    }

    return () => {
      stillMounted = false;
    };
  }, [initialValue?.logo, newLogoFile]);

  const [submitting, setSubmitting] = React.useState(false);
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    setSubmitting(true);
    try {
      const workingUserToEmail = (u: string) =>
        (usersByPretty[u] || usersByEmail[u])?.email;
      const [autoAddUserIdentities, autoAddUserEmails] =
        data.autoAddUsers.reduce(
          ([identities, emails], s) => {
            const user = usersByPretty[s] || usersByEmail[s];
            const identity = user?.identity;
            const email = user?.email ?? s;
            if (identity) {
              const verifiedIdentity = UserIdentity.safeParse(identity);
              if (verifiedIdentity.success) {
                return [identities.concat([verifiedIdentity.data]), emails];
              }
              logAnomaly(new Error("invalid identity"), { identity });
              return [identities, emails];
            }
            if (email) {
              return [identities, emails.concat([email])];
            }

            logError(`Invalid user ${s}`);
            return [identities, emails];
          },
          [[] as UserIdentity[], [] as EmailAddress[]]
        );

      const nvms = (data.customNVMS && nvmsSchema.parse(data.nvms)) || null;

      const updatedEntity: Omit<Entity, "id" | "logo"> = {
        autoAddUserEmails,
        autoAddUserIdentities,
        defaultLoanContact: data.defaultLoanContact.split("\n"),
        matchDomains: data.matchDomains,
        matchEmails: data.matchEmails.map(unPretty) as EmailAddress[],
        overrideInvoiceRouting:
          data.overrideInvoiceRouting.map(workingUserToEmail),
        prepopulateLenderId: data.prepopulateLenderId || null,
        features: data.features,
        ccAllNotifications: data.ccAllNotifications.map(
          (cc) => workingUserToEmail(cc) || cc
        ),
        nvms,
      };
      callerOnSubmit({ ...updatedEntity, newLogoFile });
    } finally {
      setSubmitting(false);
    }
  };

  const [routingEditorOpen, setRoutingEditorOpen] = React.useState(false);
  const prettyPeopleToRoutingEditorPeople = (peopleStrings: string[]) =>
    (peopleStrings
      .map((s) => {
        const u = usersByPretty[s] || usersByEmail[s];
        if (u) {
          const { firstName: first_name, lastName: last_name, ...rest } = u;
          return { ...rest, first_name, last_name };
        }
        return undefined;
      })
      .filter(Boolean) ?? []) as PersonWithEmailAndRole[];

  const autoAddUsers = watch("autoAddUsers");
  const ccAllNotifications = watch("ccAllNotifications");
  const matchDomains = watch("matchDomains");
  const matchEmails = watch("matchEmails");

  const features = watch("features");

  const lenders = React.useMemo(
    () =>
      users
        .filter(
          (user) =>
            (user.role === Role.Lender || user.role === Role.LenderAdmin) &&
            (matchEmails?.includes(user.email) ||
              matchDomains?.includes(user.email.split("@").slice(-1)[0]) ||
              autoAddUsers?.includes(prettyName(user)))
        )
        .sort((a, b) => (a.email < b.email ? -1 : a.email > b.email ? 1 : 0)),
    [autoAddUsers, matchDomains, matchEmails, users]
  );

  type BooleanFeatureFlagProps = {
    name:
      | keyof Omit<NonNullable<EntityFeatures>, "drawReportMode" | "frontend">;
  } & Pick<
    ControlledSwitchProps<Inputs["features"]>,
    "description" | "disabled"
  > & {
      onChange?: SwitchProps["onChange"];
    };

  const BooleanFeatureFlag = ({
    name,
    description,
    disabled,
    onChange,
    ...props
  }: BooleanFeatureFlagProps) => (
    <ControlledSwitch
      {...props}
      name={`features.${name}`}
      description={description}
      // defaultValue={entity?.features?.[name] ?? false}
    />
  );

  const DrawDocsSlider = (props: {
    name: keyof NonNullable<EntityFeatures["frontend"]["drawDocsRequired"]>;
    label: string;
  }) => (
    <Controller
      name={`features.frontend.drawDocsRequired.${props.name}`}
      control={control}
      // defaultValue={
      //   entity.features?.frontend?.drawDocsRequired?.[props.name] ?? 0
      // }
      render={({ field: { onChange, ...field } }) => (
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Typography>{props.label}</Typography>
          </Grid>
          <Grid item xs>
            <Slider
              {...field}
              onChange={(_, value) => {
                onChange({ target: { value } });
              }}
            />
          </Grid>
          <Grid item xs={1.5}>
            <Input
              {...field}
              size="small"
              onChange={({ target: { value } }) => {
                onChange(Number(value.replace(/\D/g, "")));
              }}
              inputProps={{
                step: 1,
                min: 0,
                max: 100,
                type: "number",
              }}
            />
          </Grid>
        </Grid>
      )}
    />
  );

  const { drawReportMode, frontend, ...booleanFeatures } = features || {};
  const numApprovalDrawDocs = frontend?.drawDocsRequired?.approval || 0;
  const numCreateDrawDocs = frontend?.drawDocsRequired?.creation || 0;

  const booleanFeatureValues = Object.values(booleanFeatures);

  return (
    <Dialog open={open}>
      <DialogTitle id="create-entity">{title}</DialogTitle>
      <DialogContent>
        <form
          id="entity-form"
          onSubmit={(...arg) => {
            handleSubmit(onSubmit, (failures /* , event */) => {
              logError(new Error("Unexpected create entity submit failure"), {
                failures,
                json: JSON.stringify(failures),
              });
            })(...arg).catch((e) => {
              logError(e);
            });
          }}
        >
          <FormProvider {...formMethods}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>
                  Membership ({matchDomains.concat(matchEmails).join(", ")})
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Paper style={{ width: "100%" }}>
                  <Grid container spacing={2} direction="column">
                    <Grid item>
                      <Controller
                        name="matchDomains"
                        control={control}
                        // defaultValue={entity?.matchDomains ?? []}
                        render={({ field: { onChange, ...rest } }) => (
                          <Autocomplete
                            {...rest}
                            onChange={(_, values) => onChange(values)}
                            multiple
                            id="domains"
                            options={domains.filter(
                              (d) => !matchDomains?.includes(d)
                            )}
                            freeSolo
                            renderTags={(value, getTagProps) =>
                              value?.map((option, index) => (
                                <Chip
                                  variant="outlined"
                                  label={option}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="filled"
                                label="Domains"
                                placeholder="Enter domain"
                                error={Boolean(errors?.matchDomains)}
                                helperText={errors?.matchDomains?.[0]?.message}
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="matchEmails"
                        control={control}
                        render={({ field: { value, onChange, ...rest } }) => (
                          <Autocomplete
                            {...rest}
                            value={value.map(prettyIfYouGotIt)}
                            onChange={(_, values) =>
                              onChange(values.map(unPretty))
                            }
                            multiple
                            id="users"
                            options={userEmails
                              .filter((name) => !matchEmails?.includes(name))
                              .map(prettyIfYouGotIt)}
                            freeSolo
                            renderTags={(value, getTagProps) =>
                              value?.map((option, index) => (
                                <Chip
                                  variant="outlined"
                                  label={option}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="filled"
                                label="Emails"
                                placeholder="Enter email"
                                error={Boolean(errors?.matchEmails)}
                                helperText={errors?.matchEmails?.[0]?.message}
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Prefills/Actions</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Paper style={{ width: "100%" }}>
                  <Grid container spacing={2} direction="column">
                    <Grid item>
                      <Paper style={{ width: "100%" }}>
                        <Controller
                          name="autoAddUsers"
                          control={control}
                          render={({ field: { onChange, ...rest } }) => (
                            <Autocomplete
                              {...rest}
                              onChange={(_, users: string[]) => {
                                onChange(users);
                              }}
                              multiple
                              id="add-users"
                              options={userEmails
                                .map(prettyIfYouGotIt)
                                .filter(
                                  (maybePrettyEmail) =>
                                    !autoAddUsers?.includes(maybePrettyEmail)
                                )}
                              freeSolo
                              renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                  <Chip
                                    variant="outlined"
                                    label={option}
                                    {...getTagProps({ index })}
                                  />
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="filled"
                                  label="Always Add To New Projects"
                                  placeholder="Enter email"
                                  error={Boolean(errors?.autoAddUsers)}
                                  helperText={
                                    errors?.autoAddUsers?.[0]?.message
                                  }
                                />
                              )}
                            />
                          )}
                        />
                      </Paper>
                    </Grid>
                    <Grid item>
                      <Paper style={{ width: "100%" }}>
                        <Controller
                          name="overrideInvoiceRouting"
                          control={control}
                          // defaultValue={
                          //   entity?.overrideInvoiceRouting?.map(
                          //     (email) =>
                          //       prettyName(usersByEmail[email]) || email
                          //   ) ?? []
                          // }
                          render={({ field: { value, onChange, ...rest } }) => (
                            <div>
                              <Tooltip title={value?.join(" ==> ")}>
                                <TextField
                                  variant="standard"
                                  style={{ textOverflow: "ellipsis" }}
                                  {...rest}
                                  id="override-routing"
                                  label="Force Draw Routing"
                                  value={value
                                    ?.map(prettyIfYouGotIt)
                                    .join(" > ")}
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="edit routing"
                                          onClick={() =>
                                            setRoutingEditorOpen(true)
                                          }
                                          size="large"
                                        >
                                          <EditIcon />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                  InputLabelProps={{ shrink: true }}
                                  fullWidth
                                  error={Boolean(
                                    errors?.overrideInvoiceRouting
                                  )}
                                  helperText={
                                    errors?.overrideInvoiceRouting?.[0]?.message
                                  }
                                />
                              </Tooltip>
                              <PeopleRoutingEditor
                                open={routingEditorOpen}
                                busy={false}
                                onClose={() => setRoutingEditorOpen(false)}
                                onSubmit={(users) => {
                                  onChange(
                                    users.map((routingEditorUser) =>
                                      prettyName(
                                        usersByEmail[routingEditorUser.email]
                                      )
                                    )
                                  );
                                  setRoutingEditorOpen(false);
                                }}
                                title={"Force Draw Routing"}
                                initialPeople={prettyPeopleToRoutingEditorPeople(
                                  value ?? []
                                )}
                                addablePeople={prettyPeopleToRoutingEditorPeople(
                                  autoAddUsers ?? []
                                )}
                                submitButtonText="Accept"
                                cancelButtonText="Cancel"
                              />
                            </div>
                          )}
                        />
                      </Paper>
                    </Grid>
                    <Grid item>
                      <Paper style={{ width: "100%" }}>
                        <Controller
                          name="defaultLoanContact"
                          // defaultValue={
                          //   entity?.defaultLoanContact?.join("\n") || ""
                          // }
                          control={control}
                          render={({ field }) => (
                            <FormControl variant="standard" fullWidth>
                              <TextField
                                variant="standard"
                                {...field}
                                fullWidth
                                multiline
                                placeholder="Text entered here
will appear at the top of Draw Reports
below the Company Name"
                                minRows={4}
                                id="loan-contact"
                                label="Default Lender Address / Contact"
                                error={Boolean(errors?.defaultLoanContact)}
                                helperText={errors?.defaultLoanContact?.message}
                              />
                            </FormControl>
                          )}
                        />
                      </Paper>
                    </Grid>
                    <Grid item>
                      <Paper style={{ width: "100%" }}>
                        <Controller
                          name="prepopulateLenderId"
                          control={control}
                          render={({ field: { value, ...field } }) => (
                            <FormControl variant="standard" fullWidth>
                              <InputLabel id="prepopulate-lender-label">
                                Prepopulate Lender
                              </InputLabel>
                              <Select
                                variant="standard"
                                value={lenders?.length > 0 ? value : ""}
                                {...field}
                                fullWidth
                                id="prepopulate-lender"
                                labelId="prepopulate-lender-label"
                                error={Boolean(errors?.prepopulateLenderId)}
                              >
                                <MenuItem value={""}>
                                  <em>(none)</em>
                                </MenuItem>
                                {lenders.map((u) => (
                                  <MenuItem value={u.id} key={u.email}>
                                    {prettyName(u)}
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText>
                                {errors?.prepopulateLenderId?.message}
                              </FormHelperText>
                            </FormControl>
                          )}
                        />
                      </Paper>
                    </Grid>
                    <Grid item>
                      <Paper style={{ width: "100%" }}>
                        <Controller
                          name="ccAllNotifications"
                          control={control}
                          // defaultValue={defaultCcAllNotifications}
                          render={(
                            { field: { onChange, ...rest } } /* TODO: once */
                          ) => (
                            <Autocomplete
                              {...rest}
                              onChange={(_, users: string[]) => {
                                onChange(users);
                              }}
                              multiple
                              id="add-users"
                              options={userEmails
                                .map(prettyIfYouGotIt)
                                .filter(
                                  (maybePrettyEmail) =>
                                    !ccAllNotifications?.includes(
                                      maybePrettyEmail
                                    )
                                )}
                              // defaultValue={defaultCcAllNotifications}
                              freeSolo
                              renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                  <Chip
                                    variant="outlined"
                                    label={option}
                                    {...getTagProps({ index })}
                                  />
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="filled"
                                  label="Cc on all interesting notifications"
                                  placeholder="Enter email"
                                  error={Boolean(errors?.ccAllNotifications)}
                                  helperText={
                                    errors?.ccAllNotifications?.[0]?.message
                                  }
                                />
                              )}
                            />
                          )}
                        />
                      </Paper>
                    </Grid>
                  </Grid>
                </Paper>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Logo</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Paper style={{ width: "100%" }}>
                  <Grid container spacing={2} direction="column">
                    <Grid item>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                        }}
                      >
                        <input
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          id="add-change-logo"
                          name="add-change-logo"
                          type="file"
                          accept="image/*"
                          onChange={() => {
                            setNewLogoFile(fileInputRef.current?.files?.[0]);
                          }}
                        />
                        <BorderedFigure>
                          <div style={{ width: 100, height: 100 }}>
                            {logoUrl ? (
                              <img
                                alt="company logo"
                                src={logoUrl}
                                style={{
                                  maxHeight: "100px",
                                  maxWidth: "100px",
                                }}
                              />
                            ) : (
                              <label htmlFor="add-change-logo">
                                <Button
                                  color="primary"
                                  variant="contained"
                                  component="span"
                                >
                                  Choose
                                </Button>
                              </label>
                            )}
                          </div>
                          <figcaption>
                            {logoUrl
                              ? newLogoFile
                                ? "Proposed Logo"
                                : initialValue?.logo
                                ? "Current Logo"
                                : "Logo will be deleted"
                              : "No logo"}
                          </figcaption>
                        </BorderedFigure>

                        <Button
                          disabled={!logoUrl}
                          color="primary"
                          variant="contained"
                          component="span"
                          onClick={() => {
                            if (fileInputRef.current) {
                              fileInputRef.current.value = "";
                            }
                            setNewLogoFile(null);
                          }}
                        >
                          Remove
                        </Button>
                        <label htmlFor="add-change-logo">
                          <Button
                            disabled={!logoUrl}
                            color="primary"
                            variant="contained"
                            component="span"
                          >
                            Change
                          </Button>
                        </label>

                        <Button
                          disabled={newLogoFile === undefined}
                          color="primary"
                          variant="contained"
                          component="span"
                          onClick={() => {
                            if (fileInputRef.current) {
                              fileInputRef.current.value = "";
                            }
                            setNewLogoFile(undefined);
                          }}
                        >
                          Reset
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>
                  Feature Flags ({booleanFeatureValues.filter(Boolean).length} /{" "}
                  {booleanFeatureValues.length}) [reports: {drawReportMode}]
                  [files: {numCreateDrawDocs},{numApprovalDrawDocs}]
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Paper style={{ width: "100%" }}>
                  <BooleanFeatureFlag
                    name="perLineItemAttachments"
                    description="Per-Line-Item Attachments (04/2022)"
                  />
                  <BooleanFeatureFlag
                    name="ccDecisionCreatorOnNotifications"
                    description="CC Decision Creator on activity emails"
                  />
                  <BooleanFeatureFlag
                    name="ccInvoiceCreatorOnNotifications"
                    description="CC Invoice Creator on activity emails"
                  />
                  <BooleanFeatureFlag
                    name="ccProjectCreatorOnNotifications"
                    description="CC Project Creator on activity emails"
                  />
                  <BooleanFeatureFlag
                    name="automatedInspections"
                    description="Offer Automated Inspector Users"
                  />
                  <BooleanFeatureFlag
                    name="digitalInspections"
                    description="Offer Digital Inspector Users"
                  />
                  <BooleanFeatureFlag
                    name="restrictInspectors"
                    description="Prevent Inspectors Creating/Modifying Draws"
                  />
                  <BooleanFeatureFlag
                    name="paidInvoiceCheckbox"
                    description="Paid Invoice Checkbox"
                  />
                  <ControlledSwitch
                    name={`features.frontend.reportingTab`}
                    description="Reporting Tab"
                    // defaultValue={
                    //   EntityFeatures.parse(entity?.features).frontend
                    //     .reportingTab
                    // }
                  />
                  <ControlledSwitch
                    name={`features.frontend.hideDecisions`}
                    description="Hide Decisions"
                    // defaultValue={
                    //   EntityFeatures.parse(entity?.features).frontend
                    //     .hideDecisions
                    // }
                  />{" "}
                  <ControlledSwitch
                    name={`features.frontend.showRetention`}
                    description="Show Retention"
                    // defaultValue={
                    //   EntityFeatures.parse(entity?.features).frontend
                    //     .hideDecisions
                    // }
                  />
                  {/* <ControlledSwitch
                    name={`features.frontend.newUI`}
                    description="New UI"
                    // defaultValue={
                    //   EntityFeatures.parse(entity?.features).frontend
                    //     .newUI
                    // }
                  /> */}
                  <Box sx={({ spacing }) => ({ paddingBottom: spacing(2) })}>
                    <DrawDocsSlider
                      name="creation"
                      label="Draw Docs for Creation"
                    />
                    <DrawDocsSlider
                      name="approval"
                      label="Draw Docs for Approval"
                    />
                  </Box>
                  <Controller
                    name="features.drawReportMode"
                    control={control}
                    // defaultValue={
                    //   entity.features?.drawReportMode ??
                    //   DrawReportMode.enum.default
                    // }
                    render={({ field: { onChange, ...field } }) => {
                      const label = "Draw Report Mode"; // this sucks: https://github.com/mui/material-ui/issues/22572#issuecomment-1270249068
                      return (
                        <FormControl fullWidth>
                          <InputLabel id="draw-report-mode-label">
                            {label}
                          </InputLabel>
                          <Select
                            {...field}
                            onChange={(event) => {
                              onChange(event);
                              setValue(
                                "features.drawReportIncludePrevious",
                                event.target.value === DrawReportMode.enum.emcap
                              );
                            }}
                            labelId="draw-report-mode-label"
                            id="draw-report-mode"
                            label={
                              `${label}X` /* also sucks I have to do this to avoid contact with the end of the text */
                            }
                          >
                            <MenuItem value={DrawReportMode.enum.default}>
                              (default)
                            </MenuItem>
                            <MenuItem value={DrawReportMode.enum.emcap}>
                              EMCAP Draw Reports (more columns, different names)
                            </MenuItem>
                            <MenuItem value={DrawReportMode.enum.capstone}>
                              Capstone: Different names
                            </MenuItem>
                          </Select>
                        </FormControl>
                      );
                    }}
                  />
                  <Controller
                    name="features.frontend.onDemandInspections"
                    control={control}
                    render={({ field: { value, onChange, ...field } }) => {
                      const selectedRoles = new Set(value);
                      const label = "On-Demand Inspections"; // this sucks: https://github.com/mui/material-ui/issues/22572#issuecomment-1270249068
                      selectedRoles.add(Role.SuperAdmin);
                      return (
                        <FormControl
                          fullWidth
                          sx={({ spacing }) => ({ marginTop: spacing(2) })}
                        >
                          <InputLabel id="on-demand-inspection-roles-label">
                            {label}
                          </InputLabel>
                          <Select
                            labelId="on-demand-inspection-roles-label"
                            id="on-demand-inspection-roles-checkbox"
                            multiple
                            {...field}
                            value={Array.from(selectedRoles)}
                            renderValue={(selected) =>
                              Array.from(selected.values()).join(", ")
                            }
                            onChange={({ target: { value: roles } }) => {
                              onChange(
                                new Set<Role>(
                                  typeof roles === "string"
                                    ? (roles.split(",") as Role[])
                                    : roles
                                )
                              );
                            }}
                            label={
                              `${label}X` /* also sucks I have to do this to avoid contact with the end of the text */
                            }
                          >
                            {Object.values(Role).map((role) => (
                              <MenuItem key={role} value={role}>
                                <Checkbox
                                  checked={
                                    role === Role.SuperAdmin ||
                                    selectedRoles.has(role)
                                  }
                                />
                                <ListItemText
                                  primary={<Typography>{role}</Typography>}
                                />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      );
                    }}
                  />
                  <Controller
                    name="features.frontend.newUI"
                    control={control}
                    render={({ field }) => {
                      const label = "New UI"; // this sucks: https://github.com/mui/material-ui/issues/22572#issuecomment-1270249068
                      return (
                        <FormControl
                          fullWidth
                          sx={({ spacing }) => ({ marginTop: spacing(2) })}
                        >
                          <InputLabel id="new-ui-label">{label}</InputLabel>
                          <Select
                            {...field}
                            labelId="new-ui-label"
                            id="new-ui-select"
                            label={
                              `${label}x` /* also sucks I have to do this to avoid contact with the end of the text */
                            }
                          >
                            <MenuItem value={"on"}>
                              <Tooltip title="Show toggle, forced on">
                                <Typography>On</Typography>
                              </Tooltip>
                            </MenuItem>
                            <MenuItem value={"off"}>
                              <Tooltip title="Show toggle, forced off">
                                <Typography>Off</Typography>
                              </Tooltip>
                            </MenuItem>
                            <MenuItem value={"offer"}>
                              <Tooltip title="Show toggle, user changeable">
                                <Typography>User Can Choose</Typography>
                              </Tooltip>
                            </MenuItem>
                            <MenuItem value={"no-offer"}>
                              <Tooltip title="Don't show it to the user, but use whatever they have in settings. So It might be on but unavailable; this is different from being forced on for the whole entity">
                                <Typography>Invisible</Typography>
                              </Tooltip>
                            </MenuItem>
                          </Select>
                        </FormControl>
                      );
                    }}
                  />
                </Paper>
              </AccordionDetails>
            </Accordion>
            <NVMSConfigEditor
              entity={initialValue}
              baseNVMSSetup={initialValue?.nvms}
            />
          </FormProvider>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        {submitting ? (
          <CircularProgress />
        ) : (
          <Button
            type="submit"
            form="entity-form"
            color="primary"
            disabled={submitting}
          >
            Submit
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
