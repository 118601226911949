import React from "react";
import { styled } from "@mui/material/styles";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import {
  ApprovalStatus,
  BackendGetProjectItemsDecisionsResponseEntry,
  PartiallyModernizedGetProjectDetailsResponse,
} from "@lib/APITypes";
import { usePermissions } from "@lib/hooks";
import { DecisionCard } from "./DecisionCard";
import { ImageGallery } from "../../../Components/ImageGallery";
import {
  StoredFile,
  LinkedFile,
} from "@project-centerline/project-centerline-api-types";

const PREFIX = "DisplayDecisions";

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled("div")(({ theme: { palette } }) => ({
  [`&.${classes.root}`]: {
    // margin: "20px",
    marginBottom: "10px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
}));

export type Decision = BackendGetProjectItemsDecisionsResponseEntry;

export default function DisplayDecisions(props: {
  data: Readonly<Decision[]>;
  onApproveDecision: (decisionId: string, approval: ApprovalStatus) => void;
  onDeleteDecision: (decisionId: string) => void;
  callBack?: () => void;
  project: Readonly<
    Pick<PartiallyModernizedGetProjectDetailsResponse, "project_id" | "name">
  >;
}): JSX.Element {
  const { canSeeDecision } = usePermissions();
  const {
    data: decisions,
    onApproveDecision,
    onDeleteDecision,
    project,
  } = props;
  const [decisionToDelete, setDecisionToDelete] = React.useState<
    Decision | undefined
  >();

  const closeDeleteDialog = () => setDecisionToDelete(undefined);

  // TODO: refactor, consolidate, don't dupe
  const [lightboxFiles, setLightboxFiles] = React.useState<
    (StoredFile | LinkedFile)[]
  >([]);
  const [lightboxOpen, setLightboxOpen] = React.useState(false);
  const handleShowFiles = (files: (StoredFile | LinkedFile)[]) => {
    setLightboxFiles(files);
    setLightboxOpen(true);
  };

  return (
    <Root className={classes.root}>
      {decisions.length === 0 ? <p>No Current Decisions</p> : null}
      {decisions
        .filter(({ inspector }) => canSeeDecision({ inspector }))
        .map((decision) => (
          <div key={decision.decision_id}>
            <DecisionCard
              decision={decision}
              onDeleteRequest={() => setDecisionToDelete(decision)}
              onApproval={(approval: ApprovalStatus) =>
                onApproveDecision(decision.decision_id, approval)
              }
              onShowFiles={handleShowFiles}
            />
          </div>
        ))}

      <Dialog
        open={Boolean(decisionToDelete)}
        onClose={closeDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete this decision?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={closeDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              decisionToDelete &&
                onDeleteDecision(decisionToDelete.decision_id);
              closeDeleteDialog();
            }}
            color="primary"
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      {lightboxOpen ? (
        <ImageGallery
          files={lightboxFiles}
          open={lightboxOpen}
          onClose={() => setLightboxOpen(false)}
          downloadAllFilename={`${project.name} - decision files.zip`}
        />
      ) : null}
    </Root>
  );
}
