import { authExchange } from "@urql/exchange-auth";
import { Auth } from "aws-amplify";
import { Effect } from "effect";

const errorsBecomeUndefined = Effect.catchAll(() => Effect.succeed(undefined));

const fetchSession = Effect.tryPromise(() => Auth.currentSession()).pipe(
  errorsBecomeUndefined
);
const fetchCredentials = Effect.tryPromise(Auth.currentCredentials).pipe(
  errorsBecomeUndefined
);
const fetchUserCredentials = Effect.tryPromise(
  Auth.currentUserCredentials
).pipe(errorsBecomeUndefined);

// const fetchUser = Effect.tryPromise(() => Auth.currentAuthenticatedUser() as Promise<CognitoUser>);

async function initializeAuthState() {
  return {
    session: Effect.runPromise(fetchSession),
    // user: Effect.runSync(fetchUser),
    credentials: await Effect.runPromise(fetchCredentials),
    userCredentials: await Effect.runPromise(fetchUserCredentials),
  };
}

// amplify v5
export const amplifyAuth = authExchange(async (utils) => {
  const { credentials, userCredentials, session } = await initializeAuthState();

  const ses = await session;

  return {
    addAuthToOperation(operation) {
      if (!credentials || !userCredentials || !session) {
        // not logged in
        return operation;
      }

      return utils.appendHeaders(operation, {
        // Authorization: `Bearer ${ses?.getAccessToken().getJwtToken()}`,
        Authorization: `Bearer ${ses?.getAccessToken().getJwtToken()}`,
        // "X-Identity": userCredentials.identityId,
        "X-Identity": ses?.getIdToken().getJwtToken() || "INVALID",
      });
    },

    willAuthError() {
      return !credentials;
    },

    didAuthError(error /* , operation */) {
      return (
        error.response?.status === 403 ||
        error.graphQLErrors.some((err) =>
          /FORBIDDEN|Unauthorized/.test(err.extensions?.code as string)
        )
      );
    },

    async refreshAuth() {
      initializeAuthState();
    },
  };
});

// v6
// async function initializeAuthState() {
//   const { tokens: { accessToken = undefined, idToken = undefined } = {} } =
//     (await fetchAuthSession()) ?? {};
//   return { accessToken, idToken };
// }

// export const amplifyAuth = authExchange(async (utils) => {
//   let { accessToken, idToken } = await initializeAuthState();

//   return {
//     addAuthToOperation(operation) {
//       if (!accessToken || !idToken) {
//         // not logged in
//         return operation;
//       }

//       return utils.appendHeaders(operation, {
//         Authorization: `Bearer ${accessToken.toString()}`,
//         "X-Identity": idToken?.toString(),
//       });
//     },

//     willAuthError() {
//       return !!accessToken;
//     },

//     didAuthError(error /* , operation */) {
//       return (
//         error.response?.status === 403 ||
//         error.graphQLErrors.some((err) =>
//           /FORBIDDEN|Unauthorized/.test(err.extensions?.code as string)
//         )
//       );
//     },

//     async refreshAuth() {
//       await fetchAuthSession();
//       return /* void */;
//     },
//   };
// });
