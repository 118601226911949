import { Grid, TextFieldVariants } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { ifEnterKey, IfEnterKeyEventHandler } from "@lib/boilerplate";
import { useFocus } from "@lib/hooks";
import React, { MutableRefObject } from "react";
import { multiRef } from "@lib/misc";
import { z } from "zod";
import { Wrapper } from "@googlemaps/react-wrapper";
import {
  ControlledTextFieldProps,
  useControlledTextField,
} from "@/Components/ControlledTextField";
import {
  useGoogleAutoComplete,
  renderGoogleNotReady,
  mapsApiKey,
} from "./googleAutocomplete";

export const ZodSchema = z.object({
  address: z.string().min(1),
  city: z.string().min(1),
  state: z.string().min(1),
  zipcode: z.string().min(1),
  unit: z.string().nullable(),
});
export type AddressInputs = z.infer<typeof ZodSchema>;

export type AddressFormProps = {
  onLastFieldEntered?: IfEnterKeyEventHandler;
  addressRef?: MutableRefObject<HTMLInputElement>;
  expanded?: boolean;
  variant?: TextFieldVariants;
};

/**
 * Component for a magic google auto-completing address form.
 * @param arg.onLastFieldEntered In case you want to do something on last enter key
 * @param arg.addressRef A ref you can jump focus to
 * @returns a (set of fields for a) self-contained mini form
 */
export function AddressForm({
  onLastFieldEntered,
  addressRef,
  expanded,
  // future expansion: don't build it til we need ti but this is how I picture doing an "address-only":
  // /** Hide unit number */
  // addressOnly = false,
  variant = "standard",
}: AddressFormProps) {
  const TextControl = useControlledTextField(ZodSchema);
  const MyTextField = React.useCallback(
    (props: ControlledTextFieldProps<typeof ZodSchema>) => (
      <TextControl variant={variant} {...props} />
    ),
    [TextControl, variant]
  );

  const [unitRef, focusUnit] = useFocus();
  const [cityRef, focusCity] = useFocus();
  const [stateRef, focusState] = useFocus();
  const [zipRef, focusZip] = useFocus();

  const addressEntryRef = React.useRef<HTMLInputElement>();
  useGoogleAutoComplete({ addressEntryRef, onLastFieldEntered: focusUnit });

  const { watch } = useFormContext<z.infer<typeof ZodSchema>>();

  return (
    <Grid container direction="column">
      <Grid item container direction="row" spacing={2}>
        <Grid item xs={10}>
          <Wrapper
            render={renderGoogleNotReady}
            apiKey={mapsApiKey}
            version="weekly"
            libraries={["places"]}
            // callback={setMapsLibraryStatus}
          >
            <MyTextField
              name="address"
              label="Project Address"
              variant={variant}
              fieldProps={(field) => ({
                inputRef: multiRef([field.ref, addressRef, addressEntryRef]),
              })}
            />
          </Wrapper>
        </Grid>
        <Grid item xs={2}>
          <MyTextField
            name="unit"
            label="Unit #"
            fieldProps={(field) => ({
              inputRef: multiRef([field.ref, unitRef]),
            })}
            onKeyDown={ifEnterKey(focusCity)}
            InputLabelProps={{ shrink: !!watch("unit") }}
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        spacing={2}
        direction={expanded !== undefined ? "column" : "row"}
        mt={0}
      >
        <Grid item>
          <MyTextField
            name="city"
            label="City"
            fieldProps={(field) => ({
              inputRef: multiRef([field.ref, cityRef]),
            })}
            onKeyDown={ifEnterKey(focusState)}
          />
        </Grid>
        <Grid item>
          <MyTextField
            name="state"
            label="State"
            fieldProps={(field) => ({
              inputRef: multiRef([field.ref, stateRef]),
            })}
            onKeyDown={ifEnterKey(focusZip)}
          />
        </Grid>
        <Grid item>
          <MyTextField
            name="zipcode"
            label="Zip Code"
            fieldProps={(field) => ({
              inputRef: multiRef([field.ref, zipRef]),
            })}
            onKeyDown={
              onLastFieldEntered ? ifEnterKey(onLastFieldEntered) : undefined
            }
          />
        </Grid>
        <Grid item /* spacer */ />
      </Grid>
    </Grid>
  );
}

//spell-checker:ignore googlemaps
