import { fullAddress, projectDescription } from "@/Lib/util";
import { Autocomplete, TextField, TextFieldProps } from "@mui/material";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { createFilterOptions } from "@mui/material/Autocomplete";
import React from "react";
import { Project2024, ProjectId2024 } from "@/Lib/types";

export type Project = Project2024;

const CREATE_NEW = Symbol("enter new address");
// eslint-disable-next-line @typescript-eslint/no-redeclare
type CREATE_NEW = typeof CREATE_NEW;

const projectOption = (includeAddress: boolean) => (project: Project) =>
  ({
    label: includeAddress ? projectDescription(project) : project.name,
    project,
  } as {
    label: string;
    project: Project | CREATE_NEW;
  });

const filterOptions = createFilterOptions({
  stringify: (option: ReturnType<ReturnType<typeof projectOption>>) =>
    `${option.label}:${
      option.project === CREATE_NEW ? "" : fullAddress(option.project)
    }`,
});

export type ProjectPickerProps = {
  projects: ReadonlyArray<Project2024>;
  defaultProject?: ProjectId2024;
  onSelectProject: (p: Project) => unknown;
  createNew?: {
    onCreateNew: () => unknown;
    prompt?: string;
  };
  nameOnly?: boolean;
} & Pick<TextFieldProps, "label">;

export const ProjectPicker = ({
  projects,
  onSelectProject,
  createNew,
  label = "Select Project",
  nameOnly = false,
  defaultProject,
  ...textFieldProps
}: ProjectPickerProps) => {
  const options = React.useMemo(
    () =>
      projects.map(projectOption(nameOnly === undefined || !nameOnly)).concat(
        createNew
          ? {
              label: createNew.prompt || "Enter address...",
              project: CREATE_NEW,
            }
          : []
      ),
    [createNew, nameOnly, projects]
  );

  const [value, setValue] = React.useState(
    options.find(
      ({ project }) => project !== CREATE_NEW && project.id === defaultProject
    ) ?? ""
  );

  return (
    <Autocomplete
      value={value}
      onChange={(_event, newValue) => {
        if (newValue) {
          if (typeof newValue === "object") {
            if (newValue.project === CREATE_NEW) {
              createNew?.onCreateNew();
            } else {
              onSelectProject(newValue.project);
            }
            setValue(newValue);
          }
        }
      }}
      options={options}
      renderInput={(params) => (
        <TextField {...params} label={label} {...textFieldProps} />
      )}
      renderOption={(props, option, { inputValue }) => {
        const matches = match(option.label, inputValue, { insideWords: true });
        const parts = parse(option.label, matches);

        return (
          <li
            {...props}
            key={option.project === CREATE_NEW ? "new" : option.project.id}
          >
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                  }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          </li>
        );
      }}
      filterOptions={filterOptions}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      freeSolo
    />
  );
};

export default ProjectPicker;
