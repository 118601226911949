import { Client, fetchExchange } from "urql";
// import { cacheExchange, KeyGenerator } from "@urql/exchange-graphcache";
// import { relayPagination } from "@urql/exchange-graphcache/extras";
import { amplifyAuth } from "./amplifyAuth";
import config from "../config";
import { devtoolsExchange } from "@urql/devtools";
import { refocusExchange } from "@urql/exchange-refocus";

// cache disabled for now because then listProjects always comes back null
// const cache = cacheExchange({
//   resolvers: {
//     Query: {
//       projects: relayPagination(),
//     },
//     // Or if the pagination happens in a nested field:
//     User: {
//       projects: relayPagination(),
//     },
//   },
//   keys: {
//     LegacyProject: ({
//       project_id,
//     }: Parameters<KeyGenerator>[0] & { project_id: string }) => project_id,
//   },
// });

export const client = () =>
  new Client({
    url: config.GraphQL.endpoint,
    // url: "http://localhost:4000/staging/graphql",
    exchanges: [
      devtoolsExchange,
      refocusExchange(),
      /* cache, */ amplifyAuth,
      fetchExchange,
    ],
    requestPolicy: "cache-and-network",
  });

// spell-checker:ignore graphcache
