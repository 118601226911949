import React from "react";
import { styled } from "@mui/material/styles";
import { useAppContext } from "@lib/UserContext";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import LinearProgress from "@mui/material/LinearProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Popover from "@mui/material/Popover";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import List from "@mui/material/List";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { useMoney } from "@lib/hooks/useMoney";
import {
  BackendGetProjectsResponse,
  NotificationTypeEnum,
} from "@lib/APITypes";
import { useBackend, usePermissions } from "@lib/hooks";
import { Grid, Paper, Fab, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { StealthyLink } from "../../Components/StealthyLink";
import { logError } from "@lib/ErrorLogging";
import { throwIfNot } from "@lib/util/throwIfNot";
import { useAuth } from "@lib/hooks/useAuth";

const PREFIX = "ProjectCards";

const classes = {
  root: `${PREFIX}-root`,
  colorPrimary: `${PREFIX}-colorPrimary`,
  bar: `${PREFIX}-bar`,
  root2: `${PREFIX}-root2`,
  wrapper: `${PREFIX}-wrapper`,
  media: `${PREFIX}-media`,
  avatar: `${PREFIX}-avatar`,
  cardContent: `${PREFIX}-cardContent`,
  cta: `${PREFIX}-cta`,
};

const Root = styled("div")(({ theme: { palette } }) => ({
  [`& .${classes.root2}`]: {
    margin: 0,
  },

  [`&.${classes.wrapper}`]: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    width: "100%",
  },

  [`& .${classes.media}`]: {
    height: 0,
    paddingTop: "56.25%",
  },

  [`& .${classes.avatar}`]: {
    backgroundColor: palette.primary.main,
  },

  [`& .${classes.cardContent}`]: {
    textAlign: "center",
    margin: "5px",
  },

  [`& .${classes.cta}`]: {
    display: "flex",
    justifyContent: "center",
  },
}));

// import { HealthIndicator } from "../../Components/HealthIndicator";

const BorderLinearProgress = LinearProgress;

function formatDaysRemaining(endDate: Date, today: Date) {
  const nDays = Math.round(
    (endDate.getTime() - today.getTime()) / (1000 * 3600 * 24)
  );
  if (nDays < 0) {
    return `OVERDUE by ${Math.abs(nDays)} days`;
  } else {
    return `${nDays} days remaining`;
  }
}

export default function ProjectCards(props: {
  callBack: () => void;
  data: BackendGetProjectsResponse;
  onRequestDelete: (projectId: string) => void;
}): JSX.Element {
  const { formatMoney } = useMoney();

  const { role, notifications } = useAppContext();
  const email = throwIfNot(useAuth().currentUserEmail, "email is required");
  const [open, setOpen] = React.useState(false);
  const today = new Date();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [popoverId, setPopoverId] = React.useState<string | null>(null);
  const [projectToLeave, setProjectToLeave] = React.useState("");
  const { canCreateProjects } = usePermissions();
  const { leaveProject } = useBackend();

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleDialogOpen = (projectId: string) => {
    setOpen(true);
    setProjectToLeave(projectId);
  };

  const handleLeave = () => {
    leaveProject({ email, role, projectId: projectToLeave })
      .then((response) => {
        console.log(response);
        setOpen(false);
        props.callBack();
      })
      .catch((error) => {
        logError(error);
        console.log(error);
      });
  };

  const handlePopover = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    popoverId: string
  ) => {
    setPopoverId(popoverId);
    setAnchorEl(event.target as HTMLElement);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setPopoverId(null);
  };

  //const openPopover = Boolean(anchorEl);
  //const id = openPopover ? "simple-popover" : undefined;

  return (
    <Root className={classes.wrapper}>
      {props.data.map((project, key) => {
        const endDate = new Date(project.end_date);
        return (
          <div key={key}>
            <Card className="project-card">
              {/* <Badge
                badgeContent={
                  <HealthIndicator
                    projectId={project.project_id}
                    health={project.health}
                  />
                }
                // overlap="circle"
              > */}
              <CardHeader
                avatar={
                  <Avatar
                    aria-label="tasks"
                    className={classes.avatar}
                    onClick={(e) => handlePopover(e, project.project_id)}
                    style={
                      notifications.filter(
                        (item) => item.project_id === project.project_id
                      ).length > 0
                        ? { backgroundColor: "orange", cursor: "pointer" }
                        : { cursor: "pointer" }
                    }
                  >
                    {
                      notifications.filter(
                        (item) => item.project_id === project.project_id
                      ).length
                    }
                  </Avatar>
                }
                title={project.address}
                subheader={`${project.city}, ${project.state}`}
              />
              {/* </Badge> */}

              <Popover
                //id={id}
                open={popoverId === project.project_id}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <List className={classes.root}>
                  {notifications
                    .filter(
                      (notification) =>
                        notification.project_id === project.project_id
                    )
                    .map((notification, key) => (
                      <ListItem key={key}>
                        <Link
                          style={{
                            textDecoration: "none",
                          }}
                          to={`projectview/${project.project_id}`}
                          state={{
                            value: `${notification.type}`,
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <ListItemAvatar>
                              <Avatar>
                                {notification.type === "Task" ? (
                                  <AssignmentTurnedInIcon />
                                ) : null}
                                {notification.type === "Decision" ? (
                                  <DateRangeIcon />
                                ) : null}
                                {notification.type === "Draw Request" ? (
                                  <MonetizationOnIcon />
                                ) : null}
                                {notification.type ===
                                NotificationTypeEnum.Info ? ( // TODO: This was "User", but there is no such notification type. Did I guess right?
                                  <FindInPageIcon />
                                ) : null}
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={notification.type}
                              secondary={notification.message}
                            />
                          </div>
                        </Link>
                      </ListItem>
                    ))}
                </List>
              </Popover>
              <StealthyLink to={`/projectview/${project.project_id}`}>
                <CardMedia
                  className={classes.media}
                  image={project.img}
                  title="Location Image"
                />

                <CardContent>
                  <p className={classes.cardContent}>
                    {`${project.tasks_complete.length}/${project.all_tasks.length} TASKS COMPLETED`}
                  </p>
                  <Divider />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p color="textSecondary">
                      {project.start_date.split("T").shift()}
                    </p>
                    <p>{project.end_date.split("T").shift()}</p>{" "}
                  </div>
                  <BorderLinearProgress
                    variant="determinate"
                    value={Math.round(
                      (Math.abs(
                        today.getTime() - new Date(project.start_date).getTime()
                      ) /
                        Math.abs(
                          new Date(project.end_date).getTime() -
                            new Date(project.start_date).getTime()
                        )) *
                        100
                    )}
                    classes={{
                      root: classes.root,
                      colorPrimary: classes.colorPrimary,
                      bar: classes.bar,
                    }}
                  />{" "}
                  <p className={classes.cardContent}>
                    {formatDaysRemaining(endDate, today)}
                  </p>
                  <p>{`${
                    isNaN(project.loanAmount ?? Number.NaN)
                      ? "Total Task Value"
                      : "Loan"
                  }: ${formatMoney(
                    Math.abs(project.effectiveBudget)
                  )}`}</p>{" "}
                  <Divider />
                  <BorderLinearProgress
                    variant="determinate"
                    value={Math.round(
                      ((project.effectiveBudget - project.budgetRemaining) /
                        project.effectiveBudget) *
                        100
                    )}
                    classes={{
                      root: classes.root,
                      colorPrimary: classes.colorPrimary,
                      bar: classes.bar,
                    }}
                  />
                  <p className={classes.cardContent}>{`${formatMoney(
                    Math.abs(project.budgetRemaining)
                  )} remaining`}</p>
                  <Divider />
                </CardContent>
              </StealthyLink>
              <CardActions className={classes.cta}>
                {role === "Super Admin" ? (
                  <Button
                    color="secondary"
                    aria-label="share"
                    onClick={() => props.onRequestDelete?.(project.project_id)}
                  >
                    Delete
                  </Button>
                ) : null}
                {role !== "Super Admin" ? (
                  <Button
                    color="secondary"
                    aria-label="share"
                    onClick={(e) => handleDialogOpen(project.project_id)}
                  >
                    Leave
                  </Button>
                ) : null}
              </CardActions>
            </Card>
            <Dialog
              open={open}
              onClose={handleDialogClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Leave this Project?"}
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleDialogClose} color="primary">
                  Cancel
                </Button>
                <Button color="primary" autoFocus onClick={handleLeave}>
                  Agree
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        );
      })}
      {canCreateProjects ? (
        <Paper className="project-card">
          <Grid
            container
            direction="column"
            alignContent="center"
            alignItems="center"
            style={{ height: "100%" }}
            justifyContent="center"
          >
            <Grid item>
              <Link to="/quicksetup">
                <Fab color="primary" aria-label="add">
                  <AddIcon />
                </Fab>
              </Link>
            </Grid>
            <Grid item>
              <Typography variant="h4">Create Project</Typography>
            </Grid>
          </Grid>
        </Paper>
      ) : null}
    </Root>
  );
}
