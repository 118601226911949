import React from "react";
import { useAuth } from "./Lib/hooks/useAuth";
import { home } from "./Routes";
import DashboardNavbar from "@tim/examples/Navbars/DashboardNavbar";

export const DashboardNavbarWithUser = () => {
  const { currentUserEmail } = useAuth();

  return (
    <>
      {/* <div id="prefetch" style={{ display: "none" }}>
              <NotificationsMenu open={false} />
            </div> */}
      <DashboardNavbar
        userName={currentUserEmail}
        userProfileRoute={"/profile"}
        home={home}
      />
    </>
  );
};
