/**
 * WHat we need to know about a Task. // TODO: We could probably use BackendGetTaskResponse instead
 */
import * as t from "io-ts";
import * as tt from "io-ts-types";
import {
  Task as APITask,
  TaskId,
  ProjectId,
} from "@project-centerline/project-centerline-api-types";
import { DateFromDateOrISO, maybe } from "@lib/util/typeUtils";
import { BackendGetTaskResponse } from "@lib/APITypes";
import { MustSatisfy } from "@lib/misc";
import { fromNullable } from "io-ts-types";

// removing some things from APITask we don't want to carry through, e.g. for rename like task_id => id
const { task_id, ...wireTaskProps } = APITask.props;

// export interface TaskIdBrand {
//     readonly TaskId: unique symbol;
// }
// export declare const TaskId: t.BrandC<t.BrandC<t.StringC, tt.UUIDBrand>, TaskIdBrand>;
// export type TaskId = t.TypeOf<typeof TaskId>;

/**
 * A task, as we like to think about it. Close to the wire version from backend but with a little bit of type massaging
 */
export const Task = t.type({
  ...wireTaskProps,
  id: TaskId,
  title: tt.NonEmptyString,
  description: t.string,
  task_value: t.union([tt.NumberFromString, t.number]),
  requested_amount: t.number,
  invoiced_amount: t.number,
  lender_only: t.boolean,
  completed: t.boolean,
  created_at: t.string, // TODO: make a real date out of it?
  start: tt.date,
  end_date: maybe(tt.date),
  critical: fromNullable(t.boolean, false),
  taskvacating: t.boolean,
  taskvacating_start: maybe(DateFromDateOrISO),
  taskvacating_end: maybe(DateFromDateOrISO),
  task_subcontractor: t.string,
  division: fromNullable(t.string, ""),
  subdivision: fromNullable(t.string, ""),
  weekend: fromNullable(t.boolean, false),
});
/* ,  t.partial({
    description: t.string,
  }),
]); */

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Task = t.TypeOf<typeof Task>;

// this shows what I upgraded going from BackendGetTaskResponse to Task in 2023: ( spell-checker: word bgtr )
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type bgtrToTaskUpgradesCheck = MustSatisfy<
  Task,
  BackendGetTaskResponse & {
    title: tt.NonEmptyString;
    id: TaskId;
    taskvacating_start: Date;
    taskvacating_end: Date;
    project_id: ProjectId;
  }
>;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type _sanity_gotEverythingBGTRHad = MustSatisfy<
  Omit<
    BackendGetTaskResponse,
    | "taskvacating_start"
    | "taskvacating_end"
    | "division"
    | "subdivision"
    | "weekend"
    | "task_id"
  > & {
    taskvacating_start: Date | null;
    taskvacating_end: Date | null;
    division: string | null;
    subdivision: string | null;
    weekend: boolean | null;
  },
  Omit<Task, "id"> & { task_id: string }
>;
