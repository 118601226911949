import { Storage } from "aws-amplify";
import ForkedLightGallery from "./ForkedLightGallery";
import { LightGallery as LightGalleryClass } from "lightgallery/lightgallery";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import React from "react";
import { logAndThrow, logAnomaly, logError } from "@lib/ErrorLogging";
import { Box, IconButton } from "@mui/material";
import { isTempFile, TempFile } from "@lib/hooks/useIdb";
import { AfterSlideDetail, InitDetail } from "lightgallery/lg-events";
import { useLocation, useNavigate, useNavigationType } from "react-router-dom";
import { isIOS, isMobile } from "react-device-detect";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { GalleryItem } from "lightgallery/lg-utils";
import {
  StoredFile,
  LinkedFile,
  PathAndKey,
} from "@project-centerline/project-centerline-api-types";
import { enqueueSnackbar } from "notistack";
import { createArchive } from "@/Lib/API";
import { pipe } from "fp-ts/lib/function";
import * as TE from "fp-ts/lib/TaskEither";
import DownloadingArchiveDialog from "./DownloadingArchiveDialog";

const knownIframeWhitelist = ["pdf"];
const knownGoodImageFormats = ["jpg", "jpeg", "gif", "png", "svg", "webp"];
const knownPlaceholderRequired = ["heic"];
const knownGoogleViewerFormats = ["doc", "docx", "xls", "xlsx", "ai"];
const thumbnailIcons: Record<string, string> = {
  pdf: "https://dji3i3u4pk2dv.cloudfront.net/eyJidWNrZXQiOiJjZW50ZXJsaW5lLWJhY2tlbmQtYXdzLWRlLXByb2plY3RjZW50ZXJsaW5lYnVja2V0LTF3cXo1aDRrNHQyY3QiLCJrZXkiOiJQREZfZmlsZV9pY29uLnN2Zy5wbmciLCJlZGl0cyI6eyJyZXNpemUiOnsid2lkdGgiOjEwMCwiaGVpZ2h0Ijo4MCwiZml0IjoiY29udGFpbiJ9fX0=",
  doc: "https://dji3i3u4pk2dv.cloudfront.net/eyJidWNrZXQiOiJjZW50ZXJsaW5lLWJhY2tlbmQtYXdzLWRlLXByb2plY3RjZW50ZXJsaW5lYnVja2V0LTF3cXo1aDRrNHQyY3QiLCJrZXkiOiJkb2NfaWNvbi5wbmciLCJlZGl0cyI6eyJyZXNpemUiOnsid2lkdGgiOjEwMCwiaGVpZ2h0Ijo4MCwiZml0IjoiY29udGFpbiJ9fX0=",
  docx: "https://dji3i3u4pk2dv.cloudfront.net/eyJidWNrZXQiOiJjZW50ZXJsaW5lLWJhY2tlbmQtYXdzLWRlLXByb2plY3RjZW50ZXJsaW5lYnVja2V0LTF3cXo1aDRrNHQyY3QiLCJrZXkiOiJkb2N4X2ljb24uc3ZnLnBuZyIsImVkaXRzIjp7InJlc2l6ZSI6eyJ3aWR0aCI6MTAwLCJoZWlnaHQiOjgwLCJmaXQiOiJjb250YWluIn19fQ==",
  xls: "https://dji3i3u4pk2dv.cloudfront.net/eyJidWNrZXQiOiJjZW50ZXJsaW5lLWJhY2tlbmQtYXdzLWRlLXByb2plY3RjZW50ZXJsaW5lYnVja2V0LTF3cXo1aDRrNHQyY3QiLCJrZXkiOiJYbHNfaWNvbl8oMjAwMC0wMykuc3ZnLnBuZyIsImVkaXRzIjp7InJlc2l6ZSI6eyJ3aWR0aCI6MTAwLCJoZWlnaHQiOjgwLCJmaXQiOiJjb250YWluIn19fQ==",
  xlsx: "https://dji3i3u4pk2dv.cloudfront.net/eyJidWNrZXQiOiJjZW50ZXJsaW5lLWJhY2tlbmQtYXdzLWRlLXByb2plY3RjZW50ZXJsaW5lYnVja2V0LTF3cXo1aDRrNHQyY3QiLCJrZXkiOiJ4bHN4X2ljb24uc3ZnLnBuZyIsImVkaXRzIjp7InJlc2l6ZSI6eyJ3aWR0aCI6MTAwLCJoZWlnaHQiOjgwLCJmaXQiOiJjb250YWluIn19fQ==",
  ai: "https://dji3i3u4pk2dv.cloudfront.net/eyJidWNrZXQiOiJjZW50ZXJsaW5lLWJhY2tlbmQtYXdzLWRlLXByb2plY3RjZW50ZXJsaW5lYnVja2V0LTF3cXo1aDRrNHQyY3QiLCJrZXkiOiJBZG9iZV9JbGx1c3RyYXRvcl9pY29uLnBuZyIsImVkaXRzIjp7InJlc2l6ZSI6eyJ3aWR0aCI6MTAwLCJoZWlnaHQiOjgwLCJmaXQiOiJjb250YWluIn19fQ==",
};

function displayStrategyHeuristic(
  s: string
): "image" | "iframe" | "placeholder" | "google" {
  const lc = s.toLowerCase();
  if (lc.startsWith("blob:")) {
    return "image";
  }

  const ext = extractExtension(lc);
  if (knownGoodImageFormats.includes(ext)) {
    return "image";
  }

  if (knownIframeWhitelist.includes(ext)) {
    return "iframe";
  }

  if (knownGoogleViewerFormats.includes(ext)) {
    return "google";
  }

  if (!knownPlaceholderRequired.includes(ext)) {
    logAnomaly(new Error(`Had to placeholder image: ${ext}`), { s });
  }
  return "placeholder";
}

type AcceptedFileTypes = StoredFile | LinkedFile | TempFile;

function extractExtension(lc: string) {
  return lc.slice(lc.lastIndexOf(".") + 1).match(/([a-zA-Z])+/)?.[0] ?? "";
}

/**
 * Resolve a (temp or linked or stored) file to something lightgallery can display
 *
 * @returns Promise<GalleryItem>
 */
async function resolveFile<
  FileT extends AcceptedFileTypes & {
    leftAdornments?: HTMLElement[];
  }
>(
  f: FileT,
  { onDelete }: { onDelete?: ({ index }: { index: number }) => Promise<void> }
): Promise<GalleryItem> {
  const { leftAdornments } = f;
  if (isTempFile(f)) {
    return new Promise<GalleryItem>((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        const fileBits = reader.result;
        return typeof fileBits === "string"
          ? resolve({
              src: fileBits,
              thumb: fileBits,
              download: f.file.name,
              subHtml: f.file.name,
              leftAdornments,
              onDelete,
            })
          : reject(new Error("read error"));
      });
      reader.addEventListener("error", reject);

      reader.readAsDataURL(f.file);
    });
  } else {
    const storedFileInfo = f; // f.destination;
    const [url, thumb, title, keyOrUrl] =
      (LinkedFile.is(f) && [f.href, f.href, f.title, f.href]) ||
      (StoredFile.is(storedFileInfo) && [
        (await Storage.get(storedFileInfo.storageKey, {
          contentDisposition: "inline",
        })) as string,
        // new URL("/description_white_24dp.svg", window.location.href).toString(),
        (await Storage.get(storedFileInfo.storageKey, {
          contentDisposition: "inline",
        })) as string,
        storedFileInfo.path,
        storedFileInfo.storageKey,
      ]) ||
      (() => {
        const error = new Error("Don't know how to handle this file");
        logError(error, { f, storedFileInfo });
        throw error;
      })();

    const strategy = displayStrategyHeuristic(keyOrUrl);
    const humanName = keyOrUrl.slice(keyOrUrl.lastIndexOf("/") + 1);
    const iframe = strategy === "iframe";
    const google = strategy === "google";
    const ext = extractExtension(keyOrUrl.toLowerCase());
    const googleViewer = `https://docs.google.com/gview?embedded=true&url=${encodeURIComponent(
      url
    )}`;

    const placeholder = strategy === "placeholder";
    const item: GalleryItem = {
      src:
        (iframe || google) && isMobile
          ? googleViewer
          : placeholder
          ? thumb
          : (google && googleViewer) || url,
      downloadUrl: url,
      download: humanName,
      thumb:
        (iframe || google || placeholder) &&
        !isIOS /* iOS is able to do reasonable thumbnails even though it can't do interactive iframe */
          ? thumbnailIcons[ext] ??
            new URL(
              "/description_white_24dp.svg",
              window.location.href
            ).toString()
          : thumb,
      iframe: iframe || google,
      leftAdornments,
      alt: title,
      subHtml: title,
      ...(iframe && {
        iframeTitle: title,
      }),
      title,
      onDelete,
    };

    return item;
  }
}

const lgPlugins = [lgThumbnail, lgZoom];
export function ImageGallery<
  FileT extends AcceptedFileTypes & {
    leftAdornments?: HTMLElement[];
  }
>({
  files,
  open: wantsToBeOpen,
  onClose,
  onDelete,
  downloadAllFilename,
}: {
  files: FileT[];
  open: boolean;
  onClose: () => void;
  onDelete?: ({ index }: { index: number }) => Promise<void>;
  /** If set, a "download all" button will be offered */
  downloadAllFilename?: string;
}): JSX.Element {
  const lightGallery = React.useRef<LightGalleryClass>();
  const [galleryItems, setGalleryItems] = React.useState<GalleryItem[]>([]);
  React.useEffect(() => {
    let stillAlive = true;
    const cleanup = () => {
      stillAlive = false;
    };

    Promise.all(files.map((f) => resolveFile(f, { onDelete }))).then(
      (resolved) => {
        if (stillAlive) {
          setGalleryItems(resolved);
        }
      }
    );

    return cleanup;
  }, [files, onDelete]);

  React.useEffect(() => {
    const open = lightGallery.current?.lgOpened;
    if (open) {
      lightGallery.current?.updateSlides(galleryItems, 0);
    } else {
      lightGallery?.current?.refresh(galleryItems);
    }
  }, [galleryItems]);

  // const [open, setOpen] = React.useState(false);
  const [overallState, setOverallState] = React.useState<
    "init" | "opening" | "open" | "closing"
  >("init");
  const useBackArrow = isIOS;

  const nAdornments = React.useMemo(
    () =>
      files.reduce(
        (maxAdornments, { leftAdornments = [] }) =>
          Math.max(maxAdornments, leftAdornments.length),
        0
      ),
    [files]
  );

  const backButtonRef = React.useRef<HTMLButtonElement>(null);
  const slot1Ref = React.useRef<HTMLDivElement>();
  // const slot2Ref = React.useRef<HTMLDivElement>();
  const adornmentSlotRefs = React.useMemo(
    () => [slot1Ref /* , slot2Ref */],
    [slot1Ref /* , slot2Ref */]
  );
  if (nAdornments > adornmentSlotRefs.length) {
    throw new Error("Make more slots");
  }

  // protect back button on mobile
  // https://github.com/sachinchoolur/lightGallery/issues/603#issuecomment-515985944
  const { pathname, state } = useLocation();
  const navigate = useNavigate();
  const navigationType = useNavigationType();

  React.useEffect(() => {
    if (wantsToBeOpen) {
      if (
        state?.lg !== "open" &&
        overallState === "init" // &&
        // lightboxImages.length
      ) {
        setOverallState("opening");
        navigate(pathname, { state: { lg: "open" } });
      } else if (overallState === "closing" && state?.lg !== "open") {
        onClose?.();
      }
    } else {
      if (state?.lg === "open") {
        navigate(-1);
      }
    }
  }, [navigate, onClose, overallState, pathname, state?.lg, wantsToBeOpen]);

  React.useEffect(() => {
    if (state?.lg === "open" && galleryItems.length) {
      if (!lightGallery.current?.lgOpened) {
        lightGallery.current?.openGallery();
        setOverallState("open");
      }
    }
  }, [galleryItems.length, state?.lg]);

  React.useEffect(() => {
    if (overallState === "closing" && navigationType !== "POP" && pathname) {
      navigate(
        -1 /* , { replace: true} this should be supported IMHO but is not */
      );
    }
  }, [navigate, navigationType, overallState, pathname]);

  const onDeleteImage = React.useCallback(() => {
    const lg = lightGallery.current;
    if (!lg) {
      throw new Error("deleting without current");
    }
    const index = lg.index;
    lg.galleryItems[index]
      .onDelete?.({ index })
      .then(() => {
        lightGallery.current?.slide(index);
      })
      .catch((err: unknown) => {
        if (err) {
          logAnomaly(new Error("onDelete threw something other than cancel"), {
            err,
          });
        }
      });
  }, []);

  const lgOnClose = React.useCallback(
    (/* foo: AfterCloseDetail */) => {
      const lg = lightGallery.current;
      if (lg) {
        if (lg.lgOpened) {
          setOverallState("closing");
        }
      }
    },
    []
  );

  const hasDelete = Boolean(onDelete);
  const hasDownloadAll = !!downloadAllFilename;

  const [archiveUrl, setArchiveUrl] = React.useState<string | undefined>();
  const [archiveError, setArchiveError] = React.useState<Error | undefined>();
  const [archivingFiles, setArchivingFiles] = React.useState<
    undefined | Array<PathAndKey>
  >();

  const stableDownloadAll = React.useCallback(() => {
    if (!downloadAllFilename) {
      logAndThrow(new Error("Can't create archive without a filename"));
    }

    const workingFiles = files.filter(PathAndKey.is);
    if (!workingFiles.length) {
      enqueueSnackbar({
        variant: "info",
        message: "All of your files are external. No archive will be created",
      });
    } else {
      setArchiveUrl(undefined);
      setArchiveError(undefined);
      setArchivingFiles(workingFiles as Array<PathAndKey>);

      pipe(
        createArchive(workingFiles as PathAndKey[], downloadAllFilename),
        TE.match(
          (err) => {
            const error = new Error("error downloading zip");
            logAnomaly(error, { err });
            setArchiveError(error);
          },
          ({ data: { url } }) => {
            setArchiveUrl(url);
          }
        )
      )();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadAllFilename, /* files, */ setArchivingFiles]);

  const lgOnInit = React.useCallback(
    ({ instance }: InitDetail) => {
      lightGallery.current = instance;

      if (hasDelete) {
        if (!instance.outer.find("#lg-delete").get()) {
          instance.$toolbar.append(
            '<button type="button" aria-label="delete" class="lg-icon" id="lg-delete"><svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" height="24px" viewBox="0 0 24 24" width="24px"><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2.46-7.12l1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14l-2.13-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4z"/></svg></button>'
          );
        }
        instance.outer.find("#lg-delete").on("click", onDeleteImage);
      }

      if (hasDownloadAll) {
        if (!instance.outer.find("#lg-download-all").get()) {
          const deleteAllButton = new DOMParser().parseFromString(
            '<button type="button" aria-label="download all" class="lg-icon" id="lg-download-all"><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="currentColor"><path d="M22 3H2v6h1v11a2 2 0 002 2h14a2 2 0 002-2V9h1V3zM4 5h16v2H4V5zm15 15H5V9h14v11zm-6-10v5.17l2.59-2.58L17 14l-5 5-5-5 1.41-1.42L11 15.17V10h2z"/></svg></button>',
            "text/html"
          ).body.firstChild;

          deleteAllButton &&
            instance.$toolbar
              .find(".lg-download")
              .get()
              .before(deleteAllButton);
        }
        instance.outer.find("#lg-download-all").on("click", stableDownloadAll);
      }

      // we do this here because counter is a plugin and we want it to have already loaded
      if (
        useBackArrow &&
        !instance.$toolbar.find("#lg-back").get() &&
        backButtonRef.current
      ) {
        lightGallery.current?.$toolbar
          .first()
          .prepend(backButtonRef.current.outerHTML)
          .on("click", () => {
            if (lightGallery?.current?.lgOpened) {
              lightGallery.current?.closeGallery();
            }
          });
      }
    },
    [hasDelete, hasDownloadAll, onDeleteImage, stableDownloadAll, useBackArrow]
  );

  const updateAdornments = React.useCallback(
    ({
      index,
      prevIndex,
    }: Pick<AfterSlideDetail, "index"> &
      Partial<Pick<AfterSlideDetail, "prevIndex">>) => {
      const items = lightGallery.current?.galleryItems;
      if (!items) {
        return;
      }
      const goingAway =
        (prevIndex !== undefined && items[prevIndex]?.leftAdornments) ?? [];
      const adding = items[index]?.leftAdornments ?? [];
      // out with the old; in with the new.
      adornmentSlotRefs.forEach((slotRef, i) => {
        const holder = lightGallery.current?.$toolbar.find(slotRef.current);
        if (holder?.get()) {
          const victim = goingAway[i];
          const newbie = adding[i];

          if (victim) {
            const lgVictim = holder.find(victim);
            if (lgVictim.get()) {
              lgVictim.remove();
            }
          }

          if (newbie) {
            holder.append(newbie);
          }
        }
      });
    },
    [adornmentSlotRefs]
  );

  const lgOnAfterOpen = React.useCallback(() => {
    // Couldn't do this at onInit (IIRC) because not everything existed yet
    adornmentSlotRefs.forEach(
      (slotRef) =>
        slotRef.current &&
        lightGallery.current?.$toolbar.append(slotRef.current)
    );

    // updateAdornments({ index: 0 });
  }, [adornmentSlotRefs]);

  return (
    <div id="image-gallery">
      <Box display={"none"}>
        {[slot1Ref /* , slot2Ref */].map((slotRef, i) => (
          <Box
            ref={slotRef}
            id={`slot-${i + 1}`}
            key={`slot-${i + 1}`}
            className="lg-icon"
            style={{
              float: "unset",
              verticalAlign: "middle",
              display: "inline-block",
              cursor: "initial",
            }}
          />
        ))}

        <IconButton ref={backButtonRef} id="lg-back">
          <ArrowBackIosNewIcon sx={{ color: "#999" }} />
        </IconButton>
      </Box>

      {state?.lg === "open" && galleryItems.length ? (
        <ForkedLightGallery
          onInit={lgOnInit}
          onAfterOpen={lgOnAfterOpen}
          onAfterClose={lgOnClose}
          onAfterSlide={updateAdornments}
          speed={500}
          plugins={lgPlugins}
          licenseKey="6BE8BED3-1C88485C-A25A6EE5-D1E44C86"
          dynamic={true}
          dynamicEl={galleryItems}
        />
      ) : null}
      {hasDownloadAll && (
        <DownloadingArchiveDialog
          files={archivingFiles}
          filename={downloadAllFilename}
          url={archiveUrl}
          error={archiveError}
          onClose={() => setArchivingFiles(undefined)}
        />
      )}
    </div>
  );
}
