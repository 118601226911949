import { HtmlTooltip } from "@/Components/HtmlTooltip";
import { StealthyLink } from "@/Components/StealthyLink";
import {
  ApprovalStatus,
  BackendGetProjectsResponseEntry,
  ProjectStatus,
} from "@/Lib/APITypes";
import { AppPermissions } from "@/Lib/hooks";
import { InspectorInfo } from "@/Lib/hooks/useInspectors";
import { Typography } from "@mui/material";
import {
  formatDistanceStrict,
  isAfter /* isAfter, subDays */,
  subDays,
} from "date-fns/fp";
import { ProjectViewTabs } from "../ProjectView";
import { Role } from "@project-centerline/project-centerline-api-types";
import React from "react";

/**
 *
 * @param param0
 * @returns
 */
export function effectiveInvoiceTimestamp({
  approvalTimestamp,
  timestamp,
}: {
  approvalTimestamp?: string | null;
  timestamp: string;
}) {
  return approvalTimestamp ?? timestamp;
}

export function lastDrawDate(
  mri: { i: Parameters<typeof effectiveInvoiceTimestamp>[0] }[]
) {
  const lastDrawAction = mri?.slice(0, 1)[0]?.i;
  const lastDraw = new Date(
    lastDrawAction && effectiveInvoiceTimestamp(lastDrawAction)
  );
  return lastDraw;
}

function putOrDrawWord({
  isPut,
  status,
  delegate,
}: {
  isPut: boolean | null;
  status?: ApprovalStatus;
  delegate?: string | null;
}) {
  if (status === ApprovalStatus.Pending && delegate) {
    return "Inspection";
  }
  return isPut ? "Put" : "Draw";
}

const invoiceDateComparator = (
  a: { approvalTimestamp?: string | null; timestamp: string },
  b: { approvalTimestamp?: string | null; timestamp: string }
) => {
  const aStamp = effectiveInvoiceTimestamp(a);
  const bStamp = effectiveInvoiceTimestamp(b);
  return aStamp < bStamp ? -1 : aStamp > bStamp ? 1 : 0;
};

export const deriveNextAction = (
  project: BackendGetProjectsResponseEntry,
  {
    permissions,
    isConciergeInspector,
    availableInspectors,
  }: {
    permissions: AppPermissions;
    isConciergeInspector: (arg: { email: string }) => boolean;
    availableInspectors: Record<
      string,
      Pick<InspectorInfo, "email" | "vendor">
    >;
  }
) => {
  const now = new Date();
  const fromNow = formatDistanceStrict(now);
  const recentDeadline = subDays(5)(now);
  const isRecent = isAfter(recentDeadline);

  const {
    /* loanAmount, */ summary,
    project_id: projectId,
    project_status: status,
  } = project;
  const { canSeeDecision } = permissions;

  const { draws, mri = [], p = [], pci = [] } = summary ?? {};
  const { Pending = 0, Approved = 0, Rejected = 0 } = draws ?? {};
  const sortedPendingInvoices = p.sort(invoiceDateComparator);
  // const mostRecentInvoice = mri
  //   ?.map(({ i }) => i)
  //   .sort(invoiceDateComparator)[0];
  // const mostRecentPendingInvoice = sortedPendingInvoices[0];
  // const oldestPendingInvoice = sortedPendingInvoices.slice(-1)[0];

  const lastDraw = lastDrawDate(mri);

  let decision:
    | {
        value: string;
        tooltipTitle?: string;
        link?: string;
        text: string;
        csv?: string;
      }
    | undefined;

  if (status === ProjectStatus.Complete) {
    decision = {
      value: "Completed",
      text: `Project has been marked closed.`,
    };
  }

  // PC inspections, for those who can see them
  const myInspection = pci[0];
  if (
    !decision &&
    myInspection &&
    canSeeDecision({ inspector: myInspection.i })
  ) {
    decision = {
      value:
        myInspection.t === "feasibility_report"
          ? "Feasibility Pending"
          : "Concierge Inspection Requested",
      link: `/projectview/${projectId}/Decision`,
      text:
        myInspection.t === "feasibility_report"
          ? "A Feasibility Report is in progress"
          : "A Project Centerline Inspection is pending",
    };
  } else {
    if (!decision) {
      //3. Inspections (no I don't agree with the order in the doc :-))
      const inspections = sortedPendingInvoices.filter(
        ({ role }) => role === Role.Inspector || role === Role.InspectorAdmin
      );
      if (inspections.length > 0) {
        const inspection = inspections[0];
        if (isRecent(new Date(effectiveInvoiceTimestamp(inspection)))) {
          const waitingOnConcierge = isConciergeInspector({
            email: inspection.currentApprover,
          });
          const inspectionVendor =
            availableInspectors[
              inspection.delegate || inspection.currentApprover
            ]?.vendor;
          decision = {
            value: `Inspection ${
              waitingOnConcierge && !inspection.delegate
                ? "Requested"
                : "Pending"
            }`,
            link: `/projectview/${projectId}/Invoices/Pending`,
            text:
              waitingOnConcierge && !inspection.delegate
                ? `Waiting for Project Centerline to dispatch an inspector${
                    inspectionVendor &&
                    !isConciergeInspector({
                      email: inspection.currentApprover,
                    })
                      ? ` from ${inspectionVendor}`
                      : ""
                  }.`
                : `${fromNow(
                    new Date(effectiveInvoiceTimestamp(inspection))
                  )} with ${
                    inspectionVendor ||
                    `inspector ${inspection.currentApprover}`
                  }`,
          };
        }
      }
    }

    //2. Draw Pending
    if (!decision && Pending > 0) {
      const invoice /* mostRecentPendingInvoice */ =
        sortedPendingInvoices.slice(-1)[0];
      const { currentApprover, delegate } = invoice;
      const conciergeDispatched =
        delegate && isConciergeInspector({ email: delegate });
      const effectiveApprover = conciergeDispatched
        ? delegate
        : currentApprover;
      const inspectorVendor =
        effectiveApprover && availableInspectors[effectiveApprover]?.vendor;
      const inspector = inspectorVendor || effectiveApprover || "(unknown)";

      decision = {
        value: isRecent(new Date(effectiveInvoiceTimestamp(invoice)))
          ? `${putOrDrawWord(invoice)} Pending`
          : "Delayed",
        link: `/projectview/${projectId}/Invoices/Pending`,
        text: conciergeDispatched
          ? `Inspection initiated with ${inspector} ${fromNow(lastDraw)} ago`
          : `${inspector} has been reviewing for ${fromNow(lastDraw)}`,
      };
    }

    // 4. Approved / Rejected
    const lastDrawFinalized = mri?.slice(0, 1)[0]?.i;
    if (
      !decision &&
      lastDrawFinalized &&
      isRecent(new Date(lastDrawFinalized.approvalTimestamp))
    ) {
      const approvedRejected = lastDrawFinalized.status;
      //     ? "Draw Approved"
      //     : "Draw Rejected";
      //     const invoiceCategory = approvedRejected === "Draw Approved" ? "Approved" : "Rejected";
      decision = {
        value: `${putOrDrawWord(lastDrawFinalized)} ${approvedRejected}`,
        link: `/projectview/${projectId}/Invoices/${approvedRejected}`,
        text: `${lastDrawFinalized.finalApprover} ${approvedRejected} ${fromNow(
          new Date(lastDrawFinalized.approvalTimestamp)
        )} ago`,
      };
    }

    // 5. Last Draw
    if (!decision && (Approved > 0 || Rejected > 0)) {
      decision = {
        value: "Awaiting Next Transaction",
        link: `/projectview/${projectId}/${ProjectViewTabs.Tasks}`,
        text: `${fromNow(lastDraw)} since last put or draw`,
      };
    }

    // https://projectcenterline.slack.com/team/U0138G0TZCN
    if (Pending === 0 && Approved === 0 && Rejected === 0) {
      decision = {
        value: "--",
        tooltipTitle: "No Draws",
        link: `/projectview/${projectId}/${ProjectViewTabs.Tasks}`,
        text: `No draws have been requested. Click here to add one.`,
        csv: "",
      };
    }

    // // 1. Pre-funding
    // if (isNaN(loanAmount ?? Number.NaN)) {
    //   return {
    //     value: "Pre-funding",
    //     formatted: "Pre-funding",
    //   };
    // }
  }
  if (decision) {
    const { value, link, text, tooltipTitle } = decision;
    const shortFormDiv = <div>{value}</div>;
    return {
      value,
      formatted: link ? (
        <HtmlTooltip
          disableFocusListener
          disableTouchListener
          title={
            <React.Fragment>
              <StealthyLink to={link}>
                <Typography color="inherit">{tooltipTitle || value}</Typography>
                {text}
              </StealthyLink>
            </React.Fragment>
          }
        >
          {shortFormDiv}
        </HtmlTooltip>
      ) : (
        shortFormDiv
      ),
      csv: decision.csv ?? `${value}: ${text}`,
    };
  }

  return {
    value: "",
    formatted: "",
  };
};
