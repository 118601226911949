/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { useLocation, NavLink, To, matchRoutes, matchPath, RouteObject } from "react-router-dom";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavList from "examples/Sidenav/SidenavList";
import SidenavItem from "examples/Sidenav/SidenavItem";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";

// Declaring props types for Sidenav
// TODO: This could be much better.
export type RouteEntry = {
  name: string;
  key: string | number;
  type?: "title" | "divider" | "collapse";
  route?: string;
  href?: string;
  collapse?: Omit<RouteEntry, "collapse">[]; // only one level of collapse is supported
  icon?: string | JSX.Element;
  title?: string;
  noCollapse?: boolean;
  styleAsTitle?: "open" | "closed";
  component?: JSX.Element;
};

interface Props {
  color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark" | "light";
  brand?: string;
  brandName?: string;
  routes: Array<RouteEntry>;
  onMouseEnter: React.MouseEventHandler;
  onMouseLeave: React.MouseEventHandler;
  home?: To;
}

const timToRR: (r: RouteEntry) => RouteObject = (r) =>
  ({
    ...r,
    path: r.route,
    children: r.collapse && r.collapse.map(timToRR),
  } as RouteObject);

function Sidenav({ color, brand, brandName, routes, home, ...rest }: Props): JSX.Element {
  const [openCollapse, setOpenCollapse] = useState<boolean | string | number>(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState<boolean | string | number>(false);
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
  const location = useLocation();
  const { pathname } = location;

  const asRoutes = routes.map(timToRR);
  const matchedAsRoutes = matchRoutes(asRoutes, pathname);
  const collapseName = (matchedAsRoutes?.at(0)?.route as RouteEntry | undefined)?.key;
  const itemParentName = collapseName;
  const itemName = (
    matchedAsRoutes?.at(matchedAsRoutes.length - 1)?.route as RouteEntry | undefined
  )?.key;

  let textColor:
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "dark"
    | "white"
    | "inherit"
    | "text"
    | "light" = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  // console.log({ collapseName, openCollapse, openNestedCollapse, pathname });
  // useEffect(() => {
  //   // console.log("setOpenCollapse effect", { collapseName, itemParentName });
  //   // setOpenCollapse(collapseName ?? false);
  //   // setOpenNestedCollapse(itemParentName ?? false);
  // }, [collapseName, itemParentName]);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location, transparentSidenav, whiteSidenav]);

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse: typeof routes) => {
    const template = collapse.map(({ name, icon, route, key, href }) => {
      // const route = newFunction(routeArg, pathname);
      // console.log("renderNestedCollapse", { name, route, key, href, itemName, itemParentName });
      // const match = (route && matchPath(route, location.pathname)) || undefined;

      // we need the actual matched route so we can link to e.g.a real project id instead of /projectview/:id
      const expandedRoute = route && matchPath(route, pathname)?.pathname;
      return href ? (
        <Link
          key={key}
          href={href}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavItem name={name} icon={icon} nested />
        </Link>
      ) : route ? (
        <NavLink to={expandedRoute ?? route} key={key} style={{ textDecoration: "italic" }}>
          <SidenavItem name={name} icon={icon} active={expandedRoute === pathname} nested />
        </NavLink>
      ) : (
        (() => {
          console.log(new Error("no href and no route; can't build link"), {
            name,
            route,
            key,
            href,
          });
          return "???";
        })()
      );
    });

    return template;
  };
  // Render the all the collapses from the routes.js
  const renderCollapse = (collapses: typeof routes) =>
    collapses.map(({ name, icon, collapse, route, href, key }) => {
      let returnValue;

      // we need the actual matched route so we can link to e.g.a real project id instead of /projectview/:id
      const expandedRoute = route && matchPath(route, pathname)?.pathname;

      // console.log("renderCollapse", { name, collapse, route, href, key, itemName, itemParentName });
      if (collapse) {
        returnValue = (
          <SidenavItem
            key={key}
            color={color}
            name={name}
            icon={icon}
            active={key === itemParentName ? "isParent" : false}
            open={openNestedCollapse === key}
            onClick={({ currentTarget }) =>
              openNestedCollapse === key && currentTarget.classList.contains("MuiListItem-root")
                ? setOpenNestedCollapse(false)
                : setOpenNestedCollapse(key)
            }
          >
            {renderNestedCollapse(collapse)}
          </SidenavItem>
        );
      } else {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavItem color={color} name={name} icon={icon} active={key === itemName} />
          </Link>
        ) : route ? (
          <NavLink to={expandedRoute ?? route} key={key} style={{ textDecoration: "none" }}>
            <SidenavItem color={color} name={name} icon={icon} active={key === itemName} />
          </NavLink>
        ) : (
          (() => {
            console.log(new Error("no href and no route; can't build link"), {
              name,
              route,
              key,
              href,
            });
            return "???";
          })()
        );
      }
      return <SidenavList key={key}>{returnValue}</SidenavList>;
    });

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({ type, name, icon, title, collapse, noCollapse, key, href, route, styleAsTitle }) => {
      let returnValue;

      // console.log("renderRoutes", {
      //   name,
      //   route,
      //   collapse,
      //   type,
      //   noCollapse,
      //   title,
      //   key,
      //   href,
      //   styleAsTitle,
      //   openCollapse,
      //   pathname,
      //   items,
      //   collapseName,
      //   itemParentName,
      //   itemName,
      //   routes,
      //   openNestedCollapse,
      // });

      // console.log("rendering", { key, name, icon, collapseName, openCollapse });
      if (type === "collapse") {
        // we need the actual matched route so we can link to e.g.a real project id instead of /projectview/:id
        const expandedRoute = route && matchPath(route, pathname)?.pathname;
        if (href) {
          returnValue = (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavCollapse
                name={name}
                icon={icon}
                active={key === collapseName}
                noCollapse={noCollapse}
              />
            </Link>
          );
        } else if (noCollapse && route) {
          returnValue = (
            <NavLink to={expandedRoute ?? route} key={key}>
              <SidenavCollapse
                name={name}
                icon={icon}
                noCollapse={noCollapse}
                active={key === collapseName}
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            </NavLink>
          );
        } else {
          returnValue = styleAsTitle ? (
            <NavLink
              style={{ textDecoration: "none" }}
              key={key}
              to={expandedRoute ?? (route || ".")}
            >
              <MDTypography
                color={textColor}
                display="block"
                variant="caption"
                fontWeight="bold"
                textTransform="uppercase"
                pl={3}
                mt={2}
                mb={1}
                ml={1}
              >
                {title}
              </MDTypography>
              {styleAsTitle === "open" && collapse ? renderCollapse(collapse) : null}
            </NavLink>
          ) : (
            <SidenavCollapse
              key={key}
              name={name}
              icon={icon}
              active={key === collapseName}
              open={openCollapse === key}
              onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
            >
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
          );
        }
      } else if (type === "title") {
        returnValue = (
          <MDTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </MDTypography>
        );
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      } else if (collapse) {
        // wind up here if you have collapse but no type - for invisible grouping of sub-routes
        returnValue = renderCollapse(collapse);
      }

      return returnValue;
    }
  );

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to={home || "/"} display="flex" alignItems="center">
          {brand && <MDBox component="img" src={brand} alt="Brand" width="14rem" />}
          <MDBox
            width={brandName ? undefined : "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
              {brandName}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>{renderRoutes}</List>
    </SidenavRoot>
  );
}

// Declaring default props for Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

export default Sidenav;
