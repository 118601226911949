/*
 * This stuff winds up in the built output. So when merging to master, include only prod or
 * do something clever with if (process.NODE_ENV === "production") or something.
 */
const prod = {
  s3: {
    REGION: "us-west-2",
    BUCKET: "centerline-backend-aws-de-projectcenterlinebucket-1wqz5h4k4t2ct",
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://2o2tug5ckd.execute-api.us-west-2.amazonaws.com/dev/",
    WEBSOCKET_URL: "wss://2dr3a9sm8f.execute-api.us-west-2.amazonaws.com/dev",
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_ss3wB0P9M",
    APP_CLIENT_ID: "2sais6ndne13o48l3abu9jtgfb",
    IDENTITY_POOL_ID: "us-west-2:167d4558-f8c8-4aa9-9ae1-7b423938274d",
  },
  contact: {
    customerSuccess: "customersuccess@projectcenterline.com",
    conciergeInspectionPrefix: "",
  },
  GraphQL: {
    endpoint: "TBD",
    REGION: "us-west-2",
  },
};

const staging = {
  s3: {
    REGION: "us-west-2",
    BUCKET: "centerline-backend-aws-st-projectcenterlinebucket-1s8ehpv87i9d6",
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://d43o4jtbzj.execute-api.us-west-2.amazonaws.com/staging/",
    WEBSOCKET_URL:
      "wss://b1lb0t2345.execute-api.us-west-2.amazonaws.com/staging",
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_84JhEPioL",
    APP_CLIENT_ID: "39n8ssvbkbj0p9acpajqdngg7i",
    IDENTITY_POOL_ID: "us-west-2:7375e3cb-31df-41a9-a19e-6d61133a89d8",
  },
  contact: {
    customerSuccess: "mitch+customersuccess@projectcenterline.com",
    conciergeInspectionPrefix: "staging-",
  },
  GraphQL: {
    endpoint:
      "https://d43o4jtbzj.execute-api.us-west-2.amazonaws.com/staging/graphql",
    REGION: "us-west-2",
  },
};

const mitch = {
  s3: {
    REGION: "us-east-1",
    BUCKET: "centerline-backend-aws-m-projectcenterlinebuckets-lfkg7g3t0k6p",
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://dzpr6t9f46.execute-api.us-east-1.amazonaws.com/mitch/",
    WEBSOCKET_URL: "wss://dvxjx2caxi.execute-api.us-east-1.amazonaws.com/mitch",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_taYT3sTYX",
    APP_CLIENT_ID: "4n102tg0dce812h0kk8h5si14q",
    IDENTITY_POOL_ID: "us-east-1:8bf74270-25a3-40fa-ace4-d424c2f704de",
  },
  contact: {
    customerSuccess: "mitch+customersuccess@projectcenterline.com",
    conciergeInspectionPrefix: "staging-",
  },
  GraphQL: staging.GraphQL,
};

const namedBaseConfigs = {
  staging,
  prod,
  mitch,
  local: {
    ...staging,
    apiGateway: {
      REGION: "us-east-1",
      URL: `http://localhost:4000/${
        process.env.REACT_APP_COGNITO === "prod"
          ? "dev"
          : process.env.REACT_APP_STAGE || "staging"
      }/`,
      WEBSOCKET_URL: "ws://localhost:3001",
    },
  },
};

const baseConfig =
  namedBaseConfigs[
    (process.env.REACT_APP_BACKEND as keyof typeof namedBaseConfigs) ||
      "staging"
  ];
const overrideCognitoFromConfig =
  namedBaseConfigs[
    process.env.REACT_APP_COGNITO as keyof typeof namedBaseConfigs
  ];

const config = {
  ...baseConfig,
  ...(overrideCognitoFromConfig
    ? { cognito: overrideCognitoFromConfig.cognito }
    : {}),
};

const finalConfig = {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
  nonProdEnv: process.env.REACT_APP_BACKEND !== "prod", // would like to have done this with NODE_ENV but CRA is rigid and all builds get "production"
};
export default finalConfig;

// cspell:ignore sais ndne jtgfb vrcge jyhfx ejbe Ugrjs limmgq gookqalomrgvs ehpv ssvbkbj acpajqdngg lfkg dvxjx caxi projectcenterlinebuckets
