/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { ReactNode } from "react";

// react-router-dom components
import { Link, useMatches, To } from "react-router-dom";

// @mui material components
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Declaring props types for the Breadcrumbs
interface Props {
  icon: ReactNode;
  title?: string;
  route?: string | string[];
  light?: boolean;
  home?: To;
}

interface Crumb {
  text: string;
  link?: string;
}

export type CrumbFunction = (match: ReturnType<typeof useMatches>[0]) => Crumb;

function Breadcrumbs({ icon, title: titleProp, route, light, home }: Props): JSX.Element {
  const routes: string[] | undefined =
    route === undefined ? undefined : Array.isArray(route) ? route?.slice(0, -1) : [route];

  const crumbs = useMatches()
    .filter(({ handle }) => handle && (handle as { crumb?: CrumbFunction }).crumb)
    .map((match) => ({
      id: match.id,
      ...(match.handle as { crumb: CrumbFunction }).crumb(match),
    }));
  const title = route?.slice(-1)?.[0] ?? crumbs.slice(-1)?.[0]?.text;

  // console.log({ title, crumbs, route });
  const crumbTypographyProps = {
    component: "span",
    variant: "button",
    fontWeight: "regular",
    textTransform: "capitalize",
    color: light ? "white" : "dark",
    opacity: light ? 0.8 : 0.5,
    sx: { lineHeight: 0 },
  };

  return (
    <MDBox mr={{ xs: 0, xl: 8 }}>
      <MuiBreadcrumbs
        sx={{
          "& .MuiBreadcrumbs-separator": {
            color: ({ palette: { white, grey } }) => (light ? white.main : grey[600]),
          },
        }}
      >
        <Link to={home || "/"}>
          <MDTypography
            component="span"
            variant="body2"
            color={light ? "white" : "dark"}
            opacity={light ? 0.8 : 0.5}
            sx={{ lineHeight: 0 }}
          >
            <Icon>{icon}</Icon>
          </MDTypography>
        </Link>
        {routes
          ? routes.map((el: string) => (
              <Link to={`/${el}`} key={el}>
                <MDTypography {...crumbTypographyProps}>{el}</MDTypography>
              </Link>
            ))
          : crumbs.slice(0, -1).map(({ id, text, link }) => {
              return link ? (
                <Link to={link} key={id}>
                  <MDTypography {...crumbTypographyProps}>{text}</MDTypography>
                </Link>
              ) : (
                <div />
              );
            })}

        {/* <MDTypography
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          color={light ? "white" : "dark"}
          sx={{ lineHeight: 0 }}
        >
          {title.replace("-", " ")}
        </MDTypography> */}
      </MuiBreadcrumbs>
      <MDTypography
        fontWeight="bold"
        textTransform="capitalize"
        variant="h6"
        color={light ? "white" : "dark"}
        noWrap
      >
        {title?.replace("-", " ")}
      </MDTypography>
    </MDBox>
  );
}

// Declaring default props for Breadcrumbs
Breadcrumbs.defaultProps = {
  light: false,
};

export default Breadcrumbs;
