import {
  AppBar,
  CircularProgress,
  Container,
  Dialog,
  Divider,
  Fab,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { ConfirmDialog } from "../../../Components/ConfirmDialog";
import { CreateTaskInputs } from "@lib/APITypes";
import { LoanType } from "@lib/LoanType";
import { useMoney, usePermissions } from "@lib/hooks";
import { useProjectDetails } from "@lib/hooks/useProjectDetails";
import { SlideUp } from "@lib/Transitions";
import { AddTaskPanel } from "../Schedule/addTask";

import { EditMode, ImportTasks } from "../Schedule/importTasks";
import InfoView from "./InfoView";
import { useNavigate } from "react-router-dom";

export interface BudgetTabProps {
  /** We'll fetch with SWR but hopefully it's already ready :-) */
  projectId: string;

  /** Where should we link to when they click on a missing loan? */
  toLoan?: string;
}

/**
 * Budget Tab - https://trello.com/c/Qv3NScyp/222-add-mvp-csv-import-to-new-budget-tab
 */
export function BudgetTab({ projectId, toLoan }: BudgetTabProps): JSX.Element {
  const { project /* , errors: projectErrors */ } =
    useProjectDetails(projectId);
  const { canAddTasks } = usePermissions();
  const projectDetails = project?.details;
  const { formatMoney } = useMoney();

  const [showAddTaskPanel, setShowAddTaskPanel] = React.useState(false);

  const handleCreateTask = (
    _project_id: string,
    _retention: number,
    _email: string,
    taskInputs: CreateTaskInputs
  ) => project?.addTask(taskInputs) ?? Promise.reject(new Error("no project?"));

  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [whatNextDialogOpen, setWhatNextDialogOpen] = React.useState(false);
  const navigate = useNavigate();

  const confirmCloseWithoutSave = () => setConfirmOpen(true);
  return projectDetails ? (
    <>
      <Grid
        container
        direction="column"
        alignItems="center"
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          width: "60%",
          margin: "auto",
        }}
      >
        .
        <Grid item sx={{ width: "100%" }}>
          <Grid
            container
            sx={({ spacing }) => ({
              paddingBottom: spacing(2),
            })}
            justifyContent="space-around"
            alignItems="center"
          >
            <Grid item>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5">
                  {projectDetails.all_tasks.length} Tasks
                </Typography>
                {canAddTasks ? (
                  <Fab
                    sx={({ spacing }) => ({
                      marginLeft: spacing(1),
                    })}
                    color="primary"
                    size="small"
                    aria-label="add"
                    onClick={() => setShowAddTaskPanel(true)}
                  >
                    <AddIcon />
                  </Fab>
                ) : null}
              </div>
            </Grid>
            <Grid item>
              <Typography variant="h5">
                Total Task Value:{" "}
                {formatMoney(
                  projectDetails.loanType === LoanType.LoC
                    ? projectDetails.totalActiveTaskValue
                    : projectDetails.totalTaskValue || 0,
                  {
                    // short: true,
                  }
                )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          sx={({ spacing }) => ({
            width: "100%",
            marginBottom: spacing(2),
          })}
        >
          {canAddTasks ? (
            <>
              <Divider
                sx={({ spacing }) => ({
                  width: "100%",
                  marginBottom: spacing(2),
                })}
              />
              <ImportTasks
                project_id={projectDetails.project_id}
                retention={Number(projectDetails.retention)}
                createTask={handleCreateTask}
                onDoneCreatingTasks={() => {
                  project?.forceRefreshDetails();
                  setWhatNextDialogOpen(true);
                }}
                edit={EditMode.NONE}
              />
            </>
          ) : null}
        </Grid>
      </Grid>
      {canAddTasks ? (
        <Dialog
          fullScreen
          TransitionComponent={SlideUp}
          open={showAddTaskPanel}
          onClose={confirmCloseWithoutSave}
        >
          <AppBar
            sx={{
              position: "relative",
            }}
          >
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={confirmCloseWithoutSave}
                aria-label="close"
                size="large"
              >
                <CloseIcon />
              </IconButton>
              <Typography
                variant="h6"
                sx={({ spacing }) => ({
                  marginLeft: spacing(2),
                  flex: 1,
                })}
              >
                Create Task
              </Typography>
            </Toolbar>
          </AppBar>
          <Container>
            <AddTaskPanel
              projectId={projectId}
              onTaskAdded={() => setShowAddTaskPanel(false)}
            />
          </Container>
        </Dialog>
      ) : null}
      <ConfirmDialog
        title="Abandon Task?"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={() => setShowAddTaskPanel(false)}
      >
        <Typography>Are you sure you want to close without saving?</Typography>
      </ConfirmDialog>
      <ConfirmDialog
        title="Import Complete"
        open={whatNextDialogOpen}
        setOpen={setWhatNextDialogOpen}
        onConfirm={() => {
          navigate(`/projectview/${projectId}/${InfoView.Tabs.Users}`);
        }}
        onCancel={() => {
          navigate(`/projectview/${projectId}`);
        }}
        confirmActionWords="add users"
        cancelActionWords="create draws"
        defaultAction="confirm"
      >
        <Typography>Add users now?</Typography>
      </ConfirmDialog>
    </>
  ) : (
    <CircularProgress />
  );
}
