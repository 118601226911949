import { Auth } from "aws-amplify";
import useSWR from "swr";

import { CognitoUserSession } from "amazon-cognito-identity-js";
import { EmailAddress } from "@project-centerline/project-centerline-api-types";

const sessionFetcher = async () => {
  try {
    const session = await Auth.currentSession();
    return session;
  } catch (err) {
    if (err !== "No current user") {
      throw err;
    }
    return undefined;
  }
};

const userFetcher = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return user;
  } catch (err) {
    if (err !== "The user is not authenticated") {
      throw err;
    }
  }
};

export function useAuth(): {
  // if you don't have to worry about it changing out from under you
  isAuthenticated: boolean;
  // dynamic
  checkAuthenticated: () => boolean;
  isValidating: boolean;
  currentUserEmail?: EmailAddress;
  signIn: (
    username: string,
    password: string
  ) => ReturnType<typeof Auth.signIn>;
  firstTimeSignIn: (
    username: string,
    password: string,
    newPassword: string
  ) => Promise<void>;
  signOut: () => Promise<void>;
  refresh: () => Promise<void>;
} {
  const {
    data: session,
    mutate: mutateSession,
    isValidating: sessionValidating,
  } = useSWR<CognitoUserSession | undefined | null, unknown>(
    "_auth:session",
    sessionFetcher
  );
  const {
    data: currentUser,
    mutate: mutateCurrentUser,
    isValidating: userValidating,
  } = useSWR("_auth:user", userFetcher);

  async function firstTimeSignIn(
    username: string,
    password: string,
    newPassword: string
  ) {
    const cognitoUser = await Auth.signIn(username, password);
    if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
      const newCognitoUser = await Auth.completeNewPassword(
        cognitoUser,
        newPassword,
        cognitoUser.challengeParam.requiredAttributes
      );
      mutateCurrentUser(newCognitoUser, false);
    } else {
      throw new Error(
        `Unexpected auth challenge "${cognitoUser.challengeName}"`
      );
    }
  }

  const refresh = async () => {
    await Promise.all([mutateSession(), mutateCurrentUser()]);
  };
  const checkAuthenticated = () => Boolean(session) && Boolean(currentUser);
  return {
    /// If you don't have to worry about it changing out from under you
    isAuthenticated: checkAuthenticated(),
    /// dynamic
    checkAuthenticated,
    isValidating: sessionValidating || userValidating,
    currentUserEmail: currentUser?.attributes?.email as EmailAddress,
    signOut: () =>
      Auth.signOut()
        .then(() => mutateCurrentUser(() => null, false))
        .then(() => mutateSession(() => null, false))
        .then(() => undefined),
    signIn: (user, pass) =>
      Auth.signIn(user, pass).then(async (user) => {
        await Promise.all([mutateCurrentUser(user, false), mutateSession()]);
        return user;
      }),
    firstTimeSignIn,
    refresh,
  };
}

export const requireAuthenticatedUser = () =>
  Auth.currentAuthenticatedUser().then(() => undefined);
