import { ProjectCount } from "@/Components/ProjectCount";
import { Tile } from "@/Components/Tile";
import { TileContainer } from "@/Components/TileContainer";
import { useMoney } from "@/Lib/hooks";
import { useNewUIHook } from "@/Lib/hooks/useNewUI";
import { Grid, Typography } from "@mui/material";

export const ProjectsAndLoansHeader = ({
  projectCount,
  totalLoans,
  totalBalance,
}: {
  projectCount: number;
  totalLoans: number;
  totalBalance: number;
}) => {
  const { formatMoney } = useMoney();
  const { useNewUI } = useNewUIHook();

  return (
    <TileContainer>
      <Tile>
        <ProjectCount projectCount={projectCount} offerCreate={!useNewUI} />
      </Tile>
      <Tile>
        <Grid container direction="column" alignContent="center">
          <Grid item>
            <Typography variant="tile">
              {formatMoney(totalLoans, { short: true })}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="tile">Loans</Typography>
          </Grid>
        </Grid>
      </Tile>
      <Tile>
        <Grid container direction="column" alignContent="center">
          <Grid item>
            <Typography variant="tile">
              {formatMoney(totalBalance, { short: true })}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="tile">Balance</Typography>
          </Grid>
        </Grid>
      </Tile>
    </TileContainer>
  );
};
