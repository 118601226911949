import React from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { FormControl } from "@mui/material";
import { ControllerRenderProps, Path, useController } from "react-hook-form";
import { z } from "zod";
import { requiredBySchema } from "@/Lib/util/zodUtils";

export type ControlledTextFieldProps<Schema extends z.AnyZodObject> = {
  name: Path<z.infer<Schema>>;
  label: string;
  fieldProps?: (
    field: ControllerRenderProps<z.infer<Schema>, Path<z.infer<Schema>>>
  ) => TextFieldProps;
} & TextFieldProps;

// this is truly the interesting bit, but gets exposed via a hook so we can memoize
const ControlledTextField = <Schema extends z.AnyZodObject>({
  name,
  label,
  fieldProps,
  schema,
  ...props
}: ControlledTextFieldProps<Schema> & { schema: Schema }) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name });
  return (
    <FormControl fullWidth>
      <TextField
        variant="outlined"
        id={field.name}
        {...field}
        required={requiredBySchema(schema, field.name)}
        label={label}
        error={Boolean(error)}
        helperText={error?.message}
        {...fieldProps?.(field)}
        {...props}
      />
    </FormControl>
  );
};

/**
 * Generate a TextField that is controlled by RHF and a zod schema and has error feedback and all that good stuff
 *
 * @param schema your generator will be customized based on your form data schema so that hinting works etc
 * @returns A `FormControl` containing the `TextField`
 */
export const useControlledTextField = <Schema extends z.AnyZodObject>(
  schema: Schema
) =>
  React.useCallback(
    (props: ControlledTextFieldProps<Schema>) => (
      <ControlledTextField {...props} schema={schema} />
    ),
    [schema]
  );
