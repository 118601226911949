import React from "react";
import TextField from "@mui/material/TextField";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
} from "@mui/material";
import { buttonCTA, buttonCancel } from "@/Lib/StyleHelpers";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ZodSchema as AddressSchema } from "@/Forms/AddressForm";
import * as z from "zod";
import { InspectionTypeRadio } from "@/Components/InspectionTypeRadio";
import colors from "@tim/assets/theme/base/colors";
import { enqueueSnackbar } from "notistack";
import { logError } from "@/Lib/ErrorLogging";
import ProjectOrNewAddress from "@/Components/ProjectOrNewAddress";
import { ErrorOrProgressOr } from "@/Components/ErrorOrProgressOr";
import { useQuery } from "urql";
import { graphql } from "@/graphql-generated";
import { useSearchParams } from "react-router-dom";
import { Schema } from "@effect/schema";
import { Project2024, ProjectId2024 } from "@/Lib/types";

export const listProjectsQuery = graphql(/* GraphQL */ `
  query listProjectsQuery(
    $first: Float
    $last: Float
    $before: Cursor
    $after: Cursor
  ) {
    projects(first: $first, last: $last, before: $before, after: $after) {
      # pageInfo {
      #   hasNextPage
      #   endCursor
      # }
      edges {
        node {
          project_id
          address
          city
          state
          zip
          name
          unit
        }
      }
    }
  }
`);

const NewRequestPage = ({
  inspection,
  budgetReview,
}: {
  inspection?: boolean;
  budgetReview?: boolean;
}) => {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("project");

  const [{ data, fetching, error }] = useQuery({
    query: listProjectsQuery,
  });
  console.log({ data, projectId });
  const projects =
    data &&
    data.projects.edges.map(({ node: { project_id, zip, ...rest } }) =>
      Schema.decodeSync(Project2024)({
        ...rest,
        id: project_id,
        zipcode: zip,
      })
    );

  const requestSchema = AddressSchema.extend({
    firstName: z.string().min(1),
    lastName: z.string().min(1),
    phoneNumber: z.string().min(10),
    email: z.string().email(),
    comments: z.string(),
    selectedProject: z
      .object({
        projectId: z.string().uuid(),
      })
      .optional(),
    detailedPlan: z.boolean(),
  });
  const [submitting, setSubmitting] = React.useState<boolean>();

  const onSubmit: SubmitHandler<Request> = (formValues) =>
    // {
    //   address,
    //   city,
    //   state,
    //   zipcode,
    // }
    {
      setSubmitting(true);
      console.log(formValues);
      const createProject = Promise.resolve("Not implemented");
      enqueueSnackbar({
        message: "Not implemented",
        variant: "error",
      });
      // addProject({
      //   projectData: { project: { address, city, state, zipcode } },
      //   uploadedFiles: [],
      // }).then(() => resetFormValues())
      createProject

        .catch((err) => {
          logError(new Error("Add project failure (create from inspection)"), {
            err,
          });
          enqueueSnackbar({
            message:
              "Oops! Something went wrong and project could not be added. The team has been notified",
            variant: "error",
          });
        })
        .finally(() => {
          return setSubmitting(false);
        });
    };

  type Request = z.infer<typeof requestSchema>;
  const requestMethod = useForm<Request>({
    mode: "onChange",
    resolver: zodResolver(requestSchema),
  });
  const { control, handleSubmit } = requestMethod;
  return (
    <FormProvider {...requestMethod}>
      <form onSubmit={handleSubmit(onSubmit, console.error)}>
        <div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                width: "40vw",
                backgroundColor: `${colors.white}`,
                boxShadow: "0px 5px 8px #C0C0C0	",
                minWidth: 400,
                paddingTop: 40,
                paddingBottom: 40,
              }}
            >
              {inspection && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Grid item>
                    <InspectionTypeRadio />
                  </Grid>
                </div>
              )}

              <div style={{ marginLeft: "1vw" }}>
                <div
                  style={{
                    display: "flex",
                    marginRight: "1vw",
                    marginTop: "2vh",
                  }}
                >
                  <ErrorOrProgressOr
                    progress={fetching}
                    error={error?.message}
                    render={() => (
                      <Box width="100%">
                        <ProjectOrNewAddress
                          projects={projects ?? []}
                          defaultProject={
                            projectId
                              ? Schema.decodeSync(ProjectId2024)(projectId)
                              : undefined
                          }
                        />
                      </Box>
                    )}
                  />
                </div>
              </div>
              <div style={{ marginTop: "2vh", marginLeft: "1vw" }}>
                Point of Contact
              </div>
              <div
                style={{ marginTop: "2vh", marginLeft: "1vw", minHeight: 130 }}
              >
                <Grid container spacing={2} columnSpacing={{ xs: 1 }}>
                  <Grid item xs={6}>
                    <Controller
                      name="firstName"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl>
                          <TextField
                            variant="outlined"
                            id="firstName"
                            {...field}
                            label="First Name"
                            type="text"
                            sx={{
                              width: "18vw",
                              minWidth: 150,
                              height: "5vh",
                            }}
                            required
                            error={Boolean(error)}
                            helperText={error?.message}
                          />
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="lastName"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl>
                          <TextField
                            variant="outlined"
                            id="lastName"
                            {...field}
                            label="Last Name"
                            type="text"
                            sx={{ width: "18vw", minWidth: 150 }}
                            required
                            error={Boolean(error)}
                            helperText={error?.message}
                          />
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name="phoneNumber"
                      render={({ field, fieldState: { error } }) => (
                        <FormControl>
                          <TextField
                            variant="outlined"
                            id="phoneNumber"
                            {...field}
                            label="Phone Number"
                            type="text"
                            sx={{ width: "18vw", minWidth: 150 }}
                            required
                            error={Boolean(error)}
                            helperText={error?.message}
                          />
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name="email"
                      render={({ field, fieldState: { error } }) => (
                        <FormControl>
                          <TextField
                            variant="outlined"
                            id="email"
                            {...field}
                            label="Email"
                            type="text"
                            sx={{ width: "18vw", minWidth: 150 }}
                            required
                            error={Boolean(error)}
                            helperText={error?.message}
                          />
                        </FormControl>
                      )}
                    />
                  </Grid>
                </Grid>
              </div>
              <div
                style={{
                  marginTop: "1vh",
                  marginBottom: "2vh",
                  display: "flex",
                  justifyContent: "flex-start",
                  marginLeft: "1vw",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        justifyContent: "center",
                        width: "18vw",
                        minWidth: 150,
                      }}
                    >
                      Add Files
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="comments"
                      control={control}
                      render={({ field }) => (
                        <FormControl>
                          <TextField
                            variant="outlined"
                            id="comments"
                            {...field}
                            label="Comments"
                            type="text"
                            multiline
                            maxRows={1}
                            sx={{
                              width: "18vw",
                              minWidth: 150,
                            }}
                          />
                        </FormControl>
                      )}
                    />
                  </Grid>
                </Grid>
              </div>
              {budgetReview && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginRight: 20,
                    paddingBottom: 10,
                  }}
                >
                  <Controller
                    name="detailedPlan"
                    control={control}
                    render={({ field }) => (
                      <FormControl>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Checkbox id="detailedPlan" {...field} />
                          Detailed Plan & Specification Review
                        </div>
                      </FormControl>
                    )}
                  />
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginLeft: "1vw",
                  marginRight: "1vw",
                }}
              >
                <Button
                  variant="contained"
                  size="medium"
                  color="cancel"
                  sx={buttonCancel}
                >
                  Leave
                </Button>

                <Button
                  type="submit"
                  variant="contained"
                  size="medium"
                  color="cta"
                  sx={buttonCTA}
                  disabled={submitting}
                >
                  {submitting ? <CircularProgress /> : "Submit"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default NewRequestPage;
