import { TabContext, TabList } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function TabNavigator() {
  const [value, setValue] = React.useState("new");
  const location = useLocation();
  const navigate = useNavigate();
  const changeRoute = (event: React.SyntheticEvent, newValue: string) => {
    const viewID = newValue;
    navigate(`${viewID}`, { relative: "route" });
  };
  useEffect(() => {
    const currentLocation = location.pathname.split("/");
    setValue(currentLocation[currentLocation.length - 1]);
  }, [value, location]);
  // Labels below to render Items on the draws screen
  const DRAWS = [
    {
      label: "New",
      value: "new",
    },
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Approved",
      value: "approved",
    },
    {
      label: "Rejected",
      value: "rejected",
    },
  ];
  // Array below to render labels on the Home Screen

  return (
    <div>
      <TabContext value={value}>
        <Box
          sx={{
            marginBottom: "1vh",
            marginRight: "25vw",
            paddingLeft: "1vw",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
          }}
        >
          <TabList
            style={{ justifyContent: "space-around" }}
            onChange={changeRoute}
          >
            {DRAWS.map((i) => (
              <Tab
                sx={{
                  alignSelf: "center",
                  marginRight: "1vw",
                  marginLeft: "1vw",
                  paddingTop: ".5vw",
                  paddingBottom: ".5vw",
                }}
                label={i.label}
                value={i.value}
              />
            ))}
          </TabList>
        </Box>
        {/* Use To Display items below the tab selected */}
        {/* <TabPanel value="1">Item One</TabPanel>
        <TabPanel value="2">Item Two</TabPanel>
        <TabPanel value="3">Item Three</TabPanel> */}
      </TabContext>
    </div>
  );
}

export default TabNavigator;
