import * as React from "react";
import { AgGridReact, AgGridReactProps } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

import {
  BackendGetProjectsResponse,
  BackendGetProjectsResponseEntry,
  ProjectStatus,
} from "@/Lib/APITypes";
import { useLocation, useMatch, useNavigate } from "react-router-dom";
import { HealthIndicator } from "../../../Components/HealthIndicator";
import HomeTabNavigator from "../../../Components/HomeTabNavigator";
import { LinearProgress } from "@mui/material";
import { Attachment } from "@mui/icons-material";
import {
  availableColumns,
  DotDotDotMenu,
  useColumnDerivationContext,
} from "../../../Components/ProjectColumnGenerator";
import { useFeatures, usePermissions } from "@/Lib/hooks";
import {
  deriveNextAction,
  lastDrawDate,
} from "@/Pages/Dashboard/projectStatusUtils";
import { useInspectors } from "@/Lib/hooks/useInspectors";
import PortfolioCSVGenerator from "./PortfolioCSVGenerator";
import { useAdminData } from "@/Lib/AdminContext";
import { BulkUserAdder } from "@/Components/BulkUserAdder";
import { EmailAddress } from "@project-centerline/project-centerline-api-types";
import { createViews, ViewID } from "../PortfolioViews";
import { useGetUserRole } from "@/Lib/hooks/useGetUserRole";
import { useAuth } from "@/Lib/hooks/useAuth";
import { throwIfNot } from "@/Lib/util/throwIfNot";
import { logAnomaly } from "@/Lib/ErrorLogging";

type ProjectData = BackendGetProjectsResponseEntry;

const HomeTable = (props: {
  projects: BackendGetProjectsResponse;
  loading: boolean;
  onRequestDelete: (projectId: string) => void;
  onUpdateStatus: (projectId: string, status: ProjectStatus) => void;
}) => {
  const { projects } = props;
  const pagination = true;
  const paginationPageSize = 10;
  const rowHeight = 60;
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const progressSpinner = searchParams.get("spinner");
  const permissions = usePermissions();
  const { isConciergeInspector, availableInspectors: inspectorsList } =
    useInspectors();
  const availableInspectors = React.useMemo(
    () =>
      Object.fromEntries(
        inspectorsList.map((inspector) => [inspector.email, inspector])
      ),
    [inspectorsList]
  );
  /* The column defs maps the value based off of the name of the cell, and passes it to this function
     This also receives a value Type, so that when we want to route, we navigate to the corresponding cell
     That was clicked on */
  const ProjectCell = ({
    data: { project_id },
    value,
    valueType,
  }: {
    data: ProjectData;
    value: string;
    valueType: "Draws" | "Files" | "Loan";
  }) => {
    if (value === null || "")
      return <div style={{ paddingTop: 10 }}>- - -</div>;
    if (valueType === "Loan")
      return (
        <div style={{ paddingTop: 10 }}>{"$ " + Number(value).toFixed(2)}</div>
      );

    return (
      <div
        style={{ paddingTop: 10 }}
        onClick={() =>
          navigate(`/projectview/${project_id}/${valueType}`, {
            relative: "route",
          })
        }
      >
        {valueType === "Files" && <Attachment />}
        {value}
      </div>
    );
  };

  const defaultColDef = {
    flex: 1,
    filter: true,
    singleClickEdit: true,
  };

  const [columnDefs] = React.useState<AgGridReactProps["columnDefs"]>([
    {
      field: "address",
      headerName: "Address",
      flex: 3,
      minWidth: 150,
      cellRenderer: ProjectCell,
      cellRendererParams: {
        valueType: "Draws",
      },
    },
    {
      field: "loanAmount",
      headerName: "Amount",
      flex: 1,
      minWidth: 150,
      cellRenderer: ProjectCell,
      cellRendererParams: {
        valueType: "Loan",
      },
    },
    {
      field: "Remaining",
      headerName: "Remaining",
      flex: 1,
      minWidth: 150,
      cellRenderer: ({ data: project }: { data: ProjectData }) => (
        <div style={{ paddingTop: 10 }}>
          {availableColumns.RemainingLooserBalance.derive(project, context)
            .formatted || "- - -"}
        </div>
      ),
    },
    {
      field: "loanIdentifier",
      headerName: "Loan Id",
      cellRenderer: ProjectCell,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "maturityDate",
      headerName: "Loan Maturity",
      flex: 2,
      minWidth: 150,
      cellRenderer: ({
        value,
      }: {
        data: ProjectData;
        value?: Date["getDate"];
      }) => {
        return (
          <div style={{ marginTop: 10 }}>{value?.toString().slice(0, 16)}</div>
        );
      },
    },
    {
      field: "health",
      headerName: "Health",
      flex: 1,
      minWidth: 100,
      cellRenderer: ({
        data: project,
        value,
      }: {
        data: ProjectData;
        value: ProjectData["health"];
      }) => {
        return (
          <div style={{ marginTop: 15 }}>
            <HealthIndicator
              projectId={project.project_id}
              health={value}
              hasLoan={Boolean(project.loanAmount)}
            />
          </div>
        );
      },
    },
    {
      field: "Inspection",
      flex: 2,
      minWidth: 100,

      cellRenderer: ({ data: project }: { data: ProjectData }) => {
        const { value } = deriveNextAction(project, {
          isConciergeInspector,
          availableInspectors,
          permissions,
        });
        return <div style={{ marginTop: 10 }}>{value}</div>;
      },
    },
    {
      field: "LastDraw",
      headerName: "Last Draw",
      minWidth: 100,
      flex: 1,
      cellRenderer: ({ data: project }: { data: ProjectData }) => {
        const GenerateDate = () => {
          const { mri } = project.summary || {};
          const lastDraw = mri && lastDrawDate(mri);
          const lastDrawTime = lastDraw?.getTime() ?? Number.NaN;
          if (Number.isNaN(lastDrawTime)) {
            return { value: Number.NaN };
          }
          const now = Date.now();
          return {
            value: (now - lastDrawTime) / 86400000,
          };
        };
        const value = GenerateDate();
        if (Number.isNaN(value.value) === true) {
          return <div style={{ marginTop: 10 }}>- - -</div>;
        }

        return (
          <div style={{ marginTop: 10 }}>{Math.round(value.value)} Days</div>
        );
      },
    },
    {
      field: "More",
      flex: 1,
      minWidth: 100,

      filter: false,
      cellRenderer: ({ data: project }: { data: ProjectData }) => {
        return (
          <div style={{ marginTop: 5 }}>
            <DotDotDotMenu
              projectId={project.project_id}
              status={project.project_status as ProjectStatus}
              onRequestDelete={
                permissions.canDeleteProjects
                  ? props.onRequestDelete
                  : undefined
              }
              onUpdateStatus={props.onUpdateStatus}
              pending={project.summary?.p ?? []}
            />
          </div>
        );
      },
    },
  ]);

  const features = useFeatures();
  const email = throwIfNot(useAuth()?.currentUserEmail, "email is required");
  const role = throwIfNot(useGetUserRole().user?.role, "role is required");
  const context = useColumnDerivationContext();

  const availableViews = React.useMemo(
    () => createViews({ features, context }),
    [context, features]
  );

  const requestedView =
    useMatch("/portfolio/:view")?.params.view?.toLowerCase() ?? "todo";
  const filteredProjects = React.useMemo(() => {
    const view = availableViews[requestedView as ViewID];
    if (view) {
      return projects.filter((project) => {
        return view.filter(project, { email, role, isConciergeInspector });
      });
    }

    logAnomaly(
      new Error(`unknown portfolio view "${requestedView}" requested`),
      { location }
    );
    return projects;
  }, [
    availableViews,
    email,
    isConciergeInspector,
    location,
    projects,
    requestedView,
    role,
  ]);

  const { setAdminData, setAdminChildren } = useAdminData();
  React.useEffect(() => {
    setAdminData((data) => ({
      ...data,
      projects: filteredProjects,
    }));
    return () =>
      setAdminData(({ filteredProjects, ...rest }) => {
        return rest;
      });
  }, [filteredProjects, setAdminData]);

  React.useEffect(() => {
    setAdminChildren((children) => ({
      ...children,
      bulkUserAdder: (
        <BulkUserAdder
          projects={filteredProjects.map((project) => ({
            ...project,
            users: project.users as EmailAddress[],
            img: project.img as string,
            location: project.location || null,
          }))}
        />
      ),
    }));
    return () => setAdminChildren(({ bulkUserAdder, ...children }) => children);
  }, [filteredProjects, setAdminChildren]);

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <HomeTabNavigator />
        <PortfolioCSVGenerator {...props} projects={filteredProjects} />
      </div>
      <div
        className="ag-theme-quartz"
        style={{
          width: "100%",
          height: "82vh",
          boxShadow: "0px 5px 8px #C0C0C0	",
          borderBottomLeftRadius: 30,
          borderBottomRightRadius: 30,
          minWidth: 500,
        }}
      >
        <AgGridReact
          rowHeight={rowHeight}
          rowData={filteredProjects}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          pagination={pagination}
          paginationPageSize={paginationPageSize}
          paginationPageSizeSelector={false}
        ></AgGridReact>
      </div>
      {!props.loading ? null : (
        <>{progressSpinner !== "none" ? <LinearProgress /> : null}</>
      )}
    </div>
  );
};

export default HomeTable;
