import React from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";

import { ifEnterKey, IfEnterKeyEventHandler } from "@lib/boilerplate";
import { useFocus } from "@lib/hooks/useFocus";
import { z } from "zod";
import { AllPersonInputsSchema } from "@lib/APITypes";
import { Controller, useFormContext } from "react-hook-form";
import { logAnomaly } from "@lib/ErrorLogging";

export const sharedStyles = {
  margin: {
    marginTop: "20px",
  },
};

const PersonWrapper = styled("div")({
  paddingRight: "5vw",
  paddingLeft: "5vw",
  textAlign: "center",
});
export { PersonWrapper };

const PossibleInputs = AllPersonInputsSchema;
// eslint-disable-next-line @typescript-eslint/no-redeclare
type PossibleInputs = z.infer<typeof PossibleInputs>;
export type PossibleFields = keyof PossibleInputs;

interface PersonFormProps {
  // TODO: consider removing `preload` from PersonForm - all current clients use RHF instead.
  preload?: {
    [K in PossibleFields]: PossibleInputs[K];
  };
  fields?: PossibleFields[];
  // schema: Schema;
  header?: string | JSX.Element;
  onLastFieldEntered?: IfEnterKeyEventHandler;
  hideEmail?: boolean;
}

// just a unity function to give it a type
const LayoutOrder = (arr: PossibleFields[]) => arr;
export const commonLayouts = {
  default: LayoutOrder(["first_name", "last_name", "email", "phone_number"]),

  companyFirst: LayoutOrder([
    "company",
    "first_name",
    "last_name",
    "email",
    "phone_number",
  ]),
  companyLast: LayoutOrder([
    "first_name",
    "last_name",
    "email",
    "phone_number",
    "company",
  ]),
} as const;

const labels: Record<PossibleFields, string> = {
  company: "Company",
  email: "Email",
  first_name: "First Name",
  last_name: "Last Name",
  phone_number: "Phone",
  business_license: "business License",
};

if (Object.keys(labels).length > 6) {
  // SEE BELOW useFocus calls in render
  logAnomaly(
    new Error("Not enough focus shifters; feel free to edit and bump up"),
    { labels }
  );
}

export default function PersonForm({
  preload,
  header,
  fields = commonLayouts.default.slice(),
  onLastFieldEntered,
  hideEmail,
}: PersonFormProps): JSX.Element {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  // 6 is enough for all fields RN; may need to go up
  const [ref1, focusRef1] = useFocus();
  const [ref2, focusRef2] = useFocus();
  const [ref3, focusRef3] = useFocus();
  const [ref4, focusRef4] = useFocus();
  const [ref5, focusRef5] = useFocus();
  const [ref6, focusRef6] = useFocus();

  const refs = [ref1, ref2, ref3, ref4, ref5, ref6];
  const focusNextRefs = [
    focusRef2,
    focusRef3,
    focusRef4,
    focusRef5,
    focusRef6,
    focusRef1,
  ];

  const effectiveFields = hideEmail
    ? fields.filter((f) => f !== "email")
    : fields;
  return (
    <PersonWrapper>
      {typeof header === "string" ? <h1>{header}</h1> : header}
      <div>
        {effectiveFields.map((name, i) => (
          <Controller
            defaultValue={preload?.[name]}
            name={name}
            key={name}
            control={control}
            render={({ field }) => (
              <FormControl
                variant="standard"
                fullWidth
                sx={sharedStyles.margin}
              >
                <TextField
                  variant="standard"
                  {...field}
                  required
                  fullWidth
                  label={labels[name]}
                  inputRef={refs[i]}
                  onKeyDown={ifEnterKey(
                    i === effectiveFields.length - 1 && onLastFieldEntered
                      ? onLastFieldEntered
                      : focusNextRefs[i]
                  )}
                  helperText={errors[name]?.message as string}
                  error={Boolean(errors[name])}
                />
              </FormControl>
            )}
          />
        ))}
      </div>
    </PersonWrapper>
  );
}
