import React from "react";
import { styled } from "@mui/material/styles";
import { useAppContext } from "@lib/UserContext";
// import BottomNavigation from "@mui/material/BottomNavigation";
// import BottomNavigationAction from "@mui/material/BottomNavigationAction";
// import CreditCardIcon from "@mui/icons-material/CreditCard";
// import DateRangeIcon from "@mui/icons-material/DateRange";
import ProjectCards from "./ProjectCards";
import ProjectGrid from "./ProjectGrid";
import { ViewID } from "./PortfolioViews";
import { useBackend } from "@lib/hooks";

import useSWRInfinite from "swr/infinite"; // TODO: should I be avoiding using this directly? but ATM we are the only caller of getProjects, so...
import { CircularProgress } from "@mui/material";
import { ConfirmDialog } from "../../Components/ConfirmDialog";
import { BackendGetProjectsResponse, ProjectStatus } from "@lib/APITypes";
import { throwIfNot } from "@lib/util/throwIfNot";
import { useAuth } from "@lib/hooks/useAuth";
import { useNewUIHook } from "@/Lib/hooks/useNewUI";
import HomeTable from "@/Pages/Dashboard/Table/HomeTable";
import { ProjectsAndLoansHeader } from "./ProjectsAndLoansHeader";

const PAGE_SIZE = 100;

const PREFIX = "Routes";

const classes = {
  root: `${PREFIX}-root`,
  wrapper: `${PREFIX}-wrapper`,
};

const Root = styled("div")({
  [`& .${classes.root}`]: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    boxShadow: "0px -1px 1px gray",
  },
  [`& .${classes.wrapper}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "10vh",
  },
});

export default function Home({
  cards,
  portfolio,
  view,
}: {
  cards?: boolean;
  portfolio?: boolean;
  view?: ViewID;
}): JSX.Element {
  const { role } = useAppContext();
  const email = throwIfNot(useAuth().currentUserEmail, "email is required");
  const { useNewUI } = useNewUIHook();
  const { getProjects, deleteProject, editProjectDetails } = useBackend();
  const getKey = (
    pageIndex: number,
    previousPageData: BackendGetProjectsResponse
  ) => {
    if (previousPageData && !previousPageData.length) {
      // at end
      return null;
    }

    const baseKey = {
      url: `/projects/email=${email}&role=${role}&summary=1&health=1`,
      before: undefined,
      limit: PAGE_SIZE,
    };

    if (pageIndex === 0) {
      return baseKey;
    }

    return {
      ...baseKey,
      before: previousPageData.slice(-1)[0].created_at,
    };
  };

  const {
    data: projectPages,
    error,
    mutate: mutateProjects,
    isValidating,
    setSize,
  } = useSWRInfinite(
    getKey,
    (key) => {
      return getProjects({
        email,
        role,
        summary: true,
        health: true,
        before: key.before,
        limit: key.limit,
      });
    },
    { revalidateFirstPage: false }
  );
  const projects = React.useMemo(
    () =>
      projectPages
        ? ([] as BackendGetProjectsResponse).concat(...projectPages)
        : [],
    [projectPages]
  );

  const lastProjectPage = projectPages?.slice(-1)[0];
  const finished = lastProjectPage && lastProjectPage.length < PAGE_SIZE;
  const lastProject = lastProjectPage?.slice(-1)[0];
  const lastCreated = lastProject?.created_at;

  React.useEffect(() => {
    if (lastCreated && !finished) {
      setSize((size) => size + 1);
    }
  }, [finished, lastCreated, setSize]);

  const [projectToDelete, setProjectToDelete] = React.useState<
    string | undefined
  >();
  const removeProject = async (projectId: string) => {
    // optimistic update
    mutateProjects(
      projectPages?.map((page) =>
        page.filter(({ project_id }) => project_id !== projectId)
      ),
      false
    );

    // do it
    await deleteProject({ projectId, role });

    // revalidate
    mutateProjects();
  };

  const updateProjectStatus = React.useCallback(
    async (projectId: string, newStatus: ProjectStatus) => {
      if (projects) {
        // optimistic update
        mutateProjects(
          projectPages?.map((page) =>
            page.map((p) =>
              p.project_id === projectId
                ? { ...p, project_status: newStatus }
                : p
            )
          ),
          false
        );

        // do it
        await editProjectDetails(projectId, "status", {
          status: newStatus,
        });

        // refresh (we only get summaries on getProjects, even though editProjectDetails returns the updated result)
        mutateProjects();
      }
    },
    [editProjectDetails, mutateProjects, projectPages, projects]
  );

  const handleRequestDelete = React.useCallback(
    (projectId: string): void => setProjectToDelete(projectId),
    []
  );

  const projectsWithLoans = projects.filter((p) => p.loanAmount);
  const totalLoans = projectsWithLoans.reduce(
    (sum, project) => sum + (project.loanAmount ?? 0),
    0
  );

  return (
    <Root>
      <div className={classes.wrapper}>
        {projects ? (
          <>
            {cards ? (
              <div>
                <ProjectCards
                  data={projects}
                  callBack={mutateProjects}
                  onRequestDelete={handleRequestDelete}
                />
              </div>
            ) : null}
            {portfolio ? (
              <>
                <ProjectsAndLoansHeader
                  totalLoans={totalLoans}
                  totalBalance={
                    totalLoans -
                    projectsWithLoans.reduce(
                      (sum, project) => sum + Number(project.budget_spent),
                      0
                    )
                  }
                  projectCount={projects.length}
                />
                {useNewUI ? (
                  <HomeTable
                    projects={projects}
                    loading={isValidating}
                    onRequestDelete={handleRequestDelete}
                    onUpdateStatus={updateProjectStatus}
                  />
                ) : (
                  <ProjectGrid
                    data={projects}
                    loading={isValidating}
                    onRequestDelete={handleRequestDelete}
                    onUpdateStatus={updateProjectStatus}
                    viewId={view}
                  />
                )}
              </>
            ) : null}
          </>
        ) : isValidating ? (
          <CircularProgress variant="indeterminate" />
        ) : error ? (
          <p>Oops! Something went wrong fetching your projects</p>
        ) : (
          <p>No projects</p>
        )}
        <ConfirmDialog
          title="Delete Project?"
          open={Boolean(projectToDelete)}
          setOpen={(open) => {
            if (!open) {
              setProjectToDelete(undefined);
            }
          }}
          onConfirm={() => {
            if (projectToDelete) {
              removeProject(projectToDelete);
              setProjectToDelete(undefined);
            }
          }}
        >
          Are you sure you want to delete this project? THERE IS NO UNDO AND IT
          WILL BE DELETED PERMANENTLY
        </ConfirmDialog>
      </div>
      {/* <BottomNavigation value={view} className={classes.root} showLabels>
        <BottomNavigationAction
          label="Card View"
          value="cardview"
          icon={<CreditCardIcon />}
          component={Link}
          to="/cards"
        />
        <BottomNavigationAction
          label="Portfolio View"
          value="portfolio"
          icon={<DateRangeIcon />}
          component={Link}
          to="/portfolio"
        />
      </BottomNavigation> */}
    </Root>
  );
}
