import { graphql, useFragment as getFragmentData } from "@/graphql-generated";

import { useQuery } from "urql";
import { Box, CircularProgress, Typography } from "@mui/material";
import { CheckinRequest, CheckinsFragment } from "./ActivityPage";

export const listPortfolioCheckinsQuery = graphql(/* GraphQL */ `
  query listPortfolioCheckinsQuery(
    $first: Float
    $last: Float
    $before: Cursor
    $after: Cursor
  ) {
    projects(first: $first, last: $last, before: $before, after: $after) {
      # pageInfo {
      #   hasNextPage
      #   endCursor
      # }
      edges {
        node {
          #   project_id
          address
          ...CheckinsFragment
        }
      }
    }
  }
`);

const PortfolioActivityPage = () => {
  const [{ data, fetching, error }] = useQuery({
    query: listPortfolioCheckinsQuery,
  });

  return (
    <div style={{ width: "81vw", height: "50vh", flexDirection: "row" }}>
      {error ? (
        <div>
          <Typography variant="h3">Oh Snap!</Typography>
          <Typography>{JSON.stringify(error, undefined, 2)}</Typography>
        </div>
      ) : fetching ? (
        <CircularProgress />
      ) : (
        data?.projects.edges.flatMap(({ node: { address, ...node } }) => {
          const { checkins: requests } = getFragmentData(
            CheckinsFragment,
            node
          );

          return requests.edges.length ? (
            <Box ml={1}>
              <Typography variant="h4">{address}</Typography>
              {requests.edges.map(({ node }) => (
                <Box ml={2}>
                  <CheckinRequest checkin={node} />
                </Box>
              ))}
            </Box>
          ) : (
            []
          );
        })
      )}
    </div>
  );
};

export default PortfolioActivityPage;
