import { S3ProviderListOutput } from "@aws-amplify/storage";
import { Storage } from "aws-amplify";
import { v4 as uuidV4 } from "uuid";
import { NonEmptyString } from "io-ts-types";
import { StoredFile } from "@project-centerline/project-centerline-api-types";

interface HasStringKey {
  key: string;
}

export type ListResult = S3ProviderListOutput;

export async function s3Upload(
  file: File,
  prefix?: string,
  progressCallback?: (arg: { loaded: number; total: number }) => void
): Promise<StoredFile & { file: File }> {
  const maybePrefixFolder = prefix ? `${prefix}/` : "";
  // we prefix with uuid to avoid collisions, and make it a "folder" so the browser names download same as upload
  const filename = `${maybePrefixFolder}${uuidV4()}/${file.name}`;

  const stored = (await Storage.put(filename, file, {
    contentType: file.type,
    progressCallback,
  })) as HasStringKey;

  // console.log({ stored });

  return {
    file,
    path: file.name as NonEmptyString,
    storageKey: stored.key as NonEmptyString,
  };
}

export function s3ListFiles(storageKey: string): Promise<ListResult> {
  return Storage.list(storageKey, { level: "public", pageSize: "ALL" });
}
