import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { TableCellProps } from "@mui/material/TableCell";
import {
  differenceInDays,
  formatDistanceStrict,
  formatDistanceStrictWithOptions,
} from "date-fns/fp";
import { HealthIndicator, HealthLabel } from "./HealthIndicator";
import { LoanInfoTooltip } from "./LoanInfoTooltip";
import {
  BackendGetProjectsResponseEntry,
  EntityFeatures,
  ProjectStatus,
} from "@lib/APITypes";
import { LoanType } from "@lib/LoanType";
import { AppPermissions, usePermissions } from "@lib/hooks";
import { MoneyFormatter } from "@lib/Money";
import { fullAddress, replaceInvalidNumberWith } from "@lib/util";
import HistoryIcon from "@mui/icons-material/History";
import InfoView from "@/Pages/ProjectView/Info/InfoView";
import { ProjectViewTabs } from "@/Pages/ProjectView";
import { format } from "date-fns";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { InspectorInfo, useInspectors } from "@lib/hooks/useInspectors";
import {
  deriveNextAction,
  lastDrawDate,
} from "@/Pages/Dashboard/projectStatusUtils";
import React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useEntityFeatures } from "@/Lib/hooks/useEntityFeatures";
import { byProp } from "@/Lib/misc";

/**
 * Things like permissions that are not part of the project but that the derivation function might need to know
 */
export interface DerivationFunctionContext {
  urlBase: string;
  permissions: AppPermissions;
  entityFeatures: EntityFeatures;
  onRequestDelete?: (projectId: string) => void;
  onUpdateStatus?: (
    projectId: string,
    newStatus: ProjectStatus,
    pending: unknown[]
  ) => void;
  isConciergeInspector: (arg: { email: string }) => boolean;
  isFullConciergeInspector: (arg: { email: string }) => boolean;
  availableInspectors: Record<string, Pick<InspectorInfo, "email" | "vendor">>;
}

/**
 * Calculates nd returns different representations of a give project column
 * @param ingredients A project from the backend
 * @param extra Things like permissions that are not part of the project but that we might need to know
 * @returns result.value number|string if possible
 * @returns result.formatted: a string (e.g. formatted $ value) or element (e.g. health icon)
 * @returns result.csf: a string formatted for use in a CSV export
 */
type DerivationFunction = (
  ingredients: BackendGetProjectsResponseEntry,
  extra: DerivationFunctionContext
) => {
  value: number | string;
  formatted: string | JSX.Element;
  csv?: number | string;
};

/**
 * Instructions how to generate a given column from a project (whether we display that column or not)
 */
export interface DisplayColumn {
  label: string | JSX.Element;
  numeric: boolean;
  disablePadding?: boolean;
  derive: DerivationFunction;
  sortFormatted?: boolean;
  linkToProjectView?: string; // TODO: projectview should export topics+sub-tabs
  csvHeader?: string;
  summable?: boolean;
  align?: TableCellProps["align"];
  alignHeader?: TableCellProps["align"];
  /** show sorters in table heading? DEFAULT IS TRUE IF UNSPECIFIED */
  sortable?: boolean; // NB: gets defaulted TRUE in `Column` identity function
}

const dateColumnCommon = {
  props: {
    numeric: true,
    sortFormatted: true, // because we dropped the time portion for display, and the date format already sorts right as strings.
  },
  format: (epochMs?: number) =>
    epochMs ? format(new Date(epochMs), "yyyy-MM-dd") : "---",
  formatForCSV: (epochMs?: number) =>
    epochMs ? format(new Date(epochMs), "M/d/yyyy") : "",
};

function adjustedTaskValue({
  totalTaskValue,
  totalActiveTaskValue,
  loanType,
}: {
  totalTaskValue: number;
  totalActiveTaskValue: number;
  loanType: LoanType;
}) {
  return loanType === LoanType.LoC ? totalActiveTaskValue : totalTaskValue;
}

const effectiveAmount = ({
  loanAmount,
  totalTaskValue,
  totalActiveTaskValue,
  loanType,
}: Pick<
  BackendGetProjectsResponseEntry,
  "loanAmount" | "totalTaskValue" | "totalActiveTaskValue" | "loanType"
>) =>
  loanAmount ||
  adjustedTaskValue({ totalActiveTaskValue, totalTaskValue, loanType }) ||
  NaN;

/*
 * This list defines all the possible columns to be displayed, which for now is the same as the existing list :-) but
 * which will grow as time goes on. Then a selector (below, `const views = ...`) chooses a subset and ordering. The
 * `derive` member generates a "value" (which is what is sorted on by default) from an object based on the project
 * record, as well as (rarely used but required for `health`) the entire response from the server (as `__response`).
 * That "value" is fed to `format` for display. For things that *should* be sorted on their string values - like, well,
 * things that are natively strings :-) - you can set `sortFormatted` to be truthy.
 */
const Column = (generic: DisplayColumn) => ({
  ...generic,
  sortable: generic.sortable ?? true,
});
export const availableColumns = {
  Name: Column({
    numeric: false,
    label: "Project Name",
    derive: ({ name, project_id }, { urlBase }) => ({
      value: name,
      formatted: name,
      csv: `=HYPERLINK(""${urlBase}/projectview/${project_id}"", ""${name}"")`,
    }),
    linkToProjectView: ProjectViewTabs.Tasks,
  }), // TODO: #65 global styles/themes
  FullAddress: Column({
    numeric: false,
    label: "Full Address",
    derive: (project, { urlBase }) => {
      const value = fullAddress(project);
      return {
        value,
        formatted: value,
        csv: value,
      };
    },
    linkToProjectView: ProjectViewTabs.Tasks,
  }),
  NumDraws: Column({
    numeric: true,
    label: "# Draws",
    derive: ({ summary }) => {
      const { draws } = summary || {};
      const value =
        (draws?.Approved ?? 0) + (draws?.Pending ?? 0) + (draws?.Rejected ?? 0);
      return {
        value,
        formatted: String(value) ?? "---",
        csv: value ?? "",
      };
    },
  }),
  LoanAmount: Column({
    numeric: true,
    label: InfoView.Tabs.Loan,
    derive: ({ loanAmount }) => {
      const value = loanAmount ?? Number.NaN;
      return {
        value,
        formatted: Number.isNaN(value) ? "---" : MoneyFormatter.format(value),
        csv: Number.isNaN(value) ? "" : MoneyFormatter.format(value),
      };
    },
    linkToProjectView: InfoView.Tabs.Loan,
    summable: true,
  }),
  Amount: Column({
    numeric: true,
    label: "Amount",
    derive: (project) => {
      const value = effectiveAmount(project);
      return {
        value,
        formatted: Number.isNaN(value) ? "---" : MoneyFormatter.format(value),
        csv: Number.isNaN(value) ? "" : MoneyFormatter.format(value),
      };
    },
    linkToProjectView: InfoView.Tabs.Loan,
    summable: true,
  }),
  EffectiveBudget: Column({
    numeric: true,
    label: "Effective Budget",
    derive: ({ effectiveBudget }: { effectiveBudget: number }) => ({
      value: effectiveBudget,
      formatted: MoneyFormatter.format(effectiveBudget),
    }),
    summable: true,
  }),
  TotalTaskValue: Column({
    numeric: true,
    label: "Total of Tasks",
    derive: ({
      totalTaskValue,
      loanType,
    }: Pick<
      BackendGetProjectsResponseEntry,
      "totalTaskValue" | "loanType"
    >) => {
      const value = totalTaskValue;
      return { value, formatted: MoneyFormatter.format(value) };
    },
    summable: true,
  }),
  TotalActiveTaskValue: Column({
    numeric: true,
    label: "Total of Active Tasks",
    derive: ({
      totalTaskValue,
      totalActiveTaskValue,
      loanType,
    }: Pick<
      BackendGetProjectsResponseEntry,
      "totalTaskValue" | "totalActiveTaskValue" | "loanType"
    >) => {
      const value = adjustedTaskValue({
        loanType,
        totalTaskValue,
        totalActiveTaskValue,
      });
      return { value, formatted: MoneyFormatter.format(value) };
    },
    summable: true,
  }),
  EndDate: Column({
    label: "Est. Completion",
    derive: ({ end_date }: { end_date: string }) => {
      const value = new Date(end_date).getTime();
      const formatted = dateColumnCommon.format(value);
      return { value, formatted, csv: dateColumnCommon.formatForCSV(value) };
    },
    ...dateColumnCommon.props,
    linkToProjectView: "Project", // TODO: https://projectcenterline.slack.com/archives/C01F1S4GS9J/p1619135702012100?thread_ts=1619135542.012000&cid=C01F1S4GS9J
  }),
  PercentComplete: Column({
    numeric: true,
    label: "Complete",
    derive: ({
      effectiveBudget,
      budgetRemaining,
    }: {
      effectiveBudget: number;
      budgetRemaining: number;
    }) => {
      const value =
        ((effectiveBudget - budgetRemaining) / effectiveBudget) * 100;
      const formatted = `${replaceInvalidNumberWith(0)(Math.round(value))}%`;
      return { value, formatted };
    },
  }),
  PercentBudgetRemaining: Column({
    numeric: true,
    label: "% Remaining",
    derive: ({
      effectiveBudget,
      budgetRemaining,
    }: {
      effectiveBudget: number;
      budgetRemaining: number;
    }) => {
      const value = (budgetRemaining / effectiveBudget) * 100;
      const formatted = `${replaceInvalidNumberWith("0")(Math.round(value))}%`;
      return { value, formatted };
    },
  }),
  LoanMaturityDate: Column({
    label: "Loan Maturity",
    derive: ({ maturityDate }) => {
      const value = maturityDate?.getTime() ?? 0;
      const formatted = dateColumnCommon.format(value);
      return { value, formatted, csv: dateColumnCommon.formatForCSV(value) };
    },
    ...dateColumnCommon.props,
    linkToProjectView: InfoView.Tabs.Loan,
  }),
  LoanStartDate: Column({
    label: "Loan Start",
    derive: ({ startDate }) => {
      const value = startDate?.getTime() ?? 0;
      const formatted = dateColumnCommon.format(value);
      return { value, formatted, csv: dateColumnCommon.formatForCSV(value) };
    },
    ...dateColumnCommon.props,
    linkToProjectView: InfoView.Tabs.Loan,
  }),
  Health: Column({
    label: <HealthLabel />,
    derive: ({
      health,
      project_id,
      loanAmount,
    }: BackendGetProjectsResponseEntry) => ({
      value: health ? health[0]?.severity ?? 0 : -1,
      formatted: (
        <HealthIndicator
          health={health}
          projectId={project_id}
          hasLoan={!isNaN(loanAmount ?? Number.NaN)}
        />
      ),
      csv: health?.[0]?.color ?? "",
    }),
    numeric: true,
    csvHeader: "Health",
  }),
  HealthMetric: Column({
    label: <HealthLabel />,
    derive: ({ health }) => {
      const value = health ? health?.[0]?.severity ?? 0 : -1;
      return { value, formatted: String(value) };
    },
    numeric: true,
    csvHeader: "Health Danger Score",
  }),
  RemainingLooserBalance: Column({
    label: "Remaining",
    derive: ({
      loanAmount,
      loanType,
      budget_spent,
      totalTaskValue,
      totalActiveTaskValue,
      project_id,
      disbursedAtClosing,
    }: Pick<
      BackendGetProjectsResponseEntry,
      | "loanAmount"
      | "loanType"
      | "budget_spent"
      | "totalTaskValue"
      | "totalActiveTaskValue"
      | "project_id"
      | "disbursedAtClosing"
    >) => {
      const topLine = effectiveAmount({
        loanAmount,
        loanType,
        totalTaskValue,
        totalActiveTaskValue,
      });
      const uglyLoCAdjustment =
        loanType === LoanType.LoC ? totalTaskValue - totalActiveTaskValue : 0;
      const balance = isNaN(topLine)
        ? NaN
        : topLine +
          uglyLoCAdjustment -
          Number(budget_spent) -
          (disbursedAtClosing || 0);
      return {
        value: balance,
        formatted: Number.isNaN(balance) ? (
          <LoanInfoTooltip
            projectId={project_id}
            description="Remaining balance"
            hasLoan={!isNaN(loanAmount ?? Number.NaN)}
          >
            ---
          </LoanInfoTooltip>
        ) : (
          MoneyFormatter.format(balance)
        ),
        csv: Number.isNaN(balance) ? "" : MoneyFormatter.format(balance),
      };
    },
    numeric: true,
    summable: true,
  }),
  RemainingLoanBalance: Column({
    label: "Loan Balance",
    derive: ({ loanAmount, budget_spent, project_id }) => {
      const safeLoanAmount = loanAmount ?? Number.NaN;
      const value = isNaN(safeLoanAmount)
        ? NaN
        : safeLoanAmount - Number(budget_spent);
      return {
        value,
        formatted: Number.isNaN(value) ? (
          <LoanInfoTooltip
            projectId={project_id}
            description="Balance"
            hasLoan={!isNaN(loanAmount ?? Number.NaN)}
          >
            ---
          </LoanInfoTooltip>
        ) : (
          MoneyFormatter.format(value)
        ),
        csv: MoneyFormatter.format(value),
      };
    },
    numeric: true,
    summable: true,
  }),
  RemainingTaskBalance: Column({
    label: "Task Balance",
    derive: ({
      totalTaskValue,
      totalActiveTaskValue,
      loanType,
      budget_spent,
      project_id,
    }) => {
      const actingTotalTaskValue = adjustedTaskValue({
        totalTaskValue,
        totalActiveTaskValue,
        loanType,
      });
      const value = isNaN(actingTotalTaskValue)
        ? NaN
        : actingTotalTaskValue - Number(budget_spent);
      return {
        value,
        formatted: Number.isNaN(value) ? (
          <LoanInfoTooltip
            projectId={project_id}
            description="Tasks"
            hasLoan={!isNaN(actingTotalTaskValue)}
          >
            ---
          </LoanInfoTooltip>
        ) : (
          MoneyFormatter.format(value)
        ),
        csv: MoneyFormatter.format(value),
      };
    },
    numeric: true,
    summable: true,
  }),
  LoanIdentifier: Column({
    derive: ({ loanIdentifier }) => ({
      value: loanIdentifier ?? "",
      formatted: loanIdentifier ?? "---",
      csv: loanIdentifier ?? "",
    }),
    label: "Loan Id",
    numeric: false,
    linkToProjectView: InfoView.Tabs.Loan,
    sortFormatted: true,
    alignHeader: "right",
  }),
  LoanType: Column({
    derive: ({ loanType }) => ({ value: loanType, formatted: loanType }),
    label: "Loan Type",
    numeric: false,
    linkToProjectView: InfoView.Tabs.Loan,
    sortFormatted: true,
  }),
  ProjectId: Column({
    derive: ({ project_id }) => ({ value: project_id, formatted: project_id }),
    label: "Project Id",
    numeric: false,
    linkToProjectView: ProjectViewTabs.Tasks,
    sortFormatted: true,
  }),
  Status: Column({
    derive: ({ project_status }) => ({
      value: project_status,
      formatted: project_status,
    }),
    label: "Project Status",
    numeric: false,
    linkToProjectView: ProjectViewTabs.Tasks,
    sortFormatted: true,
  }),
  Link: Column({
    derive: ({ project_id }, { urlBase }) => {
      const value = `${urlBase}/projectview/${project_id}`;
      return { value, formatted: value };
    },
    label: "Link",
    numeric: false,
    sortFormatted: true,
    // deriveCSV: ({ project_id }, { protocol, host }) =>
    //   `=HYPERLINK(""${protocol}//${host}/projectview/${project_id}"")`,
  }),
  Actions: Column({
    label: <HistoryIcon />,
    derive: deriveNextAction,
    numeric: false,
    align: "left",
    alignHeader: "center",
    csvHeader: "Action",
  }),
  Custom1: Column({
    label: "Investor", // TODO: feature flag sensitive
    derive: ({ custom_1 }) => ({
      value: custom_1 ?? "",
      formatted: custom_1 ?? "",
    }),
    align: "center",
    alignHeader: "center",
    numeric: false,
  }),
  DotDotDot: Column({
    label: "More",
    alignHeader: "right",
    derive: (
      { project_id, project_status, summary },
      { onRequestDelete, onUpdateStatus, permissions }
    ) => ({
      value: "-",
      formatted: (
        <DotDotDotMenu
          projectId={project_id}
          status={project_status as ProjectStatus}
          onRequestDelete={
            permissions.canDeleteProjects ? onRequestDelete : undefined
          }
          onUpdateStatus={onUpdateStatus}
          pending={summary?.p ?? []}
        />
      ),
      csv: "(you should not be seeing this)",
    }),
    numeric: false,
    sortable: false,
  }),
  Delete: Column({
    label: " ",
    derive: ({ project_id }, { onRequestDelete }) => ({
      value: "-",
      formatted: (
        <IconButton onClick={() => onRequestDelete?.(project_id)} size="large">
          <DeleteForeverIcon />
        </IconButton>
      ),
      csv: "(you should not be seeing this)",
    }),
    numeric: false,
  }),
  SearchAttributes: Column({
    derive: ({
      address,
      city,
      custom_1,
      loanContact,
      loanBorrowerOverride,
      loanIdentifier,
      name,
      project_id,
      zipcode, // spell-checker:ignore zipcode
      users,
    }) => {
      const value = [
        address,
        city,
        custom_1,
        loanContact,
        loanBorrowerOverride,
        loanIdentifier,
        name,
        project_id,
        zipcode,
      ]
        .concat(users)
        .join(":");
      return {
        value,
        formatted: value,
      };
    },
    label: "search",
    numeric: false,
  }),
  DaysSinceLastDraw: Column({
    derive: ({ summary }) => {
      const { mri } = summary || {};
      const lastDraw = mri && lastDrawDate(mri);
      const lastDrawTime = lastDraw?.getTime() ?? Number.NaN;
      if (Number.isNaN(lastDrawTime)) {
        return { value: Number.NaN, formatted: "-", csv: "" };
      }

      const now = Date.now();
      return {
        value: now - lastDrawTime,
        formatted: String(differenceInDays(lastDrawTime)(now)),
        csv: differenceInDays(lastDrawTime)(now),
      };
    },
    label: <Box style={{ wordWrap: "break-word" }}>Days Since Last Draw</Box>,
    csvHeader: "Days since last Draw",
    numeric: true,
  }),
  DaysInDataSinceLastDraw: Column({
    derive: ({ summary }) => {
      const { mri } = summary || {};
      const lastDraw = mri && lastDrawDate(mri);
      const lastDrawTime = lastDraw?.getTime() ?? Number.NaN;
      if (Number.isNaN(lastDrawTime)) {
        return { value: Number.NaN, formatted: "-" };
      }

      const now = Date.now();
      return {
        value: now - lastDrawTime,
        formatted: String(formatDistanceStrict(lastDrawTime)(now)),
      };
    },
    label: <Box style={{ wordWrap: "break-word" }}>Last Draw</Box>,
    numeric: true,
  }),
  DaysAgoInDataSinceLastDraw: Column({
    derive: ({ summary }) => {
      const { mri } = summary || {};
      const lastDraw = mri && lastDrawDate(mri);
      const lastDrawTime = lastDraw?.getTime() ?? Number.NaN;
      if (Number.isNaN(lastDrawTime)) {
        return { value: Number.NaN, formatted: "-" };
      }

      const now = Date.now();
      return {
        value: now - lastDrawTime,
        formatted: String(
          formatDistanceStrictWithOptions({ addSuffix: true })(now)(
            lastDrawTime
          )
        ),
      };
    },
    label: <Box style={{ wordWrap: "break-word" }}>Last Draw</Box>,
    numeric: true,
  }),
};

export type ColumnId = keyof typeof availableColumns;

export const DotDotDotMenu: (props: {
  projectId: string;
  status: ProjectStatus;
  onRequestDelete?: (projectId: string) => void;
  onUpdateStatus?: (
    projectId: string,
    newStatus: ProjectStatus,
    pending: unknown[]
  ) => void;
  pending: unknown[];
}) => JSX.Element = ({
  onUpdateStatus,
  projectId,
  status,
  onRequestDelete,
  pending,
}) => {
  const [taskMenuAnchor, setTaskMenuAnchor] = React.useState<
    HTMLElement | undefined
  >();
  const closeMenu = () => {
    setTaskMenuAnchor(undefined);
  };

  return (
    <div>
      <IconButton
        tabIndex={-1}
        aria-label="more actions"
        onClick={({ currentTarget }) => {
          setTaskMenuAnchor(currentTarget);
        }}
        size="large"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={taskMenuAnchor}
        keepMounted
        open={Boolean(taskMenuAnchor)}
        onClose={closeMenu}
      >
        {onUpdateStatus ? (
          <MenuItem
            onClick={() => {
              onUpdateStatus(
                projectId,
                status === ProjectStatus.InProgress
                  ? ProjectStatus.Complete
                  : ProjectStatus.InProgress,
                pending
              );
              closeMenu();
            }}
          >
            {status === ProjectStatus.InProgress ? "Complete" : "Un-Complete"}
          </MenuItem>
        ) : null}

        {onRequestDelete ? (
          <MenuItem
            onClick={() => {
              onRequestDelete(projectId);
              closeMenu();
            }}
          >
            Delete
          </MenuItem>
        ) : null}
      </Menu>
    </div>
  );
};

export const useColumnDerivationContext = () => {
  const {
    isConciergeInspector,
    isFullConciergeInspector,
    availableInspectors: inspectorsList,
  } = useInspectors();
  const { protocol, host } = window.location;
  const permissions = usePermissions();
  const entityFeatures = useEntityFeatures();

  const availableInspectors = React.useMemo(
    () => byProp(inspectorsList)("email"),
    [inspectorsList]
  );

  return React.useMemo(
    () => ({
      urlBase: `${protocol}//${host}`,
      permissions,
      entityFeatures,
      isConciergeInspector,
      isFullConciergeInspector,
      availableInspectors,
    }),
    [
      availableInspectors,
      entityFeatures,
      host,
      isConciergeInspector,
      isFullConciergeInspector,
      permissions,
      protocol,
    ]
  );
};
