import React from "react";
import { useFormContext } from "react-hook-form";
import { AddressInputs } from "./AddressForm";
import { dummyKeyboardEvent, IfEnterKeyEventHandler } from "@/Lib/boilerplate";
import { Status } from "@googlemaps/react-wrapper"; // spell-checker:ignore googlemaps
import ErrorPage from "@/Pages/ErrorPage";
import { CircularProgress } from "@mui/material";
import { throwIfNot } from "@/Lib/util/throwIfNot";

export const mapsApiKey = throwIfNot(
  process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  "Missing Google Maps API Key"
);

const supportedCountryCodeRestrictions = {
  country: ["us", "ca", "vi", "vg", "pr"],
};

export const placesOptions = {
  componentRestrictions: supportedCountryCodeRestrictions,
  fields: ["address_component", "geometry", "formatted_address"],
  types: ["address"],
};

export const renderGoogleNotReady = (status: Status) => {
  if (status === Status.FAILURE) {
    return <ErrorPage />;
  }

  return <CircularProgress />;
};

const getFullAddress = async (
  autoComplete: google.maps.places.Autocomplete
) => {
  const place = autoComplete.getPlace();

  let address1,
    locality,
    adminArea1Short,
    adminArea1Long,
    countryShort,
    countryLong,
    postalCode = "";

  // Get each component of the address from the place details,
  for (const component of place.address_components || []) {
    const componentType = component.types[0];

    if (componentType === "street_number") {
      address1 = component.long_name;
    }
    if (componentType === "route") {
      address1 = `${address1} ${component.long_name}`;
    }
    if (componentType === "locality") {
      locality = component.long_name;
    }
    if (componentType === "administrative_area_level_1") {
      adminArea1Short = component.short_name;
      adminArea1Long = component.long_name;
    }
    if (componentType === "postal_code") {
      postalCode = component.long_name;
    }
    if (componentType === "postal_code_suffix") {
      postalCode = `${postalCode}-${component.long_name}`;
    }
    if (componentType === "country") {
      countryShort = component.short_name;
      countryLong = component.long_name;
    }
  }

  const resAddress = {
    address1: address1,
    locality: locality,
    adminArea1Short: adminArea1Short,
    adminArea1Long: adminArea1Long,
    postalCode: postalCode,
    countryShort: countryShort,
    countryLong: countryLong,
    formatted: place.formatted_address,
  };

  return resAddress;
};

export const useGoogleAutoComplete = ({
  addressEntryRef,
  onGoogleMatch,
  onLastFieldEntered,
}: {
  addressEntryRef: React.MutableRefObject<HTMLInputElement | undefined>;
  onGoogleMatch?: (arg: {
    type: "incomplete" | "complete" | "fail";
    formatted: string;
  }) => void;
  onLastFieldEntered: IfEnterKeyEventHandler;
}) => {
  const autoCompleteRef = React.useRef<google.maps.places.Autocomplete>();
  const { setValue } = useFormContext<AddressInputs>();

  React.useEffect(() => {
    const Autocomplete = window.google?.maps?.places?.Autocomplete;
    if (addressEntryRef.current && !autoCompleteRef.current && Autocomplete) {
      const autocomplete = new Autocomplete(
        addressEntryRef.current,
        placesOptions
      );

      autocomplete.addListener("place_changed", async () => {
        const completionResult = await getFullAddress(autocomplete);
        const summaryLine = completionResult?.formatted || "";
        const addressLine = completionResult?.address1 || summaryLine;
        const city = completionResult?.locality || "";
        const state = completionResult?.adminArea1Long || "";
        const zipcode = completionResult?.postalCode || "";
        const partialGoogleResult = Boolean(
          completionResult?.formatted &&
            [addressLine, city, state, zipcode].some((s) => !s)
        );
        const fullGoogleResult = Boolean(summaryLine && !partialGoogleResult);
        if (partialGoogleResult || fullGoogleResult) {
          // looks like google was able to figure something out
          setValue("address", addressLine);
          setValue("city", city);
          setValue("state", state);
          setValue("zipcode", zipcode);
          onGoogleMatch?.({
            formatted: summaryLine,
            type: partialGoogleResult ? "incomplete" : "complete",
          });

          if (fullGoogleResult) {
            onLastFieldEntered?.(dummyKeyboardEvent());
          }
        } else {
          onGoogleMatch?.({ type: "fail", formatted: summaryLine });
        }
      });

      autoCompleteRef.current = autocomplete;
    }
  }, [addressEntryRef, onGoogleMatch, onLastFieldEntered, setValue]);
};
